import { useContext, useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { AbilityContext } from 'casl/ability-context';
import { camelCase } from 'lodash';
import sound from 'assets/sounds/eventually.mp3';
const TYPE_KEY = Symbol.for('type');

function usePendingTransaction (serviceName) {
  const [ pending, setPending ] = useState(0);
  const [ audio ] = useState(new Audio(sound));
  const [ notificationPlaying, setNotificationPlaying ] = useState(false);
  const nativeService = feathers.service(`/${serviceName}`);
  const ability = useContext(AbilityContext);
  const modelName = camelCase(serviceName);

  useEffect (() => {
    const onCreated = (data) => {
      data[TYPE_KEY] = modelName;
      const canRead = ability.can('read', data);
      if (!canRead) return;

      if (serviceName === 'deposit-logs') {
        if (!data.proofOfTransfer && !data.bankReference && data.amount.$numberDecimal > 0) return;
        setPending(pending + 1);
        setNotificationPlaying(true);
      } else {
        setPending(pending + 1);
        setNotificationPlaying(true);
      }
    };

    const onUpdated = (data) => {
      data[TYPE_KEY] = modelName;
      const canRead = ability.can('read', data);
      if (!canRead) return;

      if (data.nonStatusUpdate && serviceName === 'deposit-logs') {
        if (!data.proofOfTransfer && !data.bankReference && data.amount.$numberDecimal > 0) return;
        setPending(pending + 1);
        setNotificationPlaying(true);
      } else {
        if (data.status === 'approved' || data.status === 'rejected') setPending(pending - 1);
      }
    };

    nativeService.on('created', onCreated);
    nativeService.on('updated', onUpdated);

    return () => {
      nativeService.removeListener('created', onCreated);
      nativeService.removeListener('updated', onUpdated);
    };
  }, [ability, pending, modelName, nativeService, serviceName]);

  useEffect (() => {
    async function findPending () {
      let params;

      if (serviceName === 'deposit-logs') {
        params = {
          query: {
            $and: [
              { status: { $in: ['pending', 'processing'] } },
              { $or: [
                { proofOfTransfer: { $ne: null } },
                { bankReference: { $ne: null } },
                { amount: 0 }
              ] }
            ],
            $limit: 1
          }
        }
      } else {
        params = {
          query: {
            status: { $in: ['pending', 'processing'] },
            $limit: 1
          }
        };
      }

      try {
        const f =  await nativeService.find(params);
        setPending(f.total);
      } catch (err) {
        console.log(err);
        setPending(0);
      }
    };

    findPending();
  }, [nativeService, serviceName]);

  useEffect(() => {
    audio.addEventListener('ended', () => setNotificationPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setNotificationPlaying(false));
    };
  }, [audio]);

  useEffect(() => {
    if (pending <= 0) return;
    const interval = setInterval( () => {
      setNotificationPlaying(true);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [pending]);

  useEffect(() => {
    notificationPlaying ? audio.play() : audio.pause();

    return () => {
      audio.pause();
    };
  }, [audio, notificationPlaying]);

  return pending;
};

export default usePendingTransaction;