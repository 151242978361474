import React from 'react';
import PropTypes from 'prop-types';
import {
  Table
} from 'semantic-ui-react';
import InputRenderer from 'components/Renderer/Input';

const TableCellInput = (props) => {
  const { field } = props;

  if (!field) return null;

  return (
    <Table.Cell singleLine>
      <InputRenderer { ...props } />
    </Table.Cell>
  );
};

TableCellInput.propTypes = {
  field: PropTypes.object.isRequired
};

export default TableCellInput;
