import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const BanksScreen = (props) => {
  const schema = {
    modelName: 'customerBankAccounts',
    serviceName: 'customer-bank-accounts',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'user.username',
        type: 'String',
        'localeKey': 'username'
      },
      {
        name: 'bank',
        refField: 'bank.name',
        type: 'ObjectId',
        ref: 'banks',
        value: '_id',
        text: 'name',
        localeKey: 'bankName',
        required: true
      },
      {
        name: 'accountHolder',
        type: 'String',
        required: true,
      },
      {
        name: 'accountNumber',
        type: 'String',
        required: true,
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} />
      </Segment>
    </div>
  );
};

BanksScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(BanksScreen);