import React, { useState } from 'react';
import { Form, Header, Segment, Message } from 'semantic-ui-react'
//import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { getUserDetails } from 'store/auth/reducer';
import { useSelector, useDispatch } from 'react-redux';
import feathers from 'services/feathers';
import { updateUser } from 'store/auth/actions';

export default function SettingsScreen (props) {
  const { t } = useTranslation(['translation']);
  const user = useSelector(getUserDetails);
  const [ message, setMessage ] = useState('');
  const [ messageType, setMessageType ] = useState('');
  const dispatch = useDispatch();

  return (
    <Segment textAlign='left' vertical style={{ padding: '1em 2em', minHeight: '80vh' }}>
      <Header as='h2'>
        {t('header.settings')}
      </Header>
      <Formik
        initialValues={{
          name: user.name,
          newPassword: ''
        }}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.name = t('form.error.required');
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            let newData = { ...values };
            if (newData.newPassword !== '') {
              newData.password = newData.newPassword;
              delete newData.newPassword;
            }

            // Clear message before submit
            setMessageType('');
            setMessage('');

            setSubmitting(true);
            const savedUser = await feathers.service('users').update(
              user._id,
              newData
            );
            dispatch(updateUser(savedUser));
            setMessageType('success');
            setMessage(t('form.message.saved'));
            setSubmitting(false);
          } catch (err) {
            setMessageType('error');
            setMessage(err.message);
            setSubmitting(false);
          };
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
              <Form.Input
                value={values.name}
                onChange={(event, data) => {
                  setFieldValue('name', data.value);
                }}
                fluid
                label={t('form.label.name')}
                placeholder={t('form.label.name')}
                error={errors.name ? errors.name : false }
              />
              <Form.Input
                value={values.newPassword}
                onChange={(event, data) => {
                  setFieldValue('newPassword', data.value);
                }}
                fluid
                label={t('form.label.newPassword')}
                placeholder={t('form.label.newPassword')}
                error={errors.newPassword ? errors.newPassword : false }
              />
            </Form.Group>
            <Form.Button type='submit' disabled={isSubmitting}>{t('form.button.save')}</Form.Button>
          </Form>
        )}
      </Formik>
      {
        message && <Message error={messageType === 'error'} success={messageType === 'success'}>
          {message}
        </Message>
      }
    </Segment>
  );
};

SettingsScreen.propTypes = {
};
