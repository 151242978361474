import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
} from 'semantic-ui-react';

const ModalErrorMessage = (props) => {
  const [ open, setOpen ] = useState(true);
  const { message, name, setHideErrorCb } = props;
  const { t } = useTranslation(['table', 'translation']);
  return (
    <Modal basic size='tiny' open={open} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`translation:modalTitle.error`)}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <strong>{name}</strong>: {message}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={ () => {
              setOpen(false);
              setHideErrorCb();
            } }
            content={t(`table:button.close`)}
            labelPosition='right'
            icon='close'
          />
        </Modal.Actions>
    </Modal>
  );
};

ModalErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setHideErrorCb: PropTypes.func.isRequired
};

export default ModalErrorMessage;