import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';

const DateTime = (props) => {
  const { i18n } = useTranslation();
  const { value } = props;
  const locale = i18n.language.toLowerCase();
  const formattedDate = value ? dayjs(value).locale(locale).format('YYYY/MM/DD hh:mm A (ddd)') : null;
  return formattedDate;
};

export default DateTime;