import {  useContext, useEffect } from 'react';
import feathers, { feathersRefServices } from 'services/feathers';
import { each, kebabCase } from 'lodash';
import { AbilityContext } from 'casl/ability-context';
const TYPE_KEY = Symbol.for('type');

function useFeathersRefService (dispatch, fields, region=null) {
  const ability = useContext(AbilityContext);

  useEffect (() => {
    const services = {};
    const nativeServices = {};
    const onCreated = {};
    const onRemoved = {};
    const onUpdated = {};

    each(fields, field => {
      const { modelName } = field;
      const refModelName = `${modelName}Ref`;
      const serviceName = kebabCase(modelName);
      services[modelName] = feathersRefServices[refModelName];
      nativeServices[modelName] = feathers.service(`/${serviceName}`);
      const service = services[modelName];
      const nativeService = nativeServices[modelName];

      onCreated[modelName] = (data) => {
        data[TYPE_KEY] = modelName;
        const canRead = ability.can('read', data);
        if (canRead) dispatch(service.onCreated(data));
        else dispatch(service.onRemoved(data));
      };
      onRemoved[modelName] = (data) => {
        dispatch(service.onRemoved(data));
      };
      onUpdated[modelName] = (data) => {
        data[TYPE_KEY] = modelName;
        const canRead = ability.can('read', data);
        if (canRead) dispatch(service.onUpdated(data));
        else dispatch(service.onRemoved(data));
      };

      nativeService.on('created', onCreated[modelName]);
      nativeService.on('removed', onRemoved[modelName]);
      nativeService.on('updated', onUpdated[modelName]);
    });


    return () => {
      each(fields, field => {
        const { modelName } = field;
        const nativeService = nativeServices[modelName];
        nativeService.removeListener('created', onCreated[modelName]);
        nativeService.removeListener('removed', onRemoved[modelName]);
        nativeService.removeListener('updated', onUpdated[modelName]);
      });
    };
  }, [ability, dispatch, fields]);

  useEffect (() => {
    each(fields, field => {
      const { modelName } = field;
      const refModelName = `${modelName}Ref`;
      const service = feathersRefServices[refModelName];
      let options = {
        query: {
          $limit: -1
        }
      };
      dispatch(service.find(options)).catch(err => console.error(err));
    });
  }, [dispatch, fields]);
  return;
};

export default useFeathersRefService;