import React from 'react';
import { connect } from 'react-redux';
import * as authSelectors from './store/auth/reducer';
import LoginScreen from './containers/LoginScreen';
import MainScreen from './containers/MainScreen';
import { isManagementRole } from './utils/user';
import {
  Dimmer,
  Loader,
} from 'semantic-ui-react'

import './App.css';
import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App (props) {
  const { isAuthLoading } = props;
  const { t, ready: tReady } = useTranslation(['transaction', 'translation', 'table']);

  if (!tReady) {
    return (<Dimmer active page><Loader /></Dimmer>);
  }

  if (isAuthLoading) {
    return (<Dimmer active page><Loader />{t(`translation:loaderText.connecting`)}</Dimmer>);
  }

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={LoginScreen} />
        <ProtectedManagementRoute path="/:screen?" component={MainScreenComponent} role={props.role} />
      </Switch>
    </div>
  );
};

const ProtectedManagementRoute = ({ component: Component, role: Role, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (isManagementRole(Role) ? (<Component { ...props } />) : (<Redirect to={{ pathname: '/' }} /> ))
    }}
  />
);

const MainScreenComponent = (matchedRoute) => (
  <MainScreen match={matchedRoute.match} />
);

function mapStateToProps(state, ownProps) {
  return {
    isAuthLoading: authSelectors.getIsLoading(state),
    role: authSelectors.getUserRole(state),
    matchedRoute: ownProps.match
  }
}

export default connect(mapStateToProps)(App);