import PropTypes from 'prop-types'
import React from 'react';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const ExtCompanyDailyWinLossLogsKiss918Screen = (props) => {
  const schema = {
    modelName: 'extCompanyDailyWinLossLogsKiss918',
    serviceName: 'ext-company-daily-win-loss-logs-kiss-918',
    fields: [
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'date',
        type: 'String',
        sortable: true
      },
      {
        name: 'win.$numberDecimal',
        localeKey: 'amountMonetary',
        type: 'Number'
      },
      {
        name: 'eod',
        type: 'Boolean',
        translate: true
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      },
      {
        name: '_id',
        type: 'String'
      }
    ],
    sorting: {
      'date': -1
    }
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
      </Segment>
    </div>
  );
};

ExtCompanyDailyWinLossLogsKiss918Screen.propTypes = {
  mobile: PropTypes.bool
};

export default ExtCompanyDailyWinLossLogsKiss918Screen;
