import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  connected: false
});

export default function reduce (state = initialState, action = {}) {
  switch (action.type) {
    case types.CONNECTED:
      return state.merge({
        connected: true
      });
    case types.DISCONNECTED:
      return state.merge({
        connected: false
      });
    default:
      return state;
  }
};

export function getConnectedStatus (state) {
  return state.io.connected;
};
