import { clone, get, pick } from 'lodash';

export function spreadPropsMimeType (field, object) {
  const ret = {
    mimeType: ''
  };
  if (field.type === 'Buffer') {
    ret.mimeType = get(object, 'mimeType', '');
  }
  return ret;
};

export function spreadPropsRefData (refs, field, object) {
  const ret = {
    refData: []
  };
  if (field.type === 'ObjectId') {
    if (field.refPath) ret.refData = get(refs, `${object[field.refPath]}`, []);
    else ret.refData = get(refs, `${field.ref}`, []);;
  }
  return ret;
};

export function spreadPropsCallback (field, object) {
  const callback = get(field, 'callback', null);
  if (!callback) return;

  const pickedFields = pick(object, callback.pickFields);
  callback.pickedFields = pickedFields;

  const newCallback = clone(callback);
  newCallback.pickFields = pickedFields;

  const ret = {
    callback: newCallback
  };
  return ret;
};