const dev = {
  feathers: {
    //host: 'http://localhost:3030'
    host: 'https://ccrm1337.duckdns.org:10443'
  }
};

const prod = {
  feathers: {
    host: 'https://ccrm8890.duckdns.org:10443'
  }
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : dev;

const mergedConfigs = {
  // Add common config values here
  // eg: soundHost: config.feathers.host + '/sounds/',
  ...config
};

export default mergedConfigs;
