import React from 'react';
import PropTypes from 'prop-types';
import { includes, lowerCase, trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Dimmer,
  Loader
} from 'semantic-ui-react';

const TableLoaderService = (props) => {
  const { serviceStatus } = props;
  const { t } = useTranslation();
  const className = serviceStatus.className;
  if (!includes(['isLoading', 'isSaving'], className)) return null;
  const type = lowerCase(trim(className, 'is'));
  return (
    <Dimmer active page>
      <Loader>{t(`serviceStatus.${type}`)}</Loader>
    </Dimmer>
  );
};

TableLoaderService.propTypes = {
  serviceStatus: PropTypes.object.isRequired
};

export default TableLoaderService;