export const LAST_SEARCH_UPDATE = 'table.LAST_SEARCH_UPDATE';
export const LAST_SEARCH_RESET = 'table.LAST_SEARCH_RESET';
export const SEARCH_STRING_UPDATE = 'table.SEARCH_STRING_UPDATE';
export const SEARCH_STRING_RESET = 'table.SEARCH_STRING_RESET';
export const EDIT_OBJECT_UPDATE = 'table.EDIT_OBJECT_UPDATE';
export const EDIT_OBJECT_RESET = 'table.EDIT_OBJECT_RESET';
export const DELETE_OBJECT_UPDATE = 'table.DELETE_OBJECT_UPDATE';
export const DELETE_OBJECT_RESET = 'table.DELETE_OBJECT_RESET';
export const NEW_OBJECT_UPDATE = 'table.NEW_OBJECT_UPDATE';
export const NEW_OBJECT_RESET = 'table.NEW_OBJECT_RESET';
export const PAGINATION_SKIP_UPDATE = 'table.PAGINATION_SKIP_UPDATE';
export const SORTING_UPDATE = 'table.SORTING_UPDATE';
export const RESET = 'table.RESET';
export const FILTER_ADD = 'table.FILTER_ADD';
export const FILTER_START = 'table.FILTER_START';
export const FILTER_STOP = 'table.FILTER_STOP';
export const LIMIT_UPDATE = 'table.LIMIT_UPDATE';