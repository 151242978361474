import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
} from 'semantic-ui-react';
import { Editor, convertFromRaw, EditorState } from 'draft-js';

const ModalTextView = (props) => {
  const [ open, setOpen ] = useState(false);
  const [text, setText] = useState(null);
  const { field, value } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (text !== null) return;
    if (value) {
      const content = convertFromRaw(JSON.parse(value));
      setText(EditorState.createWithContent(content));
    } else {
      setText(EditorState.createEmpty());
    }
  }, [text, value]);

  return (
    <Modal size='tiny' open={open} trigger={<Button size='small' onClick={ () => { setOpen(true); } }>{`${t(`table:button.view`)}`}</Button>} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`table:field.${field.name}`)}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
          {
            text &&
            <Editor
              editorState={text}
              readOnly={true}
            />
          }
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={ () => { setOpen(false); } } content={t(`table:button.close`)} labelPosition='right' icon='close' />
        </Modal.Actions>
    </Modal>
  );
};

ModalTextView.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired
};

export default ModalTextView;