import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const ExtBalanceTransferLogsKiss918Screen = (props) => {
  const schema = {
    modelName: 'extBalanceTransferLogsKiss918',
    serviceName: 'ext-balance-transfer-logs-kiss-918',
    fields: [
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'user.username',
        type: 'String',
        localeKey: "username"
      },
      {
        name: 'extUser.username',
        type: 'String',
        localeKey: "extUsername"
      },
      {
        name: 'amount.$numberDecimal',
        localeKey: 'amountMonetary',
        type: 'Number'
      },
      {
        name: 'type',
        type: 'String',
        translate: true,
        enum: ['deposit', 'withdrawal'],
      },
      {
        name: 'status',
        type: 'String',
        translate: true,
        enum: ['pending', 'manual', 'checking', 'rejected', 'approved'],
        sortable: true
      },
      {
        name: 'errorMessage',
        type: 'String'
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
      </Segment>
    </div>
  );
};

ExtBalanceTransferLogsKiss918Screen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(ExtBalanceTransferLogsKiss918Screen);