import { each, filter, find, flatMap, get, map, uniq, uniqWith } from 'lodash';

export function isObjectId (string) {
  return string.match(/^[a-fA-F0-9]{24}$/);
};

export function extractRefFields (schema) {
  let fields = schema.fields;
  const { filterFields } = schema;

  each(filter(schema.fields, f => f.type ==='Array'), arr => {
    const arrayFields = get(arr, 'arraySchema.fields', null);
    if (arrayFields) fields = fields.concat(arrayFields);
  });

  each(filter(schema.fields, f => f.type ==='Object'), arr => {
    const objectFields = get(arr, 'objectSchema.fields', null);
    if (objectFields) fields = fields.concat(objectFields);
  });

  // Extract ref fields
  let refFields = map(filter(fields, f => f.type === 'ObjectId' && !f.refPath), ref => {
    const ret = {
      modelName: ref.ref
    };
    if (ref.refFilter) ret.refFilter = ref.refFilter
    return ret;
  });

  // Extract ref filter fields
  let refFilterFields = map(filter(filterFields, f => f.type === 'ObjectId' && !f.refPath), ref => {
    const ret = {
      modelName: ref.ref
    };
    if (ref.refFilter) ret.refFilter = ref.refFilter
    return ret;
  });

  refFields = refFields.concat(refFilterFields);

  // Extract ref path fields
  const refPathFields = uniq(flatMap(filter(fields, f => f.refPath), f => {
    return f.refPath;
  }));

  each(refPathFields, f => {
    const findField = find(fields, { name: f });
    const mapEnums = map(findField.enum, e => {
      return {
        modelName: e
      }
    });
    refFields = refFields.concat(mapEnums);
  });

  refFields = uniqWith(refFields, (d1, d2) => { return d1.modelName === d2.modelName });
  return refFields;
};