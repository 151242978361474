import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Form,
  Message,
  Modal
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';

const ModalExtUserStatusToggleView = (props) => {
  const {
    extUserObject,
    localeKey,
    onClose,
    service
  } = props;
  const { t } = useTranslation();
  const [userIsEnabled, setUserIsEnabled] = useState(false);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getStatus () {
      const { username } = extUserObject;
      const params = {
        query: {
          method: 'status'
        }
      };
      try {
        setStatus('loading');
        const statusCheck = await feathers.service(service).get(username, params);
        setUserIsEnabled(statusCheck.status === 'enabled' ? true : false);
        setStatus('idle');
      } catch (err) {
        setError(err.message);
        setStatus('idle');
      }
    };
    getStatus();
  }, [extUserObject, service]);

  const onToggle = async (e, data) => {
    const { username } = extUserObject;
    const params = {
      query: {
        method: 'toggleUserStatus'
      }
    };
    try {
      setStatus('loading');
      const statusToggle = await feathers.service(service).get(username, params);
      setStatus('idle');
      setUserIsEnabled(statusToggle.status === 'enabled' ? true : false);
    } catch (err) {
      setError(err.message);
      setStatus('idle');
    }
  };

  return (
    <Modal
      open={extUserObject !== null}
      onClose={onClose}
      size='tiny'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
    >
      <Modal.Header>{t(`translation:modalTitle.${localeKey}`)}</Modal.Header>
      <Modal.Content scrolling>
        <Form loading={status !== 'idle'}>
          <Form.Group>
            <Form.Field>
              <label>{t(`translation:form.label.status`)}</label>
              <Checkbox
                toggle
                checked={userIsEnabled}
                onChange={onToggle}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {
          error &&
          <Message negative>
            <Message.Header>{t(`translation:modalTitle.error`)}</Message.Header>
            <p>{error}</p>
          </Message>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose} content={t(`table:button.close`)} labelPosition='right' icon='close' />
      </Modal.Actions>
    </Modal>
  )
};

ModalExtUserStatusToggleView.propTypes = {
  extUserObject: PropTypes.object.isRequired,
  localeKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
};

export default ModalExtUserStatusToggleView