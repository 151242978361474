import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Confirm
} from 'semantic-ui-react';
import { getDeleteObject } from 'store/table/reducer';
import { confirmDeleteObject, resetDeleteObject } from 'store/table/actions';
import { useTranslation } from 'react-i18next';

const TableRowDelete = (props) => {
  const { t } = useTranslation();
  const { deleteObject, onDeleteConfirm, onDeleteCancel } = props;
  if (!deleteObject) return null;
  const name = deleteObject.name || '';
  const isOpen = !(deleteObject === null);
  const message = `${t(`confirm.prefix`)}${t(`confirm.type.delete`)}${name}${t(`confirm.postfix`)}`;
  return (
    <Confirm
      open={isOpen}
      content={message}
      cancelButton={t(`confirm.cancel`)}
      confirmButton={t(`confirm.ok`)}
      onCancel={onDeleteCancel}
      onConfirm={() => {
        onDeleteConfirm(deleteObject);
      }}
      size='mini'
    />
  );
};

TableRowDelete.propTypes = {
  deleteObject: PropTypes.object,
  onDeleteConfirm: PropTypes.func.isRequired,
  onDeleteCancel: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    onDeleteConfirm: (deleteObject) => {
      dispatch(confirmDeleteObject(modelName));
    },
    onDeleteCancel: () => {
      dispatch(resetDeleteObject(modelName));
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    deleteObject: getDeleteObject(state, modelName)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRowDelete);
