import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import axios from 'axios';
import config from 'config';

const restClient = rest(config.feathers.host);

const app = feathers();
app.configure(restClient.axios(axios));
app.configure(auth({
  storage: window.localStorage
}));

export default app;
