import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import { each, get, merge, size } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isObjectId } from 'utils/mongoose';

const initialState = Immutable({
  lastSearch: '',
  searchString: '',
  editObject: null,
  deleteObject: null,
  newObject: {
    _id: uuid()
  },
  paginationSkip: 0,
  sorting: {
    createdAt: -1
  },
  filter: {},
  filterStart: false,
  limit: 10
});

export default function reduce (state = initialState, action = {}) {
  switch (action.type) {
    case types.RESET:
      return initialState;
    case types.LAST_SEARCH_UPDATE:
      return state.merge({
        paginationSkip: initialState.paginationSkip,
        sorting: initialState.sorting,
        lastSearch: action.lastSearch
      });
    case types.LAST_SEARCH_RESET:
      return state.merge({
        paginationSkip: initialState.paginationSkip,
        sorting: initialState.sorting,
        lastSearch: initialState.lastSearch
      });
    case types.SEARCH_STRING_UPDATE:
      return state.merge({
        searchString: action.searchString
      });
    case types.SEARCH_STRING_RESET:
      return state.merge({
        searchString: initialState.searchString
      });
    case types.EDIT_OBJECT_UPDATE:
      return state.merge({
        editObject: action.editObject
      });
    case types.EDIT_OBJECT_RESET:
      return state.merge({
        editObject: initialState.editObject
      });
    case types.DELETE_OBJECT_UPDATE:
      return state.merge({
        deleteObject: action.deleteObject
      });
    case types.DELETE_OBJECT_RESET:
      return state.merge({
        deleteObject: initialState.deleteObject
      });
    case types.NEW_OBJECT_UPDATE:
      return state.merge({
        newObject: action.newObject
      });
    case types.NEW_OBJECT_RESET:
      return state.merge({
        newObject: initialState.newObject
      });
    case types.PAGINATION_SKIP_UPDATE:
      return state.merge({
        paginationSkip: action.paginationSkip
      });
    case types.SORTING_UPDATE:
      return state.merge({
        sorting: action.sorting
      });
    case types.FILTER_ADD:
      return state.merge({
        filter: action.filter
      });
    case types.FILTER_START:
      return state.merge({
        filterStart: true
      });
    case types.FILTER_STOP:
      return state.merge({
        filterStart: false,
        filter: {}
      });
    case types.LIMIT_UPDATE:
      return state.merge({
        paginationSkip: initialState.paginationSkip,
        limit: action.limit
      });
    default:
      return state;
  }
};

export function getLastSearch (state, modelName) {
  return get(state, `${modelName}Table.lastSearch`, initialState.lastSearch);
};

export function getSearchString (state, modelName) {
  return get(state, `${modelName}Table.searchString`, initialState.searchString);
};

export function getObject (state, modelName, objectName) {
  return get(state, `${modelName}Table.${objectName}`, initialState[objectName]);
};

export function getEditObject (state, modelName) {
  return get(state, `${modelName}Table.editObject`, initialState.editObject);
};

export function getDeleteObject (state, modelName) {
  return get(state, `${modelName}Table.deleteObject`, initialState.deleteObject);
};

export function getNewObject (state, modelName) {
  return get(state, `${modelName}Table.newObject`, initialState.newObject);
};

export function isEditObjectNull (state, modelName) {
  return get(state, `${modelName}Table.editObject`, null) === null;
};

export function getObjectValue (state, modelName, objectName, key) {
  return get(state, `${modelName}Table.${objectName}.${key}`, '');
}

export function getSorting (state, modelName) {
  return get(state, `${modelName}Table.sorting`, initialState.sorting);
};

export function getQueryString (state, modelName) {

  const lastSearch = get(state, `${modelName}Table.lastSearch`, initialState.lastSearch);
  const paginationSkip = get(state, `${modelName}Table.paginationSkip`, initialState.paginationSkip);
  const sorting = get(state, `${modelName}Table.sorting`, initialState.sorting);
  const filterStart = get(state, `${modelName}Table.filterStart`, initialState.filterStart);
  const limit = get(state, `${modelName}Table.limit`, initialState.limit);

  let query = {
    $limit: limit
  };

  if (lastSearch) appendSearchQuery(query, lastSearch);
  if (paginationSkip > 0) query.$skip = paginationSkip;
  if (size(sorting)) query.$sort = sorting;
  if (filterStart) {
    const filter = get(state, `${modelName}Table.filter`, initialState.filter);
    query = merge(query, filter);
  }
  return JSON.stringify(query);
};

export function getCaslTestDataObject (state, modelName, objectName, caslTestData) {
  let ret = {};
  each(caslTestData, (key) => {
    const v = get(state, `${modelName}Table.${objectName}.${key}`);
    if (v) {
      ret[key] = v;
    }
  });
  return ret;
};

function appendSearchQuery (query, searchString) {
  if (isObjectId(searchString)) {
    query._id = searchString;
  } else {
    query.$text = {
      $search: searchString
    }
  }
};

export function getFilter (state, modelName) {
  const filter = get(state, `${modelName}Table.filter`);
  return filter;
};

export function getFilterIsStart (state, modelName) {
  return get(state, `${modelName}Table.filterStart`);
};

export function getFilterValue (state, modelName, filterKey) {
  const filterValue = get(state, `${modelName}Table.filter.${filterKey}`);
  return filterValue;
};

export function getLimit (state, modelName) {
  return get(state, `${modelName}Table.limit`);
};