import React, { Component } from 'react';
import { Container, Grid, Icon, Segment } from 'semantic-ui-react'

export default class Footer extends Component {
  render() {
    return (
      <Segment vertical style={{ padding: '1em 0em' }}>
        <Container>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <p>
                  <span>
                    <Icon name='closed captioning' size='large' color='orange' />
                  </span>
                  <span>
                    rm
                  </span>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}
