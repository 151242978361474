export const mapServicePathsToNames = {
  'api-settings-king-855': 'apiSettingsKing855Ref',
  'api-settings-kiss-918': 'apiSettingsKiss918Ref',
  'banks': 'banksRef',
  'bonus-settings': 'bonusSettingsRef',
  'company-bank-accounts': 'companyBankAccountsRef',
  'currencies': 'currenciesRef',
  'files': 'filesRef',
  'locales': 'localesRef',
  'products': 'productsRef',
  'regions': 'regionsRef',
  'rejected-reasons': 'rejectedReasonsRef'
};

export const prioritizedListServices = [
  'banksRef',
  'bonusSettingsRef',
  'companyBankAccountsRef',
  'currenciesRef',
  'filesRef',
  'localesRef',
  'productsRef',
  'regionsRef',
  'rejectedReasonsRef',
  'apiSettingsKiss918Ref',
  'apiSettingsMega888Ref'
];
