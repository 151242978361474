import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import {
  Table
} from 'semantic-ui-react';
import { get } from 'lodash';
import { updateSorting } from 'store/table/actions';
import { getSorting } from 'store/table/reducer';

const TableColumns = (props) => {
  const { t } = useTranslation('table');
  const { fields } = props.schema;
  const {
    readOnly,
    sorting,
    updateSorting
  } = props;

  const getSorted = (column) => {
    const currentDirection = get(sorting, column, 0);
    if (currentDirection === 0) return null;
    else if (currentDirection === 1) return 'ascending';
    else return 'descending'
  };

  return (
    <Table.Row>
      {
        readOnly ?
        null :
        <Table.HeaderCell>{t(`field.actions`)}</Table.HeaderCell>
      }
      {
        map(fields, field => {
          const localeKey = get(field, 'localeKey', field.name);
          const sortable = field.sortable || false;
          const title = t(`field.${localeKey}`);
          const formattedTitle = sortable ? `# ${title}` : title;
          return (
            <Table.HeaderCell
              singleLine
              key={field.name}
              sorted={getSorted(field.name)}
              onClick={ () => {
                if (!sortable) return;
                return updateSorting(field.name);
              }}
            >
              {formattedTitle}
            </Table.HeaderCell>
          );
        })
      }
    </Table.Row>
  );
};

TableColumns.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
  sorting: PropTypes.object.isRequired,
  updateSorting: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    updateSorting: (field) => { dispatch(updateSorting(modelName, field)); }
  };
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    sorting: getSorting(state, modelName)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableColumns);
