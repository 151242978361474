import * as types from './actionTypes';
import * as selectors from './reducer';
import { feathersServices } from 'services/feathers';
import { cloneDeep, filter, get, isNumber, kebabCase, set, unset } from 'lodash';

export function reset (modelName) {
  return ({ type: types.RESET, modelName });
};

export function updateLastSearch (modelName, lastSearch) {
  return ({ type: types.LAST_SEARCH_UPDATE, modelName, lastSearch });
};

export function resetLastSearch (modelName) {
  return ({ type: types.LAST_SEARCH_RESET, modelName });
};

export function updateSearchString (modelName, searchString) {
  return ({ type: types.SEARCH_STRING_UPDATE, modelName, searchString });
};

export function resetSearchString (modelName) {
  return ({ type: types.SEARCH_STRING_RESET, modelName });
};

export function initEditObject (modelName, editObject) {
  return({ type: types.EDIT_OBJECT_UPDATE, modelName, editObject });
};

export function updateEditObject (modelName, id, key, value) {
  return (dispatch, getState) => {
    const currentEditObject = selectors.getEditObject(getState(), modelName);
    let editObject =  cloneDeep(currentEditObject);
    if (editObject && editObject._id === id) {
      set(editObject, `${key}`, value);
      dispatch({ type: types.EDIT_OBJECT_UPDATE, modelName, editObject });
    } else {
      dispatch({ type: types.EDIT_OBJECT_RESET, modelName });
    }
  };
};

export function resetEditObject (modelName) {
  return({ type: types.EDIT_OBJECT_RESET, modelName });
};

export function saveEditObject (modelName) {
  return (dispatch, getState) => {
    const service = feathersServices[modelName];
    const editObject = selectors.getEditObject(getState(), modelName);
    if (!editObject) return;
    dispatch(service.update(editObject._id, editObject)).catch(err => console.error(err));
    dispatch({ type: types.EDIT_OBJECT_RESET, modelName });
  };
};

export function updateDeleteObject (modelName, deleteObject) {
  return({ type: types.DELETE_OBJECT_UPDATE, modelName, deleteObject });
};

export function resetDeleteObject (modelName) {
  return({ type: types.DELETE_OBJECT_RESET, modelName });
};

export function confirmDeleteObject (modelName) {
  return (dispatch, getState) => {
    const service = feathersServices[modelName];
    const deleteObject = selectors.getDeleteObject(getState(), modelName);
    if (!deleteObject) return;
    dispatch(service.remove(deleteObject._id)).catch(err => console.error(err));
    dispatch({ type: types.DELETE_OBJECT_RESET, modelName });
  };
};

export function updateNewObject (modelName, id, key, value) {
  return (dispatch, getState) => {
    const currentNewObject = selectors.getNewObject(getState(), modelName);
    let newObject = cloneDeep(currentNewObject);
    set(newObject, key, value);
    dispatch({ type: types.NEW_OBJECT_UPDATE, modelName, newObject });
  };
};

export function resetNewObject(modelName) {
  return({ type: types.NEW_OBJECT_RESET, modelName });
};

export function saveNewObject (modelName) {
  return (dispatch, getState) => {
    const service = feathersServices[modelName];
    const newObject = selectors.getNewObject(getState(), modelName);
    if (!newObject) return;
    dispatch(service.create(newObject)).catch(err => console.error(err));
    dispatch({ type: types.NEW_OBJECT_RESET, modelName });
  };
};

export function updatePaginationSkip (modelName, paginationSkip) {
  return (dispatch, getState) => {
    dispatch({ type: types.PAGINATION_SKIP_UPDATE, modelName, paginationSkip });
  };
};

export function updateDefaultSorting (modelName, sorting) {
  return (dispatch, getState) => {
    dispatch({ type: types.SORTING_UPDATE, modelName, sorting });
  };
};

export function updateSorting (modelName, field) {
  return (dispatch, getState) => {
    const currentSorting = selectors.getSorting(getState(), modelName);
    const newSorting = cloneDeep(currentSorting);
    const currentFieldSorting = get(newSorting, field, 0);
    if (currentFieldSorting === 0) set(newSorting, field, 1);
    else if (currentFieldSorting === 1) set(newSorting, field, -1);
    else unset(newSorting, field);
    dispatch({ type: types.SORTING_UPDATE, modelName, sorting: newSorting });
  };
};

export function updateObjectArrayDeletion (modelName, objectName, id, arrName, arrIndex) {
  return (dispatch, getState) => {
    const currentObject = selectors.getObject(getState(), modelName, objectName);
    let object =  cloneDeep(currentObject);
    if (object && object._id === id) {
      let arr = get(object, arrName, []);
      const newArr = filter(arr, (a, index) => {
        return index !== arrIndex;
      });
      set(object, arrName, newArr);
      dispatch({ type: getActionsTypeByName(objectName, 'UPDATE'), modelName, [objectName]: object });
    } else {
      dispatch({ type: getActionsTypeByName(objectName, 'RESET'), modelName });
    }
  };
};

export function updateObjectArrayInsertion (modelName, objectName, id, arrName) {
  return (dispatch, getState) => {
    const currentObject = selectors.getObject(getState(), modelName, objectName);
    let object =  cloneDeep(currentObject);
    if (object && object._id === id) {
      let arr = get(object, arrName, []);
      arr.unshift({});
      set(object, arrName, arr);
      dispatch({ type: getActionsTypeByName(objectName, 'UPDATE'), modelName, [objectName]: object });
    } else {
      dispatch({ type: getActionsTypeByName(objectName, 'RESET'), modelName });
    }
  };
};

function getActionsTypeByName (objectName, action) {
  const pre = kebabCase(objectName).split('-')[0].toUpperCase();
  return types[`${pre}_OBJECT_${action}`];
};

export function addFilter (modelName, filterKey, filterValue) {
  return (dispatch, getState) => {
    const currentFilter = selectors.getFilter(getState(), modelName);
    let filter = cloneDeep(currentFilter);
    set(filter, filterKey, filterValue);
    dispatch({ type: types.FILTER_ADD, modelName, filter });
  };
};

export function startFilter (modelName) {
  return({ type: types.FILTER_START, modelName });
};

export function stopFilter (modelName) {
  return({ type: types.FILTER_STOP, modelName });
};

export function updateLimit (modelName, limit) {
  const l = isNumber(limit) ? limit : 0;
  return({ type: types.LIMIT_UPDATE, modelName, limit: l });
};