import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Dimmer,
  Label,
  Loader,
  Menu,
  Segment,
  Tab
} from 'semantic-ui-react';
import { map } from 'lodash';
import ImageModal from 'components/ImageModal';
import useFeathersTransactionTaskService from 'hooks/useFeathersTransactionTaskService';
import PendingCard from 'components/Card/DepositPendingCard';
import ProcessingCard from 'components/Card/DepositProcessingCard';
import ModalErrorMessage from 'components/Modal/ModalErrorMessage';
import CardConfirmation from 'components/Card/Confirmation';
import CompanyBankList from 'components/CompanyBankList';
import {
  getPendingTransactions,
  getProcessableTransactions,
  getProcessingTransactions,
  getServiceIsError,
  getServiceIsLoading
} from 'store/feathers/selectors';
import { useTranslation } from 'react-i18next';
import { CardTaskSegment } from 'styles/common';
import useFeathersRefService from 'hooks/useFeathersRefServiceNew';

const DepositTasksScreen = (props) => {
  const { t } = useTranslation(['transaction', 'translation']);
  const {
    dispatch,
    imageDownloaderServiceName,
    pendingTransactions,
    processableTransactions,
    processingTransactions,
    serviceIsError,
    serviceIsLoading
  } = props;
  const service = useFeathersTransactionTaskService(dispatch, 'depositLogs');
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [imageModalSource, setImageModalSource] = useState('');
  const [hideError, setHideError] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [tabIndex, setTabIndex] = useState(1);
  const { cbaRefData, cbaRefIsLoading } = useFeathersRefService('cba', 'companyBankAccounts');

  const setImageModalIsOpenCb= useCallback(
    (isOpen) => {
      setImageModalIsOpen(isOpen);
    },
    []
  );

  const setImageModalSourceCb = useCallback(
    (source) => {
      setImageModalSource(source);
      setImageModalIsOpen(true);
    },
    []
  );

  const onStatusUpdateCb = useCallback(
    (transactionId, updateData) => {
      const m = `${t(`translation:confirm.prefix`)}${t(`translation:confirm.type.${updateData.status}`)}${t(`translation:confirm.postfix`)}`;
      setConfirmationMessage(m);
      setConfirmationAction({ transactionId, updateData });
      setShowConfirm(true);
    },
    [t]
  );

  const onConfirmationCb = useCallback(
    (isConfirmed) => {
      const { transactionId, updateData } = confirmationAction;
      if (isConfirmed) {
        dispatch(service.update(transactionId, updateData)).catch(err => {
          console.error(err);
          setHideError(false);
        });
      }
      setConfirmationAction({});
      setShowConfirm(false);
    },
    [confirmationAction, dispatch, service]
  );

  const onTabChangeCb = useCallback(
    (e, { activeIndex }) => {
      setTabIndex(activeIndex);
    },
    []
  );

  const setHideErrorCb = useCallback(
    () => {
      setHideError(true);
    },
    []
  );

  if (cbaRefIsLoading) {
    return (
      <Dimmer active page>
        <Loader>{t(`translation:serviceStatus.refLoading`)}</Loader>
      </Dimmer>
    );
  };

  if (serviceIsLoading) {
    return (
      <Dimmer active page>
        <Loader>{t(`translation:serviceStatus.loading`)}</Loader>
      </Dimmer>
    );
  }

  if (serviceIsError && !hideError) {
    const { name, message } = serviceIsError;
    return (
      <ModalErrorMessage name={name} message={message} setHideErrorCb={setHideErrorCb} />
    );
  };

  return serviceIsLoading ?
  (
    <Dimmer active page>
      <Loader>{t(`translation:serviceStatus.loading`)}</Loader>
    </Dimmer>
  ) : (
    <Segment style={CardTaskSegment}>
      <CompanyBankList cbaRefData={cbaRefData} />
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={generatePanes()}
        activeIndex={tabIndex}
        onTabChange={onTabChangeCb}
      />
      <CardConfirmation showConfirm={showConfirm} message={confirmationMessage} onConfirmationCb={onConfirmationCb} />
      <ImageModal
        imageDownloaderServiceName={imageDownloaderServiceName}
        imageModalSource={imageModalSource}
        imageModalIsOpen={imageModalIsOpen}
        setImageModalIsOpen={setImageModalIsOpenCb}
      />
    </Segment>
  );

  function generatePanes () {
    return [
      {
        menuItem: (
          <Menu.Item key='pending'>
            {t(`transaction:tab.pending`)}<Label>{pendingTransactions.length}</Label>
          </Menu.Item>
        ),
        render: () => renderPending()
      },
      {
        menuItem: (
          <Menu.Item key='processable'>
            {t(`transaction:tab.processable`)}<Label>{processableTransactions.length}</Label>
          </Menu.Item>
        ),
        render: () => renderProcessable()
      },
      {
        menuItem: (
          <Menu.Item key='processing'>
            {t(`transaction:tab.processing`)}<Label>{processingTransactions.length}</Label>
          </Menu.Item>
        ),
        render: () => renderProcessing()
      },
    ]
  };

  function renderPending () {
    return (
      <Card.Group>
        {
          map(pendingTransactions, (transactionData, index) => {
            return (
              <PendingCard key={`card${index}`} transactionData={transactionData} onStatusUpdateCb={onStatusUpdateCb} />
            )
          })
        }
      </Card.Group>
    );
  };

  function renderProcessable () {
    return (
      <Card.Group>
        {
          map(processableTransactions, (transactionData, index) => {
            return (
              <PendingCard key={`card${index}`} transactionData={transactionData} onStatusUpdateCb={onStatusUpdateCb} />
            )
          })
        }
      </Card.Group>
    );
  };

  function renderProcessing () {
    return (
      <Card.Group>
        {
          map(processingTransactions, (transactionData, index) => {
            return (
              <ProcessingCard
                key={`card${index}`}
                transactionData={transactionData}
                setImageModalSourceCb={setImageModalSourceCb}
                onStatusUpdateCb={onStatusUpdateCb}
              />
            )
          })
        }
      </Card.Group>
    );
  };
};

DepositTasksScreen.propTypes = {
  imageDownloaderServiceName: PropTypes.string.isRequired,
  pendingTransactions: PropTypes.array.isRequired,
  processableTransactions: PropTypes.array.isRequired,
  processingTransactions: PropTypes.array.isRequired,
  serviceIsError: PropTypes.object,
  serviceIsLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const imageDownloaderServiceName = 'proof-of-transfers';
  const modelName = 'depositLogs';
  const serviceName = `${modelName}Task`;

  return {
    imageDownloaderServiceName: imageDownloaderServiceName,
    pendingTransactions: getPendingTransactions(state, modelName),
    processableTransactions: getProcessableTransactions(state, modelName),
    processingTransactions: getProcessingTransactions(state, modelName),
    serviceIsError: getServiceIsError(state, serviceName),
    serviceIsLoading: getServiceIsLoading(state, serviceName)
  };
};

export default connect(mapStateToProps)(DepositTasksScreen);