import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import usePendingTransaction from 'hooks/usePendingTransaction';
import { Label } from 'semantic-ui-react'

const NavLinkWithBadge = (props) => {
  const { title, service, to } = props;
  const pendingCount = usePendingTransaction(service);
  return (
    <NavLink to={to} className='item'>
      {title}
      <Label horizontal color={pendingCount ? 'green' : 'grey' }>
        {pendingCount}
      </Label>
    </NavLink>
  )
};

NavLinkWithBadge.propTypes = {
  title: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default NavLinkWithBadge;
