import * as types from './actionTypes';
import feathers from 'services/feathers';
import * as authSelectors from './reducer';
import i18n from 'i18n';
import ability from 'casl/ability';
import { get, set } from 'lodash';
import { cleanLoginForm } from 'store/login/actions';
import { isManagementRole } from 'utils/user';

export function reAuthenticate () {
  return async (dispatch, getState) => {
    dispatch({ type: types.AUTHENTICATE, payload: feathers.reAuthenticate() })
    .then(data => {
      const role = get(data, 'value.user.role');

      if (!isManagementRole(role)) {
        dispatch({ type: types.LOGOUT });
      } else {
        ability.update(data.value.rules);
        i18n.changeLanguage(data.value.user.locale.code || 'en');
        dispatch({ type: types.PERSONALIZATION_COMPLETED });
      }
    })
    .catch(err =>
    {
      console.log(err);
    });
  };
};

export function authenticate (credential) {
  return async (dispatch, getState) => {
    dispatch({ type: types.AUTHENTICATE, payload: feathers.authenticate(credential) })
    .then(data => {
      const role = get(data, 'value.user.role');

      if (!isManagementRole(role)) {
        dispatch({ type: types.LOGOUT });
      } else {
        dispatch(cleanLoginForm());
        ability.update(data.value.rules);
        i18n.changeLanguage(data.value.user.locale.code || 'en');
        dispatch({ type: types.PERSONALIZATION_COMPLETED });
      }
    })
    .catch(err =>
    {
      if (err.code === 403
        && err.data
        && err.data.twoFactorEnabled
      ) {
        dispatch({ type: types.TWOFACTOR_ENABLED });
      } else {
        dispatch(cleanLoginForm());
        console.log(err);
      }
    });
  };
};

export function updateUser (user) {
  return (dispatch, getState) => {
    let currentUser = { ...authSelectors.getUserDetails(getState()) };
    const name = get(user, 'name', '');
    set(currentUser, 'name', name);
    dispatch({
      type: types.USER_UPDATE,
      payload: {
        user: currentUser
      }
    });
  };
};

export function logout () {
  return async (dispatch, getState) => {
    await feathers.logout();
    dispatch({ type: types.LOGOUT });
  };
};
