import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { each, isFunction } from 'lodash';
import {
  Dropdown,
} from 'semantic-ui-react';

const RefDropdown = (props) => {
  const { data, placeholder, onChange, text } = props;
  const [ selected, setSelected ] = useState(null);

  let options = [];

  each(data, d => {
    const option = {
      key: d._id,
      value: d._id,
      text: isFunction(text) ? text(d) : d[text]
    };
    if (d.disabled) option.disabled = true;
    options.push(option);
  });

  return (
    <div>
      <Dropdown
        fluid
        placeholder={placeholder}
        search
        selection
        options={options}
        value={selected}
        onChange={onChangedHandler}
      />
    </div>
  );

  function onChangedHandler (e, data) {
    setSelected(data.value);
    onChange(data.value);
  };
};

RefDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired
};


export default RefDropdown;