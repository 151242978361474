import PropTypes from 'prop-types'
import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import useImageDownloader from 'hooks/useImageDownloader';
import dauria from 'dauria';
import {
  Dimmer,
  Image,
  Loader
} from 'semantic-ui-react';

const CustomView = (props) => {
  const { data, isModal } = props;
  const { image, loading } = useImageDownloader(data.service, data.src);
  if (!isModal) return null;

  if (loading) {
    return (
      <Dimmer active page>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  } else {
    if (image) {
      const uri = dauria.getBase64DataURI(image.data, image.mimeType);
      return <Image src={uri} style={{width: 'auto', height: `${window.innerHeight - 100}px`}} />
    } else {
      return null;
    }
  }
};

const ImageModal = (props) => {
  const {
    imageDownloaderServiceName,
    imageModalIsOpen,
    imageModalSource,
    setImageModalIsOpen
  } = props;
  const imageView = [{ src: imageModalSource, service: imageDownloaderServiceName }];

  return (
    <ModalGateway>
      {imageModalIsOpen ? (
        <Modal onClose={() => { setImageModalIsOpen(false)} }>
          <Carousel views={imageView} components={{ View: CustomView }} />
        </Modal>
      ) : null }
    </ModalGateway>
  );
};

ImageModal.propTypes = {
  imageDownloaderServiceName: PropTypes.string.isRequired,
  imageModalIsOpen: PropTypes.bool.isRequired,
  imageModalSource: PropTypes.string.isRequired,
  setImageModalIsOpen: PropTypes.func.isRequired
};

export default ImageModal;