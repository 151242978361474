import PropTypes from 'prop-types'
import React, { useState } from 'react';
import {
  Button,
  Card,
  Divider,
  Form,
  Icon,
  Label,
  Popup,
  TextArea
} from 'semantic-ui-react';
import { get } from 'lodash';
import DateTimeRenderer from 'components/Renderer/DateTime';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';

const ProcessingCard = (props) => {
  const { i18n, t } = useTranslation(['transaction', 'translation']);
  const [ remark, setRemark ] = useState('');
  const [ txnTime, setTxnTime ] = useState(new Date());

  const { onStatusUpdateCb, setImageModalSourceCb, transactionData } = props;
  const transactionId = get(transactionData, '_id', '');
  const createdAt = get(transactionData, 'createdAt', null);
  const amount = get(transactionData, 'amount.$numberDecimal', 0);
  const toBankName = get(transactionData, 'toAccount.bank.name', t(`translation:text.nil`));
  const toBankAccountHolder = get(transactionData, 'toAccount.accountHolder', t(`translation:text.nil`));
  const toBankAccountNumber = get(transactionData, 'toAccount.accountNumber', t(`translation:text.nil`));
  const toBankTagColor = get(transactionData, 'toAccount.bank.tagColor', 'grey');
  const fromBankAccountHolder = get(transactionData, 'fromAccount.accountHolder', t(`translation:text.nil`));
  const fromBankAccountNumber = get(transactionData, 'fromAccount.accountNumber', t(`translation:text.nil`));
  const fromBankTagColor = get(transactionData, 'fromAccount.bank.tagColor', 'grey');
  const proofOfTransfer = get(transactionData, 'proofOfTransfer', '');
  const bankReference = get(transactionData, 'bankReference', '');
  const userContact = get(transactionData, 'user.contact', '');
  const username = get(transactionData, 'user.username', '');
  const bonusName = get(transactionData, 'depositBonus.setting.name', '');
  const bonusAmount = get(transactionData, 'depositBonus.amount.$numberDecimal', 0);
  const staticRemark = get(transactionData, 'remark', '');
  let extraProps = {};
  if (toBankTagColor) extraProps.color = toBankTagColor;

  const onRemarkChange = (e, { value }) => {
    const extractedRemark = value.slice(staticRemark.length + 1);
    setRemark(extractedRemark);
  };

  return (
    <Card>
      <Label attached='top'><DateTimeRenderer value={createdAt} /></Label>
      <Card.Content>
        <Card.Header>{amount}</Card.Header>
        <Card.Meta>{toBankName}</Card.Meta>
        <Card.Description>
          <strong>{toBankAccountHolder}</strong>
          <br />
          <Label style={{
            margin: 10
          }} color={toBankTagColor} size='huge' horizontal>{toBankAccountNumber}</Label>
          <Divider horizontal>{t(`transaction:divider.bonus`)}</Divider>
          <Label size='huge' horizontal>{bonusName}</Label>
          <br />
          <strong>{`${t('translation:text.creditWithCount', { count: bonusAmount })}`}</strong>
          <Divider horizontal>{t(`transaction:divider.customerBank`)}</Divider>
          <strong>{fromBankAccountHolder}</strong>
          <br />
          <Label style={{
            margin: 10
          }} color={fromBankTagColor} size='huge' horizontal>{fromBankAccountNumber}</Label>
          {
            username &&
            <p>
              <Icon name='user' />
              {username}
            </p>
          }
          { userContact ?
            <p><a rel='noopener noreferrer' href={`https://wa.me/${userContact}`} target='_blank'><Icon name='whatsapp' />{userContact}</a></p>
            : null }
          { renderBankReference() }
          { renderTransactionTime() }
          <Divider horizontal>{t(`transaction:divider.additional`)}</Divider>
          <Form>
            <TextArea style={{ width: "100%" }} placeholder={t(`transaction:textarea.remark`)} value={`${staticRemark} ${remark}`} onChange={onRemarkChange} />
          </Form>
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <div className='ui two buttons'>
          <Button color='green' onClick={ () => {
            const updateData = {
              status: 'approved',
              transactionTime: txnTime
            };
            updateData.remark = `${staticRemark} ${remark}`;
            onStatusUpdateCb(transactionId, updateData);
          }}>
            {t(`button.approve`)}
          </Button>
          <Button color='red' onClick={ () => {
            const updateData = {
              status: 'rejected',
              transactionTime: txnTime
            };
            updateData.remark = `${staticRemark} ${remark}`;
            onStatusUpdateCb(transactionId, updateData);
          }}>
            {t(`button.reject`)}
          </Button>
        </div>
      </Card.Content>
      <Card.Content>
        <Popup inverted content={t(`transaction:popup.copyId`)} trigger={
          <Button icon labelPosition='right' onClick={ () => { navigator.clipboard.writeText(transactionId) } }>
            <Icon name='copy' />
            {transactionId}
          </Button>
        } />
      </Card.Content>
    </Card>
  );

  function renderBankReference () {
    if (!bankReference && !proofOfTransfer) return null;
    return (
      <div>
        <Divider horizontal>{t(`transaction:divider.bankReference`)}</Divider>
        { bankReference ? <p>{bankReference}</p> : null }
        { renderProofOfTransfer() }
      </div>
    );
  };

  function renderTransactionTime () {
    if (!txnTime) return null;
    const locale = i18n.language.toLowerCase();
    return (
      <div>
        <Divider horizontal>{t(`transaction:divider.transactionTime`)}</Divider>
        <DateTimePicker locale={locale} maxDate={new Date()} format='dd/MM/y hh:mm:ss a' clearIcon={null} value={txnTime} onChange={setTxnTime} />
      </div>
    );
  };

  function renderProofOfTransfer () {
    if (!proofOfTransfer) return null;
    return (
      <div>
        <p>
        <Button size='small' fluid onClick={() => { setImageModalSourceCb(proofOfTransfer) }}>
          {t(`button.pot`)}
        </Button>
        </p>
      </div>
    );
  };
};

ProcessingCard.propTypes = {
  onStatusUpdateCb: PropTypes.func.isRequired,
  setImageModalSourceCb: PropTypes.func.isRequired,
  transactionData: PropTypes.object.isRequired
};

export default ProcessingCard;






