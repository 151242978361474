import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { updateConnectionStatus } from 'store/io/actions';
import { getConnectedStatus } from 'store/io/reducer';
import { socketClient } from 'services/feathers';
import { getIsAuthenticated } from 'store/auth/reducer';
import { useTranslation } from 'react-i18next';

const SocketConnectionStatus = (props) => {
  const { t } = useTranslation(['translation']);
  const {
    connected,
    isAuthenticated,
    setConnected,
    setDisconnected
  } = props;

  const onConnectCb = useCallback(
    () => {
      setConnected();
    },
    [setConnected]
  );

  const onDisconnectCb = useCallback(
    () => {
      setDisconnected();
    },
    [setDisconnected]
  );

  useEffect(() => {
    if (!socketClient.disconnected === connected) return;
    if (socketClient.disconnected) setDisconnected();
    else setConnected();
  }, [connected, setConnected, setDisconnected]);

  useEffect(() => {
    socketClient.on('connect', onConnectCb);
    socketClient.on('disconnect', onDisconnectCb);

    return () => {
      socketClient.removeListener('connect', onConnectCb);
      socketClient.removeListener('disconnect', onDisconnectCb);
    };
  }, [onConnectCb, onDisconnectCb])

  return (
    <Dimmer active={!connected && isAuthenticated}>
      <Loader>{t(`loaderText.connecting`)}</Loader>
    </Dimmer>
  );
};

const mapStateToProps = (state) => {
  return {
    connected: getConnectedStatus(state),
    isAuthenticated: getIsAuthenticated(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConnected: () => {
      dispatch(updateConnectionStatus(true))
    },
    setDisconnected: () => {
      dispatch(updateConnectionStatus(false))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketConnectionStatus);
