import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
} from 'semantic-ui-react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';

const ModalTextEdit = (props) => {
  const [ open, setOpen ] = useState(false);
  const [text, setText] = useState(null);
  const { field, objectid, onValueChanged, value } = props;

  //const [ text, setText ] = useState(value ? EditorState.createWithContent(convertFromRaw(value).getCurrentContent()) : EditorState.createEmpty());
  const pathName = props.parentPath ? `${props.parentPath}.${field.name}` : field.name;

  useEffect(() => {
    if (text !== null) return;
    if (value) {
      const content = convertFromRaw(JSON.parse(value));
      setText(EditorState.createWithContent(content));
    } else {
      setText(EditorState.createEmpty());
    }
  }, [text, value]);

  const onEditorStateChange = (editorState) => {
    setText(editorState);
    const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    onValueChanged(null, {
      objectid: objectid,
      objectkey: pathName,
      value: rawContent
    })
  };

  const { t } = useTranslation();
  return (
    <Modal open={open} trigger={<Button size='small' onClick={ () => { setOpen(true); } }>{`${t(`table:button.edit`)}`}</Button>} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`table:field.${field.name}`)}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
          {
            text &&
            <Editor
              editorState={text}
              onEditorStateChange={onEditorStateChange}
            />
          }
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ () => { setOpen(false); } } content={t('table:button.close')} labelPosition='right' icon='close' />
        </Modal.Actions>
    </Modal>
  );
};

ModalTextEdit.propTypes = {
  field: PropTypes.object.isRequired,
  objectid: PropTypes.string.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

export default ModalTextEdit;