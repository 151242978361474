import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Message,
  Modal
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { get } from 'lodash';
import DateTimePicker from 'react-datetime-picker';

const ModalDateTimeEditView = (props) => {
  const {
    editObject,
    editKey,
    localeKey,
    onClose,
    service
  } = props;
  const { t } = useTranslation();
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  const dt = get(editObject, editKey, new Date());
  const [txnTime, setTxnTime] = useState(new Date(dt));

  const onSubmit = async () => {
    const data = {
      [editKey]: txnTime
    };
    try {
      setError(null);
      setStatus('saving');
      await feathers.service(service).update(editObject._id, data);
      setStatus('saved');
    } catch (err) {
      setError(err.message);
      setStatus('idle');
    }
  };

  useEffect(() => {
    if (status === 'saved') {
      onClose();
    }
  }, [onClose, status]);


  return (
    <Modal
      open={editObject !== null}
      onClose={onClose}
      size='tiny'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
    >
      <Modal.Header>{t(`translation:modalTitle.${localeKey}`)}</Modal.Header>
      <Modal.Content>
        <DateTimePicker
          maxDate={new Date()}
          format='dd/MM/y hh:mm:ss a'
          clearIcon={null}
          value={txnTime}
          onChange={setTxnTime}
        />
        {
          error &&
          <Message negative>
            <Message.Header>{t(`translation:modalTitle.error`)}</Message.Header>
            <p>{error}</p>
          </Message>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button
            positive
            onClick={onSubmit}
            content={t(`table:button.save`)}
            labelPosition='left'
            icon='save'
          />
          <Button negative onClick={onClose} content={t(`table:button.close`)} labelPosition='right' icon='close' />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  )
};

ModalDateTimeEditView.propTypes = {
  editObject: PropTypes.object.isRequired,
  editKey: PropTypes.string.isRequired,
  localeKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
};

export default ModalDateTimeEditView
