import { getUserId } from 'store/auth/reducer';
import { cloneDeep, countBy, each, filter, get, has, includes, isArray, map, toNumber, sumBy } from 'lodash';
const _REFS_RESULT = {};

export function getQueryResult (state, modelName) {
  return get(state, `${modelName}.queryResult`, {});
};

export function getPagination (state, modelName) {
  if (!has(state, `${modelName}.queryResult`)) return {};
  const { total, limit, skip } = state[modelName].queryResult;
  return { total, limit, skip };
};

export function getRefsLoadingStatus (state, refFields) {
  for (const ref of refFields){
    const refStateName = `${ref.modelName}Ref`;
    const isSaving = state[refStateName].isSaving;
    const isLoading = state[refStateName].isLoading;
    if (isSaving || isLoading) return true;
  }
  return false;
};

export function getRefsResult (state, refFields) {
  for (let key in _REFS_RESULT) delete _REFS_RESULT[key];
  each(refFields, r => {
    const { modelName } = r;
    const refStateName = `${modelName}Ref`;
    _REFS_RESULT[modelName] = getPersistentRefData(state, refStateName);
  });
  return _REFS_RESULT;
};

export function getRefData (state, modelName, objectName, field) {
  if (field.type !== 'ObjectId') return [];
  if (field.refPath) {
    const selectedRefPath = get(state, `${modelName}Table.${objectName}.${field.refPath}`);
    if (!selectedRefPath) return [];
    const refStateName = `${selectedRefPath}Ref`;
    return getPersistentRefData(state, refStateName);
  } else {
    const refStateName = `${field.ref}Ref`;
    return getPersistentRefData(state, refStateName);
  }
};

export function getUniqueRefData (state, modelName, objectName, field, arrName) {
  const refData = cloneDeep(getRefData(state, modelName, objectName, field));
  const selectedRef = get(state, `${modelName}Table.${objectName}.${arrName}`, []);
  const selectedRefId = filter(map(selectedRef, ref => {
    const getProduct = get(ref, 'product', null);
    if (!getProduct) return null;
    const getProductId = get(getProduct, '_id', null);
    if (!getProductId) return getProduct;
    else return getProductId;
  }), f => { return f !== null });
  if (!selectedRefId.length) return refData;
  map(refData, ref => {
    if (includes(selectedRefId, ref._id)) {
      ref.disabled = true;
    }
    return ref;
  });
  return refData;
};

export function getPersistentRefData (state, refStateName) {
  const refData = get(state, `${refStateName}.queryResult.data`, []);
  if (!isArray(refData)) return [];
  return refData;
};

export function getPendingTransactions (state, modelName) {
  const queryResult = get(state, `${modelName}Task.queryResult.data`, []);
  if (!queryResult.length) return [];

  const pending = filter(queryResult, result => {
    const transactionAmount = toNumber(get(result, 'amount.$numberDecimal', result.amount));
    return result.status === 'pending' && !result.proofOfTransfer && !result.bankReference && transactionAmount > 0;
  });
  return pending;
};

export function getProcessableTransactions (state, modelName) {
  const queryResult = get(state, `${modelName}Task.queryResult.data`, []);
  if (!queryResult.length) return [];

  const processable = filter(queryResult, result => {
    const transactionAmount = toNumber(get(result, 'amount.$numberDecimal', result.amount));
    return result.status === 'pending' && (result.proofOfTransfer || result.bankReference || transactionAmount === 0);
  });
  return processable;
};

export function getProcessingTransactions (state, modelName) {
  const userId = getUserId(state);
  if (!userId) return [];

  const queryResult = get(state, `${modelName}Task.queryResult.data`, []);
  if (!queryResult.length) return [];

  const processing = filter(queryResult, result => {
    return (result.status === 'processing' && result.executor._id === userId);
  });
  return processing;
};

export function getPendingTransactionsCount (state, modelName) {
  const queryResult = get(state, `${modelName}Task.queryResult.data`, []);
  if (!queryResult.length) return 0;

  const count = countBy(queryResult, result => {
    return result.status === 'pending';
  });
  return count.true || 0;
};

export function getProcessingTransactionsCount (state, modelName) {
  const userId = getUserId(state);
  if (!userId) return 0;

  const queryResult = get(state, `${modelName}Task.queryResult.data`, []);
  if (!queryResult.length) return 0;

  const count = countBy(queryResult, result => {
    return (result.status === 'processing' && result.executor._id === userId);
  });
  return count.true || 0;
};

export function getServiceIsLoading (state, serviceName) {
  const isLoading = get(state, `${serviceName}.isLoading`, false);
  const isSaving = get(state, `${serviceName}.isSaving`, false);
  return (isSaving || isLoading) ? true: false;
};

export function getServiceIsError (state, serviceName) {
  return get(state, `${serviceName}.isError`, null);
};

export function getPageAmountSum (state, modelName) {
  const queryResult = get(state, `${modelName}.queryResult.data`, []);
  if (!queryResult.length) return 0;

  const total = sumBy(queryResult, d => {
    return toNumber(get(d, 'amount.$numberDecimal', 0));
  });
  return total;
};