import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Dropdown,
  Input
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { AbilityContext } from 'casl/ability-context';
import { each, get, isFunction, isNumber, merge } from 'lodash';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import { getCaslTestDataObject, getObjectValue } from 'store/table/reducer';
import { getUserRegionId, getUserRegionName } from 'store/auth/reducer';
import { getRefData, getUniqueRefData } from 'store/feathers/selectors';
import DataRenderer from './Data';
import ModalArrayEdit from 'components/Modal/ModalArrayEdit';
import ModalObjectEdit from 'components/Modal/ModalObjectEdit';
import ModalTextEdit from 'components/Modal/ModalTextEdit';

const TYPE_KEY = Symbol.for('type');

const InputRenderer = (props) => {
  const ability = useContext(AbilityContext);
  const { i18n, t } = useTranslation();
  const {
    action,
    caslTestDataObject,
    field,
    modelName,
    objectid,
    onValueChanged,
    refData,
    regionId,
    regionName,
    value
  } = props;

  if (!field) return null;
  const fieldName = field.name.split('.')[0] || field.name;
  const pathName = props.parentPath ? `${props.parentPath}.${field.name}` : field.name;
  const can = ability.can(action, modelName, fieldName);
  if (!can && action === 'create') return renderProtected();
  if (!can && action === 'update') return renderViewOnly();

  const type = getType(field);

  switch (type) {
    case 'Number':
      return renderNumberInput();
    case 'String':
      return renderStringInput();
    case 'Enum':
      return renderEnumInput();
    case 'ObjectId':
      return renderRefInput();
    case 'Date':
      return renderDateInput();
    case 'Boolean':
      return renderBooleanInput();
    case 'Array':
      return renderArrayInput();
    case 'Object':
      return renderObjectInput();
    case 'Text':
      return renderTextInput();
    default:
      return renderEmpty();
  };

  function renderNumberInput () {
    const extraProps = {};
    if (isNumber(field.min)) extraProps.min = field.min;
    if (isNumber(field.max)) extraProps.max = field.max;
    if (field.label) {
      extraProps.label = field.label.text;
      extraProps.labelPosition = field.label.position
    }

    return (
      <Input
        placeholder={getPlaceHolder(field, t)}
        objectid={objectid}
        objectkey={pathName}
        value={getDefaultValue(field, value)}
        onChange={onValueChanged}
        type='number'
        { ...extraProps }
      />
    );
  };

  function renderStringInput () {
    const extraProps = {};
    if (field.mask) extraProps.type = 'password';
    return (
      <Input
        placeholder={getPlaceHolder(field, t)}
        objectid={objectid}
        objectkey={pathName}
        value={value}
        onChange={onValueChanged}
        {...extraProps}
      />
    );
  };

  function renderEnumInput () {
    const transRequired = translateRequired(field);

    let options = [];

    each(field.enum, value => {
      let test = {
        [TYPE_KEY]: modelName,
        [field.name]: value
      };
      test = merge(test, caslTestDataObject);
      if (regionName !== 'Open') test.region = regionId;
      if (action === 'update') test._id = objectid;

      const isAllowed = ability.can(action, test);
      if (!isAllowed) return;
      options.push({
        key: `${objectid}${field.name}${value}`,
        value: value,
        text: transRequired ? t(`table:enum.${field.name}.${value}`) : value
      });
    });

    return (
      <Dropdown
        placeholder={getPlaceHolder(field, t)}
        search
        selection
        options={options}
        objectid={objectid}
        objectkey={pathName}
        value={value}
        onChange={onValueChanged}
      />
    );
  };

  function renderRefInput () {
    if (!refData.length) return renderEmpty();

    let options = [];

    each(refData, data => {
      let test = {
        [TYPE_KEY]: modelName,
        [field.name]: data[field.value]
      };
      test = merge(test, caslTestDataObject);
      if (regionName !== 'Open') test.region = regionId;
      if (action === 'update') test._id = objectid;

      const isAllowed = ability.can(action, test);
      if (!isAllowed) return;

      const option = {
        key: `${objectid}${field.name}${data._id}`,
        value: data[field.value],
        text: isFunction(field.text) ? field.text(data) : data[field.text]
      };
      if (data.disabled) option.disabled = true;
      if (field.flag) option.flag = data[field.flag];
      options.push(option);
    });

    return (
      <Dropdown
        placeholder={getPlaceHolder(field, t)}
        search
        selection
        options={options}
        objectid={objectid}
        objectkey={pathName}
        value={get(value, '_id', value)}
        onChange={onValueChanged}
      />
    );
  };

  function renderDateInput () {
    const locale = i18n.language.toLowerCase();
    return (
      <DateTimeInput
        closable
        localization={locale}
        animation='none'
        objectid={objectid}
        objectkey={pathName}
        value={getDefaultValue(field, value)}
        dateFormat={'YYYY/MM/DD'}
        onChange={onValueChanged}
        popupPosition='bottom center'
      />
    );
  };

  function renderBooleanInput () {
    const transRequired = translateRequired(field);
    const tPath = field.tPath;
    const translatepath = tPath ? `table:boolean.${tPath}` : `table:boolean.${field.name}`;

    const options = [
      {
        key: `${objectid}${field.name}0`,
        value: false,
        text: transRequired ? t(`${translatepath}.false`) : t(`table:boolean.false`)
      },
      {
        key: `${objectid}${field.name}1`,
        value: true,
        text: transRequired ? t(`${translatepath}.true`) : t(`table:boolean.true`)
      }
    ];

    if (field.checkbox) {
      return (
        <Checkbox toggle
          placeholder={getPlaceHolder(field, t)}
          objectid={objectid}
          objectkey={pathName}
          checked={getDefaultValue(field, value)}
          onChange={(e, data) => {
            data.value = data.checked;
            onValueChanged(e, data);
          }}
        />
      )
    }

    return (
      <Dropdown
        placeholder={getPlaceHolder(field, t)}
        search
        selection
        options={options}
        objectid={objectid}
        objectkey={pathName}
        value={getDefaultValue(field, value)}
        onChange={onValueChanged}
      />
    );
  };

  function renderArrayInput () {
     return (
        <ModalArrayEdit { ...props }/>
    );
  };

  function renderObjectInput () {
     return (
        <ModalObjectEdit { ...props }/>
    );
  };

  function renderTextInput () {
    return (
      <ModalTextEdit { ...props } />
    );
  };

  function renderEmpty () {
    return null;
  };

  function renderProtected () {
    return t(`table:field._protected`);
  };

  function renderViewOnly () {
    return (
      <DataRenderer
        modelName={modelName}
        value={value}
        field={field}
        refData={refData}
      />
    );
  };
};

function getType (field) {
  if (field.enum) return 'Enum';
  return field.type || 'String';
};

function translateRequired (field) {
  return field.translate ? true : false;
};

function getPlaceHolder (field, t) {
  const localeKey = get(field, 'localeKey', field.name);
  const name = t(`table:field.${localeKey}`);
  return field.required ? `${name}*` : name;
};

function getDefaultValue (field, value) {
  let getValue = (field.default !== undefined && value === '') ? field.default : value;
  if (field.type === 'Boolean' && getValue === '') getValue = false;
  return getValue;
};

InputRenderer.propTypes = {
  action: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  modelName: PropTypes.string.isRequired,
  objectid: PropTypes.string.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  regionId: PropTypes.string.isRequired,
  regionName: PropTypes.string.isRequired,
  refData: PropTypes.array,
  value: PropTypes.any.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { arrname, caslTestData = [], field, modelName, objectName } = ownProps;
  const { unique } = field;
  const name = ownProps.parentPath ? `${ownProps.parentPath}.${field.name}` : field.name;
  return {
    caslTestDataObject: getCaslTestDataObject(state, modelName, objectName, caslTestData),
    regionId: getUserRegionId(state),
    regionName: getUserRegionName(state),
    refData: unique === true ?
      getUniqueRefData(state, modelName, objectName, field, arrname) :
      getRefData(state, modelName, objectName, field),
    value: getObjectValue(state, modelName, objectName, name)
  };
};

export default connect(mapStateToProps)(InputRenderer);