import { createMedia } from "@artsy/fresnel";
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import { connect } from 'react-redux';
import * as authActions from 'store/auth/actions';
import MenuLink from 'components/MenuLink';
import { withTranslation } from 'react-i18next';
import Footer from 'components/Footer';
import {version} from '../../package.json';

// Screens
import ApiSettingsKing855Screen from './ApiSettingsKing855Screen';
import ApiSettingsKiss918Screen from './ApiSettingsKiss918Screen';
import AppVersionsScreen from './AppVersionsScreen';
import BankBalanceAdjLogsScreen from './BankBalanceAdjLogsScreen';
import BanksScreen from './BanksScreen';
import BonusSettingsScreen from './BonusSettingsScreen';
import CompanyBankAccountsScreen from './CompanyBankAccountsScreen';
import CurrenciesScreen from './CurrenciesScreen';
import CustomerBankAccountsScreen from './CustomerBankAccountsScreen';
import DailyWinLossReportsScreen from './DailyWinLossReportsScreen';
import DepositsScreen from './DepositsScreen';
import DepositTasksScreen from './DepositTasksScreen';
import ExtBalanceTransferLogsKiss918 from './ExtBalanceTransferLogsKiss918';
import ExtBetDetailLogsKing855Screen from './ExtBetDetailLogsKing855Screen';
import ExtBetLogsKing855Screen from './ExtBetLogsKing855Screen';
import ExtCompanyDailyWinLossLogsKing855Screen from './ExtCompanyDailyWinLossLogsKing855Screen';
import ExtCompanyDailyWinLossLogsKiss918Screen from './ExtCompanyDailyWinLossLogsKiss918Screen';
import ExtUsersKing855Screen from './ExtUsersKing855Screen';
import ExtUsersKiss918Screen from './ExtUsersKiss918Screen';
import FilesScreen from './FilesScreen';
import HomeScreen from './HomeScreen';
import LocalesScreen from './LocalesScreen';
import NewsScreen from './NewsScreen';
import ProductsScreen from './ProductsScreen';
import RegionsScreen from './RegionsScreen';
import SettingsScreen from './SettingsScreen';
import SupportsScreen from './SupportsScreen';
import TransactionReportsScreen from './TransactionReportsScreen';
import UsersScreen from './UsersScreen';
import WalletBalanceAdjLogsScreen from './WalletBalanceAdjLogsScreen';
import WalletsScreen from './WalletsScreen';
import WithdrawalsScreen from './WithdrawalsScreen';
import WithdrawalTasksScreen from './WithdrawalTasksScreen';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920
  }
});
const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    //const { fixed } = this.state
    const { onLogoutClick } = this.props;
    const { t } = this.props;

    return (
      <div>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Segment basic as={Media} greaterThanOrEqual="computer">
            <Visibility
              once={false}
              onBottomPassed={this.showFixedMenu}
              onBottomPassedReverse={this.hideFixedMenu}
            >
              <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, overflowX: 'hidden', width: '280px' }}>
                <Menu fluid vertical compact attached inverted borderless
                  style={{ minHeight: '100%' }}
                >
                  <Container textAlign='left'>
                    <Menu.Item header>
                      <p>
                        <Icon name='gem' size='large' color='orange' />
                        <strong>
                          &nbsp;CCrm&nbsp;
                          <small>
                            {version}
                          </small>
                        </strong>
                      </p>
                    </Menu.Item>
                    <MenuLink />
                    <Menu.Item
                      name={t('menu.logout')}
                      onClick={onLogoutClick}
                      position='right'
                      icon='sign-out'
                    />
                  </Container>
                </Menu>
              </div>
            </Visibility>
            <div style={{ marginLeft: '280px', minWidth: '550px' }}>
              {children}
              <Footer />
            </div>
          </Segment>
        </MediaContextProvider>
      </div>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state
    const { onLogoutClick } = this.props;

    //const screen = match.params.screen;

    return (
      <div>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Segment basic as={Media} lessThan="computer">
            <Sidebar
              as={Menu}
              animation='push'
              inverted
              onHide={this.handleSidebarHide}
              vertical
              visible={sidebarOpened}
            >
              <MenuLink />
            </Sidebar>

            <Sidebar.Pusher dimmed={sidebarOpened}>
              <Segment
                inverted
                textAlign='center'
                //style={{ minHeight: 350, padding: '1em 0em' }}
                vertical
              >
                <Container>
                  <Menu inverted pointing secondary size='large'>
                    <Menu.Item onClick={this.handleToggle}>
                      <Icon name='sidebar' />
                    </Menu.Item>
                    <Menu.Item position='right'>
                      <Button as='a' inverted onClick={onLogoutClick}>
                        Logout
                      </Button>
                    </Menu.Item>
                  </Menu>
                </Container>
              </Segment>
              {React.cloneElement(children, { mobile: true })}
              <Footer />
            </Sidebar.Pusher>
          </Segment>
        </MediaContextProvider>
      </div>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = (props) => (
  <div>
    <DesktopContainer { ...props } />
    <MobileContainer { ...props } />
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class MainScreenLegacy extends Component {
  render() {
    const { match } = this.props;
    const screen = match.params.screen;

    const screens = {
      'api-settings-king-855': ApiSettingsKing855Screen,
      'api-settings-kiss-918': ApiSettingsKiss918Screen,
      'app-versions': AppVersionsScreen,
      'bank-balance-adj-logs': BankBalanceAdjLogsScreen,
      'banks': BanksScreen,
      'bonus-settings': BonusSettingsScreen,
      'company-bank-accounts': CompanyBankAccountsScreen,
      'currencies': CurrenciesScreen,
      'customer-bank-accounts': CustomerBankAccountsScreen,
      'daily-win-loss-reports': DailyWinLossReportsScreen,
      'deposit-logs': DepositsScreen,
      'ext-balance-transfer-logs-kiss-918': ExtBalanceTransferLogsKiss918,
      'ext-bet-detail-logs-king-855': ExtBetDetailLogsKing855Screen,
      'ext-bet-logs-king-855': ExtBetLogsKing855Screen,
      'ext-company-daily-win-loss-logs-king-855': ExtCompanyDailyWinLossLogsKing855Screen,
      'ext-company-daily-win-loss-logs-kiss-918': ExtCompanyDailyWinLossLogsKiss918Screen,
      'ext-users-king-855': ExtUsersKing855Screen,
      'ext-users-kiss-918': ExtUsersKiss918Screen,
      'files': FilesScreen,
      'home': HomeScreen,
      'locales': LocalesScreen,
      'news': NewsScreen,
      'products': ProductsScreen,
      'regions': RegionsScreen,
      'settings': SettingsScreen,
      'supports': SupportsScreen,
      'task-deposit-logs': DepositTasksScreen,
      'task-withdrawal-logs': WithdrawalTasksScreen,
      'transaction-reports': TransactionReportsScreen,
      'users': UsersScreen,
      'wallet-balance-adj-logs': WalletBalanceAdjLogsScreen,
      'wallets': WalletsScreen,
      'withdrawal-logs': WithdrawalsScreen,
    };

    const ChildScreen = screens[screen] || HomeScreen;
    return (<ResponsiveContainer { ...this.props }><ChildScreen /></ResponsiveContainer>);
  }
}

const MainScreen = withTranslation()(MainScreenLegacy);

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutClick: () => {
      dispatch(authActions.logout());
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
