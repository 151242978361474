import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const BonusSettingsScreen = (props) => {
  const schema = {
    modelName: 'bonusSettings',
    serviceName: 'bonus-settings',
    fields: [
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'name',
        type: 'String',
        required: true
      },
      {
        name: 'type',
        type: 'String',
        translate: true,
        enum: ['fixed', 'percentage'],
        required: true
      },
      {
        name: 'amount',
        type: 'Number',
        required: true
      },
      {
        name: 'wagerMultiplier',
        type: 'Number',
        required: true
      },
      {
        name: 'productsIncluded',
        type: 'Array',
        arraySchema: {
          fields:
          [
            {
              name: 'product',
              type: 'ObjectId',
              ref: 'products',
              value: '_id',
              text: 'name',
              required: true,
              unique: true
            },
            {
              name: 'percentage',
              type: 'Number',
              required: true,
              label: {
                position: 'right',
                text: '%'
              },
              min: 0,
              max: 100
            }
          ]
        },
        text: (object) => { return `${object.product.name} @ ${object.percentage}%` },
        required: true
      },
      { name: 'minDeposit', type: 'Number', required: true, min: 0 },
      { name: 'minWithdrawal', type: 'Number', required: true, min: 0 },
      { name: 'maxWithdrawal', type: 'Number', required: true, min: 0 },
      { name: 'maxBonus', type: 'Number', required: true, min: 0 },
      { name: 'startDate', type: 'Date', required: true },
      { name: 'endDate', type: 'Date', required: true },
      { name: 'applicationLimit', type: 'Number', required: true, min: 0 },
      { name: 'reapplicationFreqInDays', type: 'Number', required: true, min: 0 },
      {
        name: 'daysAllowed',
        type: 'Object',
        objectSchema: {
          fields: [
            { name: 'mon', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day' },
            { name: 'tue', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day'  },
            { name: 'wed', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day'  },
            { name: 'thu', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day'  },
            { name: 'fri', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day'  },
            { name: 'sat', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day'  },
            { name: 'sun', type: 'Boolean', default: true, required: true, checkbox: true, translate: true, "tPath": 'day'  },
            { name: 'birthday', type: 'Boolean', default: false, required: true, checkbox: true, translate: true, "tPath": 'day'  }
          ]
        }
      },
      {
        name: 'compensateMode',
        type: 'Boolean',
        default: false,
        required: true,
        translate: true
      },
      {
        name: 'referralMode',
        type: 'Boolean',
        default: false,
        required: true,
        translate: true
      },
      {
        name: 'isEnabled',
        type: 'Boolean',
        default: true,
        required: true,
        translate: true
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <Segment style={TableSegment} vertical>
      <TableView schema={schema} refFields={extractRefFields(schema)} />
    </Segment>
  );
};

BonusSettingsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(BonusSettingsScreen);