import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  username: '',
  password: '',
  code: ''
});

export default function reduce (state = initialState, action = {}) {
  switch (action.type) {
    case types.USERNAME_CHANGED:
      return state.merge({
        username: action.username
      });
    case types.PASSWORD_CHANGED:
      return state.merge({
        password: action.password
      });
    case types.CODE_CHANGED:
      return state.merge({
        code: action.code
      });
    case types.CLEAN_FORM:
      return state.merge({
        password: '',
        code: ''
      });
    default:
      return state;
  }
};

export function getUsername (state) {
  return state.login.username;
};

export function getPassword (state) {
  return state.login.password;
};

export function getCode (state) {
  return state.login.code;
};
