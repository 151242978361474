import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import ModalBalanceAdjView from 'components/Modal/ModalBalanceAdjView';
import { TableSegment } from 'styles/common';

const CompanyBankAccountsScreen = (props) => {
  const [ balAdjObject, setBalAdjObject ] = useState(null);

  const onBalanceAdjCallback = useCallback(
    (object) => {
      setBalAdjObject(object);
    },
    []
  );

  const onBalanceAdjModalCloseCallback = useCallback(
    () => {
      setBalAdjObject(null);
    },
    []
  );

  const schema = {
    modelName: 'companyBankAccounts',
    serviceName: 'company-bank-accounts',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'balance.$numberDecimal',
        type: 'Number',
        localeKey: 'amountMonetary',
        callback: {
          buttonIcon: 'edit',
          pickFields: ['_id', 'region'],
          onClick: onBalanceAdjCallback
        }
      },
      {
        name: 'aliasName',
        type: 'String'
      },
      {
        name: 'bank',
        refField: 'bank.name',
        type: 'ObjectId',
        ref: 'banks',
        value: '_id',
        text: 'name',
        localeKey: 'bankName',
        required: true
      },
      {
        name: 'accountHolder',
        type: 'String',
        sortable: true
      },
      {
        name: 'accountNumber',
        type: 'String',
        sortable: true
      },
      {
        name: 'isEnabled',
        type: 'Boolean',
        default: true,
        required: true,
        translate: true
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
      {
        balAdjObject !== null ?
        <ModalBalanceAdjView
          balAdjObject={balAdjObject}
          onClose={onBalanceAdjModalCloseCallback}
          refField='bankAccount'
          service='bank-balance-adj-logs'
          localeKey='bankAccountBalAdj'
        /> :
        <TableView schema={schema} refFields={extractRefFields(schema)} />
      }
      </Segment>
    </div>
  );
};

CompanyBankAccountsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(CompanyBankAccountsScreen);