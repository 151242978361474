import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Flag,
  Icon,
  Image
} from 'semantic-ui-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AbilityContext } from 'casl/ability-context';
import { find, get, isFunction, isNumber, toNumber } from 'lodash';
import dauria from 'dauria';
import prettyBytes from 'pretty-bytes';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import ModalArrayView from 'components/Modal/ModalArrayView';
import ModalObjectView from 'components/Modal/ModalObjectView';
import ModalTextView from 'components/Modal/ModalTextView';

const DataRenderer = (props) => {
  const ability = useContext(AbilityContext);
  const { i18n, t } = useTranslation();
  const { callback, canEdit, field, mimeType, modelName, refData, value } = props;
  if (!field) return null;

  const fieldName = field.name.split('.')[0] || field.name;
  const canRead = ability.can('read', modelName, fieldName);

  if (!canRead) return renderProtected(t);

  const type = field.type || 'String';

  switch (type) {
    case 'Number':
      return renderBase(renderNumber());
    case 'String':
      return renderBase(renderString());
    case 'Boolean':
      return renderBoolean();
    case 'Date':
      return renderBase(renderDate(), false);
    case 'ObjectId':
      return renderObjectId();
    case 'Buffer':
      return renderBuffer();
    case 'Array':
      return renderArray();
    case 'Object':
      return renderObject();
    case 'Text':
      return renderText();
    default:
      return renderEmpty();
  };

  function renderBase (baseView, ignoreEmptyValue = true) {
    if (ignoreEmptyValue && !value) return baseView;

    // Check callback permission
    const checkUpdatePermission = get(callback, 'checkUpdatePermission', false);

    if (checkUpdatePermission && !canEdit) return baseView;

    if (callback) {
      return (
        <div>
          <Button size='mini' circular icon={callback.buttonIcon} onClick={
            () => {
              callback.onClick(callback.pickFields)
            }
          } />
          {
            baseView
          }
        </div>
      );
    }
    return baseView;
  };

  function renderEmpty () {
    return null;
  };

  function renderProtected () {
    return t(`table:field._protected`);
  };

  function renderNumber () {
    if (field.format === 'file' && isNumber(value)) {
      return prettyBytes(value);
    } else {
      return renderNumberString();
    }
  };

  function renderNumberString () {
    let val = toNumber(value).toFixed(2);
    if (field.hideValue) val = '';
    const translate = field.translate ? true : false;
    if (translate && val !== '') {
      return t(`table:enum.${field.name}.${val}`);
    }
    else {
      return val;
    }
  };

  function renderString () {
    let val = value;
    if (field.hideValue) val = '';
    const translate = field.translate ? true : false;
    const link = get(field, 'link');
    if (translate && val !== '') {
      return t(`table:enum.${field.name}.${val}`);
    } else if (link) {
      const href = link.href(val);
      const icon = get(field, 'link.icon');
      return (
        <p><a rel='noopener noreferrer' href={href} target='_blank'><Icon name={icon} />{val}</a></p>
      )
    } else {
      return val;
    }
  };

  function renderBoolean () {
    const translate = field.translate ? true : false;
    const tPath = field.tPath;
    const translatepath = tPath ? `table:boolean.${tPath}` : `table:boolean.${field.name}`;

    const valueString = !value ? "false" : "true";
    let formattedValue;
    if (translate)
      formattedValue = t(`${translatepath}.${valueString}`);
    else
      formattedValue = t(`table:boolean.${valueString}`);
    return formattedValue;
  };

  function renderDate () {
    const locale = i18n.language.toLowerCase();
    const formattedDate = value ? dayjs(value).locale(locale).format('YYYY/MM/DD hh:mm A (ddd)') : null;
    return formattedDate;
  };

  function renderObjectId () {
    const id = get(value, '_id', value);
    const refValue = find(refData, { _id: id });
    const text = isFunction(field.text) ? field.text(refValue) : get(refValue, field.text, value);

    let flag = null;
    if (field.flag) {
      const code = get(refValue, 'code', null);
      flag = code ? <Flag name={code} /> : null;
    }
    return (
      <span>
        {flag}
        {text}
      </span>
    );
  };

  function renderBuffer () {
    let uri;
    uri = dauria.getBase64DataURI(value, mimeType);
    return (
      <Image size='mini' src={uri} />
    );
  };

  function renderArray () {
    return (
      <ModalArrayView { ...props } />
    );
  };

  function renderObject () {
    return (
      <ModalObjectView { ...props } />
    );
  };

  function renderText () {
    return (
      <ModalTextView { ...props } />
    );
  };
};

DataRenderer.propTypes = {
  field: PropTypes.object.isRequired,
  modelName: PropTypes.string.isRequired,
  refData: PropTypes.array,
  mimeType: PropTypes.string,
  value: PropTypes.any.isRequired
};

export default DataRenderer;
