import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dropdown
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { each, get, isFunction } from 'lodash';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import { getPersistentRefData } from 'store/feathers/selectors';
import { getFilterValue } from 'store/table/reducer';
import { addFilter } from 'store/table/actions';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

const FilterDropdown = (props) => {
  const { i18n, t } = useTranslation();
  const {
    dispatch,
    filterField,
    filterValue,
    filterStyle = {},
    modelName,
    refData,
  } = props;

  if (!filterField) return null;
  const { filterKey } = filterField;

  const type = getType(filterField);

  switch (type) {
    case 'Enum':
      return renderEnumInput();
    case 'ObjectId':
      return renderRefInput();
    case 'DateRange':
      return renderDateRangeInput();
    default:
      return renderEmpty();
  };

  function onValueChanged (e, data) {
    const { filterkey, value } = data;
    dispatch(addFilter(modelName, filterkey, value));
  };

  function onDateRangeChanged (filterKey, data) {
    if (data === null) {
      dispatch(addFilter(modelName, filterKey, null));
    } else {
      const { dateKey } = filterField;
      const dr = {
        from: data[0],
        to: data[1],
        dateKey
      };
      dispatch(addFilter(modelName, filterKey, dr))
    }
  };

  function renderEnumInput () {
    const transRequired = translateRequired(filterField);

    let options = [];

    each(filterField.enum, value => {
      options.push({
        key: `${filterField.name}${value}`,
        value: value,
        text: transRequired ? t(`table:enum.${filterField.name}.${value}`) : value
      });
    });

    return (
      <Dropdown
        style={filterStyle}
        filterkey={filterKey}
        onChange={onValueChanged}
        options={options}
        placeholder={getPlaceHolder(filterField, t)}
        search
        selection
        value={filterValue}
      />
    );
  };

  function renderRefInput () {
    if (!refData.length) return renderEmpty();

    let options = [];

    each(refData, data => {
      const option = {
        key: `${filterField.name}${data._id}`,
        value: data[filterField.value],
        text: isFunction(filterField.text) ? filterField.text(data) : data[filterField.text]
      };
      if (data.disabled) option.disabled = true;
      if (filterField.flag) option.flag = data[filterField.flag];
      options.push(option);
    });

    return (
      <Dropdown
        style={filterStyle}
        filterkey={filterKey}
        onChange={onValueChanged}
        options={options}
        placeholder={getPlaceHolder(filterField, t)}
        search
        selection
        value={filterValue}
      />
    );
  };

  function renderDateRangeInput () {
    const locale = i18n.language.toLowerCase();
    const dateFrom = get(filterValue, 'from', null);
    const dateTo = get(filterValue, 'to', null);

    return (
      <DateTimeRangePicker
        locale={locale}
        onChange={(data) => {
          onDateRangeChanged(filterKey, data);
        }}
        filterkey={filterKey}
        value={[dateFrom, dateTo]}
        maxDate={new Date()}
      />


    );
  };

  function renderEmpty () {
    return null;
  };
};

function getType (filterField) {
  if (filterField.enum) return 'Enum';
  return filterField.type || 'String';
};

function translateRequired (filterField) {
  return filterField.translate ? true : false;
};

function getPlaceHolder (filterField, t) {
  const localeKey = get(filterField, 'localeKey', filterField.name);
  const name = t(`table:field.${localeKey}`);
  return filterField.required ? `${name}*` : name;
};

FilterDropdown.propTypes = {
  filterField: PropTypes.object.isRequired,
  modelName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps;
  const { filterKey, ref } = ownProps.filterField;
  const refModelName = `${ref}Ref`;
  return {
    filterValue: getFilterValue(state, modelName, filterKey),
    refData: ref === undefined ? [] : getPersistentRefData(state, refModelName)
  };
};

export default connect(mapStateToProps)(FilterDropdown);