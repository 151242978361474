import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

class RegionsScreen extends Component {

  componentDidMount() {

  };

  componentWillUnmount() {

  };

  render() {
    const schema = {
      modelName: 'regions',
      serviceName: 'regions',
      fields: [
        {
          name: '_id',
          type: 'String',
          required: true
        },
        {
          name: 'name',
          type: 'String',
          required: true
        },
        {
          name: 'currency',
          type: 'ObjectId',
          ref: 'currencies',
          value: '_id',
          text: 'name',
          required: true
        },
        {
          name: 'code',
          type: 'String',
          required: true
        },
        {
          name: 'countryCode',
          type: 'String',
          required: true
        },
        {
          name: 'createdAt',
          type: 'Date',
          sortable: true
        },
        {
          name: 'updatedAt',
          type: 'Date',
          sortable: true
        }
      ]
    };

    return (
      <div>
        <Segment style={TableSegment} vertical>
          <TableView schema={schema} refFields={extractRefFields(schema)} />
        </Segment>
      </div>
    );
  };
};

RegionsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(RegionsScreen);