import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Form,
  Modal,
} from 'semantic-ui-react';
import { get, map } from 'lodash';
import ModalInput from './ModalInput';

const ModalArrayEdit = (props) => {
  const [ open, setOpen ] = useState(false);
  const { field, objectid, onArrayDeletion, onArrayInsertion, value } = props;
  const { t } = useTranslation();
  return (
    <Modal open={open} trigger={<Button size='small' onClick={ () => { setOpen(true); } }>{`${t(`table:button.edit`)} [${value.length}]`}</Button>} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`table:field.${field.name}`)}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Form.Button objectid={objectid} arrname={field.name} onClick={onArrayInsertion} positive content={t('table:button.insert')} labelPosition='right' icon='plus' />
              {
                map(value, (included, index) => {
                  const arrayFields = get(field, 'arraySchema.fields', []);
                  return (
                    <div key={`${field.name}${index}`}>
                      <Form.Group widths='equal'>
                        {
                          map(arrayFields, arrayField => {
                            let customProps = { ...props };
                            customProps.arrname = field.name;
                            customProps.arrindex = index;
                            customProps.parentPath = `${field.name}[${index}]`
                            customProps.field = arrayField;
                            return (
                              <Form.Field key={`${field.name}${index}${arrayField.name}`}>
                                <ModalInput { ...customProps }/>
                              </Form.Field>
                            )
                          })
                        }
                        <Form.Button objectid={objectid} arrname={field.name} arrindex={index} onClick={onArrayDeletion} negative content={t('table:button.delete')} labelPosition='right' icon='trash' />
                      </Form.Group>
                      <Divider />
                    </div>
                  )
                })
              }
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ () => { setOpen(false); } } content={t('table:button.close')} labelPosition='right' icon='close' />
        </Modal.Actions>
    </Modal>
  );
};

ModalArrayEdit.propTypes = {
  field: PropTypes.object.isRequired,
  objectid: PropTypes.string.isRequired,
  onArrayDeletion: PropTypes.func.isRequired,
  onArrayInsertion: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

export default ModalArrayEdit;