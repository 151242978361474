import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFeathersStatus } from 'services/feathers';
import {
  Grid,
  Table
} from 'semantic-ui-react';
import TableData from './TableData';
import TableSearch from './TableSearch';
import TableColumns from './TableColumns';
import TablePagination from './TablePagination';
import TableRowDelete from './TableRowDelete';
import TableError from './TableError';
import TableLoaderService from './TableLoaderService';
import TableLoaderRef from './TableLoaderRef';
import TableNewRow from './TableNewRow';
import { filter, get, includes } from 'lodash';
import useFeathersService from 'hooks/useFeathersService';
import useFeathersRefService from 'hooks/useFeathersRefService';
import { getRefsLoadingStatus } from 'store/feathers/selectors';
import { getUserRegionName } from 'store/auth/reducer';
import { getFilterIsStart, getQueryString } from 'store/table/reducer';
import { updateDefaultSorting } from 'store/table/actions';

const TableView = (props) => {
  const {
    dispatch,
    filterStatus,
    isRefsLoading,
    query,
    refFields,
    regionName,
    schema,
    serviceStatus
  } = props;
  const { modelName, serviceName } = props.schema;
  const readOnly = props.readOnly || false;

  const service = useFeathersService(dispatch, modelName, serviceName);
  useFeathersRefService(dispatch, refFields);

  if (regionName !== 'Open') {
    schema.fields = filter(schema.fields, f => {
      return f.name !== 'region';
    });
  }

  useEffect (() => {
    if (schema.sorting === undefined) return;
    dispatch(updateDefaultSorting(modelName, schema.sorting));
  }, [dispatch, modelName, schema]);

  useEffect (() => {
    const schemaQuery = get(schema, 'query', {});
    const parsedQuery = JSON.parse(query);
    const mergedQuery = { ...schemaQuery, ...parsedQuery };
    dispatch(service.find({ query: mergedQuery })).catch(err => console.error(err));
  }, [dispatch, filterStatus, query, schema, service]);

  // Ref loader
  if (isRefsLoading) {
    return <TableLoaderRef />;
  }

  // Service loader
  if (includes(['isLoading', 'isSaving'], serviceStatus.className)) {
    return <TableLoaderService serviceStatus={serviceStatus}/>;
  }

  return (
    <div>
      <Grid stackable verticalAlign='middle' style={{ overflowX: 'auto', margin: 'auto' }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <TableError serviceStatus={serviceStatus} />
          </Grid.Column>
          <Grid.Column width={16}>
            <Table compact celled attached sortable>
              <Table.Header>
                <TableSearch schema={schema} />
                <TableColumns readOnly={readOnly} schema={schema} />
              </Table.Header>
              <TableData
                readOnly={readOnly}
                schema={schema}
                refFields={refFields}
              />
              { !readOnly && <TableNewRow schema={schema} refFields={refFields} /> }
              <Table.Footer fullWidth>
                <TablePagination schema={schema} />
              </Table.Footer>
            </Table>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          { !readOnly && <TableRowDelete schema={schema} /> }
        </Grid.Row>
      </Grid>
    </div>
  );
};

TableView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filterStatus: PropTypes.bool.isRequired,
  isRefsLoading: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  refFields: PropTypes.array.isRequired,
  regionName: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  serviceStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { refFields } = ownProps;
  const { modelName } = ownProps.schema;

  return {
    filterStatus: getFilterIsStart(state, modelName),
    isRefsLoading: getRefsLoadingStatus(state, refFields),
    query: getQueryString(state, modelName),
    regionName: getUserRegionName(state),
    serviceStatus: getFeathersStatus(state, modelName),
  }
};

export default connect(mapStateToProps)(TableView);
