import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Pagination,
  Table
} from 'semantic-ui-react';
import { ceil, floor } from 'lodash';
import { getPagination } from 'store/feathers/selectors';
import { isEditObjectNull, getLimit } from 'store/table/reducer';
import { updatePaginationSkip, updateLimit } from 'store/table/actions';
import { useTranslation } from 'react-i18next';

const TablePagination = (props) => {
  const { t } = useTranslation();
  const { fields } = props.schema;
  const { total, limit, skip } = props.pagination;
  const { isEditObjectNull, rowLimit, updateLimit, updatePaginationSkip } = props;
  if (!total || !isEditObjectNull) return null;

  const totalPages = ceil(total / limit);
  const activePage = floor(totalPages - ((total - skip) / limit)) + 1;

  return (
    <Table.Row>
      <Table.HeaderCell colSpan={fields.length + 1}>
        <Pagination
          activePage={activePage}
          onPageChange={ (e, data) => {
            const newSkip = limit * (data.activePage - 1);
            updatePaginationSkip(newSkip);
          }}
          totalPages={totalPages}
        />
        <Dropdown
          style={{ margin: 10 }}
          text={t(`table:button.rowLimit`) + ` (${rowLimit})`}
          labeled
          button
          className='icon'
          onChange={ (e, data) => {
            updateLimit(data.value);
          }}
          value={rowLimit}
          options={
            [
              { value: 10, text: '10' },
              { value: 20, text: '20' },
              { value: 30, text: '30' },
              { value: 40, text: '40' },
              { value: 50, text: '50' },
              { value: 100, text: '100' },
              { value: 500, text: '500' },
              { value: 1000, text: '1000' },
            ]
          }

        />
      </Table.HeaderCell>
    </Table.Row>
  );
};

TablePagination.propTypes = {
  isEditObjectNull: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  rowLimit: PropTypes.number.isRequired,
  updatePaginationSkip: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    updateLimit: (limit) => { dispatch(updateLimit(modelName, limit)); },
    updatePaginationSkip: (skip) => { dispatch(updatePaginationSkip(modelName, skip)); },
  };
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    isEditObjectNull :isEditObjectNull(state, modelName),
    pagination: getPagination(state, modelName),
    rowLimit: getLimit(state, modelName),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
