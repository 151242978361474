import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import {
  Button,
  Table
} from 'semantic-ui-react';
import TableCellView from './TableCellView';
import TableCellInput from './TableCellInput';
import { useTranslation } from 'react-i18next';
import { AbilityContext } from 'casl/ability-context';
import { getQueryResult, getRefsResult } from 'store/feathers/selectors';
import * as tableActions from 'store/table/actions';
import { getObjectValue, isEditObjectNull } from 'store/table/reducer';
import {  spreadPropsRefData, spreadPropsMimeType, spreadPropsCallback } from 'utils/props';
const TYPE_KEY = Symbol.for('type');

const TableData = (props) => {
  const ability = useContext(AbilityContext);
  const { t } = useTranslation();

  const {
    editObjectId,
    isEditObjectNull,
    onEditObjectArrayDeletion,
    onEditObjectArrayInsertion,
    onEditObjectCancelClicked,
    onEditObjectChanged,
    onEditObjectSaveClicked,
    queryResult,
    readOnly,
    refs,
    schema,
  } = props;

  const { data } = queryResult;

  const renderRowData = () => {
    if (!isEditObjectNull || !data) return null;
    const { fields, modelName } = schema;
    return (
      map(data, (object) => {
        object[TYPE_KEY] = modelName;
        return (
          <Table.Row key={object._id}>
            {
              readOnly ?
              null :
              <Table.Cell singleLine>
                { renderActionButton(object) }
              </Table.Cell>
            }
            {
              map(fields, field => {
                let value;
                if (field.refField) {
                  value = get(object, field.refField, '');
                } else {
                  value = get(object, field.name, '');
                }
                const canEdit = ability.can('update', object);
                return (
                  <TableCellView
                    modelName={modelName}
                    canEdit={canEdit}
                    value={value}
                    field={field}
                    key={object._id + field.name}
                    refs={refs}
                    { ...spreadPropsMimeType(field, object) }
                    { ...spreadPropsRefData(refs, field, object) }
                    { ...spreadPropsCallback(field, object) }
                  />
                );
              })
            }
          </Table.Row>
        )
      })
    );
  };

  const renderRowDataEdit = () => {
    if (isEditObjectNull) return null;
    const { fields, modelName } = schema;
    const caslTestData = get(schema, 'caslTestData', []);
    return (
      <Table.Row>
        <Table.Cell singleLine>
          <Button.Group>
            <Button onClick={ () => { onEditObjectCancelClicked(); }}>{t('table:button:cancel')}</Button>
            <Button.Or text={t('table:button:or')} />
            <Button positive onClick={ () => { onEditObjectSaveClicked(); }}>{t('table:button:save')}</Button>
          </Button.Group>
        </Table.Cell>
        {
          map(fields, field => {
            const key = `${editObjectId}${field.name}`;
            return (
              <TableCellInput
                action='update'
                caslTestData={caslTestData}
                field={field}
                key={key}
                modelName={modelName}
                objectid={editObjectId}
                objectName='editObject'
                onArrayDeletion={onEditObjectArrayDeletion}
                onArrayInsertion={onEditObjectArrayInsertion}
                onValueChanged={onEditObjectChanged}
              />
            );
          })
        }
      </Table.Row>
    );
  };

  const renderActionButton = (object) => {
    const { onDeleteObjectClicked, onEditObjectClicked } = props;

    const cannotDelete = ability.cannot('delete', object);
    const cannotEdit = ability.cannot('update', object);

    return (
      <Button.Group>
        <Button disabled={cannotEdit} onClick={ () => { onEditObjectClicked(object); }}>{t('table:button:edit')}</Button>
        <Button.Or text={t('table:button:or')} />
        <Button disabled={cannotDelete} negative onClick={ () => { onDeleteObjectClicked(object); }}>{t('table:button:delete')}</Button>
      </Button.Group>
    )
  };

  return (
    <Table.Body>
    { renderRowDataEdit() }
    { renderRowData() }
    </Table.Body>
  );
};

TableData.propTypes = {
  editObjectId: PropTypes.string.isRequired,
  isEditObjectNull: PropTypes.bool.isRequired,
  onEditObjectArrayDeletion: PropTypes.func.isRequired,
  onEditObjectArrayInsertion: PropTypes.func.isRequired,
  onEditObjectCancelClicked: PropTypes.func.isRequired,
  onEditObjectChanged: PropTypes.func.isRequired,
  onEditObjectSaveClicked: PropTypes.func.isRequired,
  queryResult: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  refs: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps.schema;
  const { refFields } = ownProps;
  return {
    queryResult: getQueryResult(state, modelName),
    editObjectId: getObjectValue(state, modelName, 'editObject', '_id'),
    isEditObjectNull :isEditObjectNull(state, modelName),
    refs: getRefsResult(state, refFields)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    onEditObjectClicked: (editObject) => {
      dispatch(tableActions.initEditObject(modelName, editObject))
    },
    onEditObjectCancelClicked: () => {
      dispatch(tableActions.resetEditObject(modelName))
    },
    onEditObjectChanged: (e, data) => {
      const { objectid, objectkey, value } = data;
      dispatch(tableActions.updateEditObject(modelName, objectid, objectkey, value))
    },
    onEditObjectArrayDeletion: (e, data) => {
      const { objectid, arrname, arrindex } = data;
      dispatch(tableActions.updateObjectArrayDeletion(modelName, 'editObject', objectid, arrname, arrindex));
    },
    onEditObjectArrayInsertion: (e, data) => {
      const { objectid, arrname } = data;
      dispatch(tableActions.updateObjectArrayInsertion(modelName, 'editObject', objectid, arrname));
    },
    onEditObjectSaveClicked: () => {
      dispatch(tableActions.saveEditObject(modelName));
    },
    onDeleteObjectClicked: (deleteObject) => {
      dispatch(tableActions.updateDeleteObject(modelName, deleteObject));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TableData);
