import PropTypes from 'prop-types'
import React from 'react';
import {
  Button,
  Container
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'components/Dropzone';

const baseStyle = {
  //marginTop: 10,
  //flex: 1,
  //display: 'flex'
  /*flex: 3,
  display: 'flex',
  flexDirection: 'row'*/
};

const buttonGroupStyle = {
  width: '100%'
}

const PotWd = (props) => {
  const { t } = useTranslation(['transaction']);
  const {
    onPasteCb,
    onPotDeleteCb,
    pastedImage,
    proofOfTransfer,
    setImageModalSourceCb,
    transactionId,
    uploadService
  } = props;

  return (
    <Container style={baseStyle}>
    {
      renderer()
    }
    </Container>
  );

  function renderer () {
    const potExist = proofOfTransfer !== null;
    if (!potExist) {
      return <Dropzone onPasteCb={onPasteCb} pastedImage={pastedImage} service={uploadService} additionalUploadData={{ uploadRef: transactionId }} multiple={false} />;
    } else {
      return (
        <Button.Group style={buttonGroupStyle}>
            <Button size='small' onClick={() => { setImageModalSourceCb(proofOfTransfer) }}>
              {t(`button.view`)}
            </Button>
            <Button color='red' size='small' onClick={() => {
              onPotDeleteCb(proofOfTransfer);
            }}>
              {t(`button.delete`)}
            </Button>
        </Button.Group>
      );
    }
  };
};

PotWd.propTypes = {
  onPasteCb: PropTypes.func.isRequired,
  onPotDeleteCb: PropTypes.func.isRequired,
  pastedImage: PropTypes.any,
  proofOfTransfer: PropTypes.string,
  setImageModalSourceCb: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
  uploadService: PropTypes.string.isRequired,
};

export default PotWd;