import PropTypes from 'prop-types'
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import Dropzone from 'components/Dropzone';
import { AbilityContext } from 'casl/ability-context';
import { TableSegment } from 'styles/common';

const FilesScreen = (props) => {
  const ability = useContext(AbilityContext);
  const schema = {
    modelName: 'files',
    serviceName: 'files',
    fields: [
      {
        name: 'name',
        type: 'String',
        required: true
      },
      {
        name: 'mimeType',
        type: 'String'
      },
      {
        name: 'isPrivate',
        type: 'Boolean',
        translate: true
      },
      {
        name: 'thumbnail',
        type: 'Buffer'
      },
      {
        name: 'size',
        type: 'Number',
        format: 'file'
      },
      {
        name: 'uploader.username',
        type: 'String'
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      {
        ability.can('create', 'uploads') ?
          <Dropzone service='uploads' /> :
          null
      }
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} />
      </Segment>
    </div>
  );
};

FilesScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(FilesScreen);