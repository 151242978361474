import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  Input,
  Popup,
  Table
} from 'semantic-ui-react';
import * as tableActions from 'store/table/actions';
import { isEditObjectNull, getFilterIsStart, getLastSearch, getSearchString } from 'store/table/reducer';
import { map, trim } from 'lodash';
import FilterDropdown from 'components/Renderer/FilterDropdown';

const TableSearch = (props) => {
  const { t } = useTranslation(['table']);
  const { fields, filterFields, modelName } = props.schema;
  const { isEditObjectNull, lastSearch, searchString } = props;
  const {
    filterStatus,
    resetLastSearch,
    resetSearchString,
    startFilter,
    stopFilter,
    updateLastSearch,
    updateSearchString,
  } = props;

  if (!isEditObjectNull) return null;

  const searchColSpanCount = filterFields === undefined ? fields.length + 1 : 1;

  return (
    <Table.Row>
      <Table.HeaderCell colSpan={searchColSpanCount} textAlign='left'>
        <Popup
          trigger={
            <Input
              icon={{
                name: 'cancel',
                link: true,
                onClick: () => {
                  resetSearchString();
                  if (lastSearch === '') return;
                  resetLastSearch();
                }
              }}
              onChange={ (e, data) => {
                updateSearchString(data.value);
              }}
              onKeyPress={ (e) => {
                const trimmedSearchString = trim(searchString);
                if (!trimmedSearchString || lastSearch === trimmedSearchString) return;
                if (e.key !==  'Enter') return;
                updateLastSearch(trimmedSearchString);
              }}
              value={searchString}
              placeholder={t(`table:popup.search.placeholder`)}
            />
          }
          header={t(`table:popup.search.${modelName}.header`)}
          content={t(`table:popup.search.${modelName}.content`)}
          on='hover'
        />
      </Table.HeaderCell>
      {
        filterFields &&
        <Table.HeaderCell colSpan={fields.length - 1}>
          {
            map(filterFields, (f, i) => {
              let filterStyle;
              if (i === filterFields.length - 1) filterStyle = { margin: 10 }
              else filterStyle = { marginLeft: 10 }

              return (
                <FilterDropdown
                  filterStyle={filterStyle}
                  key={f.name}
                  filterField={f}
                  modelName={modelName}
                />
              )
            })
          }
          <Button.Group>
            <Button disabled={filterStatus} color='blue' icon labelPosition='left' onClick={ () => {
              startFilter(modelName);
            }}>
              <Icon name='filter' />
              {t(`table:button.filter`)}
            </Button>
            <Button disabled={!filterStatus} color='red' icon labelPosition='right' onClick={ () => {
              stopFilter(modelName);
            }}>
              <Icon name='cancel' />
              {t(`table:button.reset`)}
            </Button>
          </Button.Group>
        </Table.HeaderCell>
      }
    </Table.Row>
  );
};

TableSearch.propTypes = {
  isEditObjectNull: PropTypes.bool.isRequired,
  filterStatus: PropTypes.bool.isRequired,
  lastSearch: PropTypes.string.isRequired,
  resetLastSearch: PropTypes.func.isRequired,
  resetSearchString: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  searchString: PropTypes.string.isRequired,
  updateLastSearch: PropTypes.func.isRequired,
  updateSearchString: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    updateLastSearch: (lastSearch) => {
      dispatch(tableActions.updateLastSearch(modelName, lastSearch));
    },
    updateSearchString: (searchString) => {
      dispatch(tableActions.updateSearchString(modelName, searchString));
    },
    resetLastSearch: () => {
      dispatch(tableActions.resetLastSearch(modelName));
    },
    resetSearchString: () => {
      dispatch(tableActions.resetSearchString(modelName));
    },
    startFilter: () => {
      dispatch(tableActions.startFilter(modelName));
    },
    stopFilter: () => {
      dispatch(tableActions.stopFilter(modelName));
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps.schema;
  return {
    isEditObjectNull :isEditObjectNull(state, modelName),
    filterStatus: getFilterIsStart(state, modelName),
    lastSearch: getLastSearch(state, modelName),
    searchString: getSearchString(state, modelName),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableSearch);
