import PropTypes from 'prop-types'
import React  from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const ExtUsersKing855Screen = (props) => {
  const schema = {
    modelName: 'extUsersKing855',
    serviceName: 'ext-users-king-855',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'user.username',
        type: 'String',
        localeKey: 'username'
      },
      {
        name: 'username',
        type: 'String',
        localeKey: 'extUsername',
      },
      {
        name: 'password',
        type: 'String'
      },
      {
        name: 'isEnabled',
        type: 'Boolean',
        default: true,
        required: true,
        translate: true
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
      {
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={false}/>
      }
      </Segment>
    </div>
  );
};

ExtUsersKing855Screen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(ExtUsersKing855Screen);