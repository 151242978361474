import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Table
} from 'semantic-ui-react';
import { map } from 'lodash';
import { getObjectValue, isEditObjectNull } from 'store/table/reducer';
import {
  resetNewObject,
  saveNewObject,
  updateNewObject,
  updateObjectArrayDeletion,
  updateObjectArrayInsertion
} from 'store/table/actions';
import { useTranslation } from 'react-i18next';
import { AbilityContext } from 'casl/ability-context';
import TableCellInput from './TableCellInput';

const TableNewRow = (props) => {
  const ability = useContext(AbilityContext);
  const { t } = useTranslation();
  const {
    isEditObjectNull,
    newObjectId,
    onNewObjectArrayDeletion,
    onNewObjectArrayInsertion,
    resetNewObject,
    saveNewObject,
    schema,
    updateNewObject
  } = props;
  const { fields, modelName } = schema;

  const onValueChangedCb = useCallback(
    (e, data) => {
      const { objectid, objectkey, value } = data;
      updateNewObject(objectid, objectkey, value);
    }, [updateNewObject]
  );

  const canCreate = ability.can('create', modelName);
  if (!canCreate || !isEditObjectNull) return null;

  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell singleLine>
          <Button.Group>
            <Button onClick={ () => { resetNewObject(); } }>{t('table:button:reset')}</Button>
            <Button.Or text={t('table:button:or')} />
            <Button positive onClick={ () => { saveNewObject(); } }>{t('table:button:save')}</Button>
          </Button.Group>
        </Table.Cell>
        {
          map(fields, field => {
            const key = `${newObjectId}${field.name}`;
            return (
              <TableCellInput
                modelName={modelName}
                objectName='newObject'
                action='create'
                field={field}
                objectid={newObjectId}
                key={key}
                onValueChanged={onValueChangedCb}
                onArrayDeletion={onNewObjectArrayDeletion}
                onArrayInsertion={onNewObjectArrayInsertion}
              />
            );
          })
        }
      </Table.Row>
    </Table.Body>
  );
};

TableNewRow.propTypes = {
  isEditObjectNull: PropTypes.bool.isRequired,
  newObjectId: PropTypes.string.isRequired,
  onNewObjectArrayDeletion: PropTypes.func.isRequired,
  onNewObjectArrayInsertion: PropTypes.func.isRequired,
  resetNewObject: PropTypes.func.isRequired,
  saveNewObject: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  updateNewObject: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { modelName } = ownProps.schema;

  return {
    resetNewObject: () => {
      dispatch(resetNewObject(modelName));
    },
    updateNewObject: (id, key, value) => {
      dispatch(updateNewObject(modelName, id, key, value));
    },
    saveNewObject: () => {
      dispatch(saveNewObject(modelName));
    },
    onNewObjectArrayDeletion: (e, data) => {
      const { objectid, arrname, arrindex } = data;
      dispatch(updateObjectArrayDeletion(modelName, 'newObject', objectid, arrname, arrindex));
    },
    onNewObjectArrayInsertion: (e, data) => {
      const { objectid, arrname } = data;
      dispatch(updateObjectArrayInsertion(modelName, 'newObject', objectid, arrname));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const { modelName } = ownProps.schema;

  return {
    newObjectId: getObjectValue(state, modelName, 'newObject', '_id'),
    isEditObjectNull: isEditObjectNull(state, modelName)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableNewRow);
