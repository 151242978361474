import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const ExtBetLogsKing855Screen = (props) => {
  const schema = {
    modelName: 'extBetLogsKing855',
    serviceName: 'ext-bet-logs-king-855',
    fields: [
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'agentName',
        type: 'String'
      },
      {
        name: 'extUsername',
        type: 'String'
      },
      {
        name: 'ticketId',
        type: 'String',
        sortable: true
      },
      {
        name: 'serial',
        type: 'String',
        sortable: true
      },
      {
        name: 'amount.$numberDecimal',
        localeKey: 'amountMonetary',
        type: 'Number'
      },
      {
        name: 'balance.$numberDecimal',
        localeKey: 'balanceBefore',
        type: 'Number'
      },
      {
        name: 'isReversed',
        type: 'Boolean',
        translate: true
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
      </Segment>
    </div>
  );
};

ExtBetLogsKing855Screen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(ExtBetLogsKing855Screen);