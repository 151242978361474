import React from 'react';
import PropTypes from 'prop-types';
import InputRenderer from 'components/Renderer/Input';

const ModalInput = (props) => {
  const { field } = props;

  if (!field) return null;
  return <InputRenderer { ...props } />
};

ModalInput.propTypes = {
  field: PropTypes.object.isRequired
};

export default ModalInput;