import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Form,
  Message,
  Modal
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';

const ModalBalanceAdjView = (props) => {
  const {
    balAdjObject,
    localeKey,
    onClose,
    refField,
    service,
    bonusMode = false
  } = props;
  const { t } = useTranslation(['table', 'translation']);
  const [amount, setAmount] = useState(1);
  const [action, setAction] = useState('');
  const [remark, setRemark] = useState('');
  const [status, setStatus] = useState('idle');
  const [isBonus, setIsBonus] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async () => {
    const data = {
      region: balAdjObject.region,
      [refField]: balAdjObject._id,
      amount: amount,
      action: action,
      remark: remark,
      isBonus: isBonus
    }

    try {
      setError(null);
      setStatus('saving');
      await feathers.service(service).create(data);
      setStatus('saved');
    } catch (err) {
      setError(err.message);
      setStatus('idle');
    }
  };

  useEffect(() => {
    if (status === 'saved') {
      setStatus('idle');
      onClose();
    }
  }, [onClose, status]);

  return (
    <Modal
      open={balAdjObject !== null}
      onClose={onClose}
      size='tiny'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
    >
      <Modal.Header>{t(`translation:modalTitle.${localeKey}`)}</Modal.Header>
      <Modal.Content scrolling>
        <Form loading={status !== 'idle'}>
          <Form.Group>
            <Form.Field
              type='number'
              min="1"
              label={t(`translation:form.label.amount`)}
              control='input'
              value={amount}
              onChange={ (e) => {
                setAmount(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group grouped>
            <label>{t(`translation:form.label.action`)}</label>
            <Form.Field
              label={t(`translation:form.radio.add`)}
              control='input'
              type='radio'
              value='add'
              checked={action === 'add'}
              onChange={ (e) => {
                setAction(e.target.value);
              }}
            />
            <Form.Field
              label={t(`translation:form.radio.deduct`)}
              control='input'
              type='radio'
              value='deduct'
              checked={action === 'deduct'}
              onChange={ (e) => {
                setAction(e.target.value);
              }}
            />
          </Form.Group>
          {
            bonusMode && <Form.Field
              label={t(`translation:form.label.isBonus`)}
              control={Checkbox}
              onChange={ (e, data) => {
                setIsBonus(data.checked);
              }}
            />
          }
          <Form.Field
            label={t(`translation:form.label.remark`)}
            control='textarea'
            rows='3'
            value={remark}
            onChange={ (e) => {
              setRemark(e.target.value);
            }}
          />
        </Form>
        {
          error &&
          <Message negative>
            <Message.Header>{t(`translation:modalTitle.error`)}</Message.Header>
            <p>{error}</p>
          </Message>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button
            disabled={!amount || !action || !remark}
            positive
            onClick={onSubmit}
            content={t(`table:button.save`)}
            labelPosition='left'
            icon='save'
          />
          <Button negative onClick={onClose} content={t(`table:button.close`)} labelPosition='right' icon='close' />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  )
};

ModalBalanceAdjView.propTypes = {
  balAdjObject: PropTypes.object.isRequired,
  localeKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refField: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
};

export default ModalBalanceAdjView