import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Modal,
} from 'semantic-ui-react';
import { get, map } from 'lodash';
import InputRenderer from 'components/Renderer/Input';

const ModalObjectEdit = (props) => {
  const [ open, setOpen ] = useState(false);
  const { field } = props;
  const { t } = useTranslation();
  return (
    <Modal open={open} trigger={<Button size='small' onClick={ () => { setOpen(true); } }>{t(`table:button.edit`)}</Button>} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`table:field.${field.name}`)}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              {
                map(get(field, 'objectSchema.fields', []), objectField => {
                  let customProps = { ...props };
                  customProps.parentPath = `${field.name}`
                  customProps.field = objectField;
                  return (
                    <Form.Field key={`${field.name}${objectField.name}`} inline>
                      <label>{t(`table:field.${objectField.name}`)}</label>
                      <InputRenderer { ...customProps }/>
                    </Form.Field>
                  )
                })
              }
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ () => { setOpen(false); } } content={t('table:button.close')} labelPosition='right' icon='close' />
        </Modal.Actions>
    </Modal>
  );
};

ModalObjectEdit.propTypes = {
  field: PropTypes.object.isRequired,
  objectid: PropTypes.string.isRequired,
  onArrayDeletion: PropTypes.func.isRequired,
  onArrayInsertion: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

export default ModalObjectEdit;