import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import ModalBalanceAdjView from 'components/Modal/ModalBalanceAdjView';
import { TableSegment } from 'styles/common';

const WalletsScreen = (props) => {
  const [ balAdjObject, setBalAdjObject ] = useState(null);

  const onBalanceAdjCallback = useCallback(
    (object) => {
      setBalAdjObject(object);
    },
    []
  );

  const onBalanceAdjModalCloseCallback = useCallback(
    () => {
      setBalAdjObject(null);
    },
    []
  );

  const schema = {
    modelName: 'wallets',
    serviceName: 'wallets',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'balance.$numberDecimal',
        type: 'Number',
        localeKey: 'amountMonetary',
        callback: {
          buttonIcon: 'edit',
          pickFields: ['_id', 'region'],
          onClick: onBalanceAdjCallback
        }
      },
      {
        name: 'owner.username',
        type: 'String',
        localeKey: 'username'
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
      {
        balAdjObject !== null ?
        <ModalBalanceAdjView
          balAdjObject={balAdjObject}
          onClose={onBalanceAdjModalCloseCallback}
          refField='wallet'
          service='wallet-balance-adj-logs'
          localeKey='walletBalAdj'
          bonusMode={true}
        /> :
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
      }
      </Segment>
    </div>
  );
};

WalletsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(WalletsScreen);