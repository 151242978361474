import PropTypes from 'prop-types'
import React, { useCallback, useContext, useState }  from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import ModalExtUserStatusToggleView from 'components/Modal/ModalExtUserStatusToggleView';
import { TableSegment } from 'styles/common';
import { AbilityContext } from 'casl/ability-context';
const TYPE_KEY = Symbol.for('type');

const ExtUsersKiss918Screen = (props) => {
  const ability = useContext(AbilityContext);
  const [ extUserObject, setExtUserObject ] = useState(null);

  const onExtUserStatusToggleCallback = useCallback(
    (object) => {
      setExtUserObject(object);
    },
    []
  );

  const onExtUserStatusToggleModalClose = useCallback(
    () => {
      setExtUserObject(null);
    },
    []
  );

  const canToggle = ability.can('get',
  {
    method: 'toggleUserStatus',
    [TYPE_KEY]: 'br-api-kiss-918'
  });

  const toggleCb = canToggle ? {
    buttonIcon: 'edit',
    pickFields: ['_id', 'username'],
    onClick: onExtUserStatusToggleCallback
  } : null;

  const schema = {
    modelName: 'extUsersKiss918',
    serviceName: 'ext-users-kiss-918',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: 'user.username',
        type: 'String',
        localeKey: 'username'
      },
      {
        name: 'username',
        type: 'String',
        localeKey: 'extUsername',
        callback: toggleCb
      },
      {
        name: 'password',
        type: 'String'
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
      {
        extUserObject !== null ?
        <ModalExtUserStatusToggleView
          extUserObject={extUserObject}
          localeKey='extUserStatusToggle'
          onClose={onExtUserStatusToggleModalClose}
          service='br-api-kiss-918'
        /> :
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={false}/>
      }
      </Segment>
    </div>
  );
};

ExtUsersKiss918Screen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(ExtUsersKiss918Screen);