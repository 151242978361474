import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Label,
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';
import ImageModal from 'components/ImageModal';
import {
  getPageAmountSum
} from 'store/feathers/selectors';
import { useTranslation } from 'react-i18next';

const WithdrawalsScreen = (props) => {
  const { t } = useTranslation(['translation']);
  const { pageAmountSum } = props;
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [imageModalSource, setImageModalSource] = useState('');

  const setImageModalIsOpenCb= useCallback(
    (isOpen) => {
      setImageModalIsOpen(isOpen);
    },
    []
  );

  const setImageModalSourceCb = useCallback(
    (object) => {
      setImageModalSource(object.proofOfTransfer);
      setImageModalIsOpen(true);
    },
    []
  );

  const schema = {
    modelName: 'withdrawalLogs',
    serviceName: 'withdrawal-logs',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code'
      },
      {
        name: 'user.username',
        type: 'String',
        localeKey: 'username'
      },
      {
        name: 'amount.$numberDecimal',
        type: 'Number',
        localeKey: 'amountMonetary'
      },
      {
        name: 'forfeitedAmount.$numberDecimal',
        type: 'Number',
        localeKey: 'forfeitedAmount'
      },
            {
        name: 'finalAmount',
        type: 'Number',
        localeKey: 'finalAmount'
      },
      {
        name: 'toAccount.bank.name',
        type: 'String',
        localeKey: 'bankName'
      },
      {
        name: 'toAccount.accountHolder',
        type: 'String',
        localeKey: 'accountHolder'
      },
      {
        name: 'toAccount.accountNumber',
        type: 'String',
        localeKey: 'accountNumber'
      },
      {
        name: 'proofOfTransfer',
        type: 'String',
        hideValue: true,
        callback: {
          buttonIcon: 'image',
          pickFields: ['proofOfTransfer'],
          onClick: setImageModalSourceCb
        }
      },
      {
        name: 'status',
        type: 'String',
        translate: true,
        enum: ['pending', 'processing', 'approved', 'rejected'],
        sortable: true
      },
      {
        name: 'remark',
        type: 'String'
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ],
    filterFields: [
      {
        name: 'dateRange',
        filterKey: 'dateRange',
        type: 'DateRange',
        dateKey: 'updatedAt'
      },
      {
        name: 'companyBankAccounts',
        filterKey: 'fromAccount',
        type: 'ObjectId',
        ref: 'companyBankAccounts',
        value: '_id',
        text: (object) => { return `${object.aliasName} (${object.accountNumber})` }
      },
      {
        name: 'status',
        filterKey: 'status',
        type: 'String',
        translate: true,
        enum: ['pending', 'processing', 'approved', 'rejected'],
      }
    ],
    sorting: {
      'updatedAt': -1
    }
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <div style={{ marginLeft: 12, textAlign: 'left' }}>
          <Label color='red' size='large'>
            {`${t(`header.pageAmountSum`)}: ${pageAmountSum.toFixed(2)}`}
          </Label>
        </div>
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
        <ImageModal
          imageDownloaderServiceName='proof-of-transfers'
          imageModalSource={imageModalSource}
          imageModalIsOpen={imageModalIsOpen}
          setImageModalIsOpen={setImageModalIsOpenCb}
        />
      </Segment>
    </div>
  );
};

WithdrawalsScreen.propTypes = {
  mobile: PropTypes.bool,
  pageAmountSum: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    pageAmountSum: getPageAmountSum(state, 'withdrawalLogs')
  };
};

export default connect(mapStateToProps)(WithdrawalsScreen);