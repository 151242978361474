import React from 'react';
import PropTypes from 'prop-types';
import {
  Table
} from 'semantic-ui-react';
import DataRenderer from 'components/Renderer/Data';

const TableCellView = (props) => {
  const { field, value } = props;
  if (!field) return null;

  let extraProps = {};
  appendBooleanEffect();
  appendTransactionStatusEffect();
  appendActionEffect();

  return (
    <Table.Cell singleLine { ...extraProps }>
      <DataRenderer { ...props } />
    </Table.Cell>
  );

  function appendActionEffect () {
    if (field.name !== 'action') return;

    switch (value) {
      case 'add':
        extraProps.positive = true;
        break;
      case 'deduct':
        extraProps.negative = true;
        break;
      default:
    }
  };

  function appendBooleanEffect () {
    if (field.type !== 'Boolean') return;
    if (value === true) extraProps.positive = true;
    else extraProps.negative = true;
  };

  function appendTransactionStatusEffect () {
    if (field.name !== 'status' || (value !== 'approved' && value !== 'rejected')) return;
    if (value === 'approved') extraProps.positive = true;
    else extraProps.negative = true;
  };
};

TableCellView.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired
};

export default TableCellView;
