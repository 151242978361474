import * as types from './actionTypes';
import { get } from 'lodash';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isError: null,
  isLoading: false,
  isAuthenticated: false,
  accessToken: null,
  authentication: null,
  user: null,
  caslRules: [],
  twoFactorEnabled: false
});

export default function reduce (state = initialState, action = {}) {
  switch (action.type) {
    case types.AUTHENTICATE_PENDING:
      return state.merge({
        isError: null,
        isLoading: true,
        isAuthenticated: false,
        accessToken: null,
        authentication: null,
        user: null,
        caslRules: [],
        twoFactorEnabled: false
      });
    case types.AUTHENTICATE_FULFILLED:
      return state.merge({
        isError: false,
        isLoading: true,
        isAuthenticated: false,
        accessToken: action.payload.accessToken,
        authentication: action.payload.authentication,
        user: action.payload.user,
        caslRules: action.payload.rules,
        twoFactorEnabled: false
      });
    case types.AUTHENTICATE_REJECTED:
      return state.merge({
        isError: action.payload,
        isLoading: false,
        isAuthenticated: false,
        accessToken: null,
        authentication: null,
        user: null,
        caslRules: [],
        twoFactorEnabled: false
      });
    case types.PERSONALIZATION_COMPLETED:
      return state.merge({
        isLoading: false,
        isAuthenticated: true
      });
    case types.LOGOUT:
      return state.merge({
        isError: null,
        isLoading: false,
        isAuthenticated: false,
        accessToken: null,
        authentication: null,
        user: null,
        caslRules: [],
        twoFactorEnabled: false
      });
    case types.TWOFACTOR_ENABLED:
      return state.merge({
        isError: null,
        isLoading: false,
        isAuthenticated: false,
        accessToken: null,
        decodedAccessToken: null,
        caslRules: [],
        twoFactorEnabled: true
      });
    case types.USER_UPDATE:
      return state.merge({
        user: action.payload.user
      });
    default:
      return state;
  }
};

export function getIsAuthenticated (state) {
  return state.auth.isAuthenticated;
};

export function getIsError (state) {
  return state.auth.isError;
};

export function getIsLoading (state) {
  return state.auth.isLoading;
};

export function getUserDetails (state) {
  return state.auth.user;
};

export function getUserRole (state) {
  if (!state.auth.isAuthenticated) return 'anonymous';
  return state.auth.user.role;
};

export function getUserId (state) {
  return get(state, 'auth.user._id', '');
};

export function getTwoFactorEnabled (state) {
  return state.auth.twoFactorEnabled;
};

export function getTwoFactorToken (state) {
  return state.auth.twoFactorToken;
};

export function getTwoFactorTokenCounter (state) {
  return state.auth.twoFactorTokenCounter;
};

export function getUserRegionName (state) {
  return get(state, 'auth.user.region.name', '');
};

export function getUserRegionId (state) {
  return get(state, 'auth.user.region._id', '');
};