export const TableSegment = {
  padding: '1em 1em',
  minHeight: '80vh'
  /*flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'ghostwhite'*/
};

export const CardTaskSegment = {
  padding: '1em 1em',
  minHeight: '80vh'
}