import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import {
  Icon,
  Message
} from 'semantic-ui-react';

const TableError = (props) => {
  const { serviceStatus } = props;
  const className = serviceStatus.className;
  if (includes(['isLoading', 'isSaving', ''], className)) return null;
  const message = serviceStatus.message;
  return (
    <Message attached='top' error>
      <Icon name='exclamation' />
      {message}
    </Message>
  );
};

TableError.propTypes = {
  serviceStatus: PropTypes.object.isRequired
};

export default TableError;