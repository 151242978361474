import { useContext, useEffect } from 'react';
import feathers, { feathersServices } from 'services/feathers';
import { AbilityContext } from 'casl/ability-context';
const TYPE_KEY = Symbol.for('type');

function useFeathersService (dispatch, modelName, serviceName) {
  const service = feathersServices[modelName];
  const nativeService = feathers.service(`/${serviceName}`);
  const ability = useContext(AbilityContext);

  useEffect (() => {
    const onCreated = (data) => {
      data[TYPE_KEY] = modelName;
      const canRead = ability.can('read', data);
      if (canRead) dispatch(service.onCreated(data));
      else dispatch(service.onRemoved(data));
    };
    const onRemoved = (data) => {
      dispatch(service.onRemoved(data));
    };
    const onUpdated = (data) => {
      data[TYPE_KEY] = modelName;
      const canRead = ability.can('read', data);
      if (canRead) dispatch(service.onUpdated(data));
      else dispatch(service.onRemoved(data));
    };

    nativeService.on('created', onCreated);
    nativeService.on('removed', onRemoved);
    nativeService.on('updated', onUpdated);

    return () => {
      nativeService.removeListener('created', onCreated);
      nativeService.removeListener('removed', onRemoved);
      nativeService.removeListener('updated', onUpdated);
    };
  }, [ability, dispatch, modelName, nativeService, service]);

  return service;
};

export default useFeathersService;