import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

class BankBalanceAdjLogsScreen extends Component {

  render() {
    const schema = {
      modelName: 'bankBalanceAdjLogs',
      serviceName: 'bank-balance-adj-logs',
      fields: [
        {
          name: '_id',
          type: 'String'
        },
        {
          name: 'region',
          type: 'ObjectId',
          ref: 'regions',
          value: '_id',
          text: 'name',
          flag: 'code'
        },
        {
          name: 'action',
          type: 'String',
          enum: ['add', 'deduct'],
          translate: true,
          localeKey: 'balAdjAction'
        },
        {
          name: 'amount.$numberDecimal',
          type: 'Number',
          localeKey: 'amountMonetary'
        },
        {
          name: 'bankAccount.bank.name',
          type: 'String',
          localeKey: 'bankName'
        },
        {
          name: 'bankAccount.accountHolder',
          type: 'String',
          localeKey: 'accountHolder'
        },
        {
          name: 'bankAccount.accountNumber',
          type: 'String',
          localeKey: 'accountNumber'
        },
        {
          name: 'remark',
          type: 'String'
        },
        {
          name: 'executor.username',
          type: 'String',
          localeKey: 'executor'
        },
        {
          name: 'createdAt',
          type: 'Date',
          sortable: true
        },
        {
          name: 'updatedAt',
          type: 'Date',
          sortable: true
        }
      ]
    };

    return (
      <div>
        <Segment style={TableSegment} vertical>
          <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
        </Segment>
      </div>
    );
  };
};

BankBalanceAdjLogsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(BankBalanceAdjLogsScreen);