import PropTypes from 'prop-types'
import React from 'react';
import {
  Confirm
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Confirmation = (props) => {
  const { t } = useTranslation();
  const { message, onConfirmationCb, showConfirm } = props;
  return (
    <Confirm
      open={showConfirm}
      content={message}
      cancelButton={t(`confirm.cancel`)}
      confirmButton={t(`confirm.ok`)}
      onCancel={ () => onConfirmationCb(false) }
      onConfirm={ () => onConfirmationCb(true) }
      size='mini'
    />
  );
};

Confirmation.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirmationCb: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired
};

export default Confirmation;






