import React, { useEffect, useState } from 'react';
import {
  Divider,
  Flag,
  Header,
  Message,
  Statistic
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { kebabCase, random, replace } from 'lodash';
import dayjs from 'dayjs';

const StatExtWalletBalance = (props) => {
  const { t } = useTranslation();
  const [ state, setState ] = useState('idle');
  const [ error, setError ] = useState(null);
  const [ products, setProducts ] = useState([]);
  const productsService = feathers.service(`/products`);

  useEffect(() => {
    async function getProducts () {
      try {
        setState('fetchingProducts');
        const findProducts = await productsService.find({
          query: {
            walletType: 'external',
            $select: ['region', 'name', 'onApiSettingModel'],
            $populate: [{ path: 'region', select: ['code'] }],
            $limit: -1
          }
        });
        if (findProducts.length) {
          const mapProducts = findProducts.map(product => {
            const { onApiSettingModel } = product;
            const apiServiceName = kebabCase(replace(onApiSettingModel, 'Settings', '')).split('-').join('-');
            product.apiService = `br-${apiServiceName}`;
            product.balance = 0;
            return product;
          });
          setProducts(mapProducts);
        }
        setState('productsFetched');
      } catch (err) {
        setError(err.message);
        setState('error');
      }
    }
    getProducts();
  }, [productsService]);

  useEffect(() => {
    async function getProductBalance (product, index) {
      setState('fectchingBalance');
      const apiService = feathers.service(`/${product.apiService}`);
      const params = {
        query: {
          method: '_operatorBalance',
          region: product.region._id
        }
      };

      try {
        const req = await apiService.get('-', params);
        products[index].balance = req.balance || 0;
        products[index].lastPoll = new Date();
        delete products[index].error;
        setState('idle');
      } catch (err) {
        products[index].balance = 0;
        products[index].error = err.message;
        setState('idle');
      };
    };

    if (products.length <= 0 || state !== 'productsFetched') return;

    products.forEach((product, index) => {
      getProductBalance(product, index);
    });

  }, [products, state]);

  useEffect(() => {
    const nextFetchDelay = random(15000, 30000);
    const timer = setInterval(() => {
      setState('productsFetched');
    }, nextFetchDelay);
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <Header size='huge'>{t(`translation:header.productBalance`)}</Header>
      {
        error &&
        <Message negative>
          <p>{error}</p>
        </Message>
      }
      {
        !error &&
        <Statistic.Group horizontal size='huge'>
          {
            products.map(product => {
              const formattedBalance = new Intl.NumberFormat({ style: 'currency' }).format(product.balance);
              const formattedDate = dayjs(product.lastPoll).format('DD/MM/YYYY HH:mm:ss');
              return (
                <Statistic key={product.name}>
                  <Statistic.Value>{formattedBalance}</Statistic.Value>
                  <Statistic.Label>
                    {`${product.name} `}
                    <Flag name={product.region.code} />
                    {
                      product.error &&
                      ` ${product.error}`
                    }
                    {` (${formattedDate})`}
                  </Statistic.Label>
                </Statistic>
              )
            })
          }
        </Statistic.Group>
      }
      <Divider />
    </div>
  );
};

export default StatExtWalletBalance
