import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dimmer,
  Flag,
  Grid,
  Loader,
  Message,
  Segment,
  Table
} from 'semantic-ui-react';
import { TableSegment } from 'styles/common';
import feathers from 'services/feathers';
import { forEach, get, groupBy, kebabCase, map, toNumber, uniq } from 'lodash';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { useTranslation } from 'react-i18next';
import { getDwlModelFromApiModel } from '../utils/product';
import dayjs from 'dayjs';

const DailyWinLossReport = (props) => {
  const { i18n, t } = useTranslation('table');
  const [ dateRange, setDateRange ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ products, setProducts ] = useState(null);
  const [ dwls, setDwls ] = useState([]);
  const [ bonuses, setBonuses ] = useState([]);
  const [ bonusAdjustments, setBonusAdjustments ] = useState([]);
  const [ status, setStatus ] = useState('idle');
  const locale = i18n.language.toLowerCase();

  useEffect(() => {
    if (status !== 'fetchProducts') return;
    async function fetchProducts () {
      try {
        const products = await feathers.service('products').find();
        map(products.data, p => {
          p.dwl = getDwlModelFromApiModel(p.onApiSettingModel);
        });
        setProducts(products);
        setStatus('fetchDwl');
      } catch (err) {
        setErrorMessage(err.message);
        setStatus('idle');
      };
    };
    fetchProducts();
  }, [status]);

  useEffect(() => {
    if (status !== 'fetchDwl') return;
    const dateFrom = dayjs(dateRange[0]).format('YYYY-MM-DD');
    const dateTo = dayjs(dateRange[1]).format('YYYY-MM-DD')

    async function fetchDwls (products) {
      let localDwls = [];
      for (let i = 0; i < products.length; i++) {
        const serviceName = kebabCase(products[i].dwl);
        const dwl = await feathers.service(serviceName).find({
          query: {
            _idPath: [
              '$match.region'
            ],
            _aggregate: [
              {
                '$match': {
                  'region': products[i].region,
                  //'eod': true,
                  'date': {
                    '$gte': dateFrom,
                    '$lte': dateTo
                    /*'$gte': '2020-01-01',
                    '$lte': '2020-05-02'*/
                  }
                }
              }, {
                '$group': {
                  '_id': '$region',
                  'winLoss': {
                    '$sum': '$win'
                  }
                }
              }, {
                '$lookup': {
                  'from': 'regions',
                  'localField': '_id',
                  'foreignField': '_id',
                  'as': 'region'
                }
              }
            ]
          }
        });

        if (!dwl.length) continue;
        const name = get(products[i], 'name', 'N/A');
        const percentage = get(products[i], 'percentage', '0');
        dwl[0].name = name;
        dwl[0].percentage = percentage;
        localDwls.push(dwl[0]);
      }
      setDwls(localDwls);
      setStatus('fetchBonus');
    };

    fetchDwls(products.data);
  }, [dateRange, status, products]);

  useEffect(() => {
    if (status !== 'fetchBonus') return;
    const regions = uniq(map(products.data, 'region'));

    async function fetchBonuses (regions) {
      let localBonuses = [];
      let localBonusAdjustments = [];
      for (let i = 0; i < regions.length; i++) {
        const bonus = await feathers.service('deposit-logs').find({
          query: {
            _datePath: [
              '$match.transactionTime.$gte',
              '$match.transactionTime.$lte',
            ],
            _idPath: [
              '$match.region'
            ],
            _aggregate: [
              {
                '$match': {
                  'region': regions[i],
                  'status': 'approved',
                  'transactionTime': {
                    '$gte': dateRange[0],
                    '$lte': dateRange[1]
                    /*'$gte': new Date('Wed, 01 Jan 2020 00:00:00 GMT'),
                    '$lte': new Date('Sat, 02 May 2020 23:59:59 GMT')*/
                  }
                }
              }, {
                '$group': {
                  '_id': '$region',
                  'bonusSum': {
                    '$sum': '$depositBonus.amount'
                  }
                }
              }
            ]
          }
        });
        localBonuses.push(bonus[0]);

        const bonusAdjustment = await feathers.service('wallet-balance-adj-logs').find({
          query: {
            _datePath: [
              '$match.createdAt.$gte',
              '$match.createdAt.$lte',
            ],
            _idPath: [
              '$match.region'
            ],
            _aggregate: [
              {
                '$match': {
                  'region': regions[i],
                  'isBonus': true,
                  'createdAt': {
                    '$gte': dateRange[0],
                    '$lte': dateRange[1]
                  }
                }
              }, {
                '$group': {
                  '_id': '$region',
                  'bonusSum': {
                    '$sum': {
                      '$switch': {
                        'branches': [
                          {
                            'case': {
                              '$eq': [
                                '$action', 'add'
                              ]
                            },
                            'then': '$amount'
                          }, {
                            'case': {
                              '$eq': [
                                '$action', 'deduct'
                              ]
                            },
                            'then': {
                              '$subtract': [
                                0, '$amount'
                              ]
                            }
                          }
                        ],
                        'default': 0
                      }
                    }
                  }
                }
              }
            ]
          }
        });
        localBonusAdjustments.push(bonusAdjustment[0]);
      };

      setBonuses(localBonuses);
      setBonusAdjustments(localBonusAdjustments);
      setStatus('ready');
    };

    fetchBonuses(regions);
  }, [dateRange, status, products]);

  function onSearch () {
    if (dateRange === null) return;
    setStatus('fetchProducts');
    setProducts(null);
    setDwls([]);
    setBonuses([]);
    setErrorMessage(null);
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <Grid stackable verticalAlign='middle' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Dimmer active={status !== 'idle' && status !== 'ready'} inverted>
                <Loader />
              </Dimmer>
              <Table celled striped attached={errorMessage !== null}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='7'>
                      <DateTimeRangePicker
                        locale={locale}
                        onChange={setDateRange}
                        value={dateRange}
                        maxDate={new Date()}
                      />
                      <Button style={{ margin: 8 }} color='blue' onClick={onSearch}>{t(`button.generate`)}</Button>
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>{t(`field.product`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.grossWinLoss`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.commAmount`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.bonusSum`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.bonusAdjustmentSum`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.estimatedBonusLoss`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.nettWinLoss`)}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {
                  status === 'ready' ?
                  renderReport() :
                  renderEmpty()
                }
              </Table>
              {
                errorMessage &&
                <Message attached='bottom' negative>
                  <p>{errorMessage}</p>
                </Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );

  function renderEmpty () {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan='7'>
            {t(`message.empty`)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  };

  function renderReport () {
    const dwlsRegionsGB = groupBy(dwls, '_id');
    const bonusesRegionsGB = groupBy(bonuses, '_id');
    const bonusAdjustmentsRegionsGB = groupBy(bonusAdjustments, '_id');
    const rows = [];

    forEach(dwlsRegionsGB, (dwlsRegion, regionKey) => {
      let totalGross = 0;
      let totalCommAmount = 0;
      let maxPercentage = 0;

      for (let i = 0; i < dwlsRegion.length; i++) {
        if (i === 0) {
          const flag = get(dwlsRegion[i], 'region[0].code');
          rows.push(
            <Table.Row key={`header_${regionKey}`}>
              <Table.Cell colSpan='7'>
                <Flag name={flag} />
              </Table.Cell>
            </Table.Row>
          );
        }

        const winLoss = toNumber(get(dwlsRegion[i], 'winLoss.$numberDecimal', 0));
        const name = get(dwlsRegion[i], 'name', 'N/A');
        const percentage = (100 - toNumber(get(dwlsRegion[i], 'percentage', 0)));
        const commAmount = winLoss * percentage / 100;
        maxPercentage = (percentage > maxPercentage) ? percentage : maxPercentage;

        totalGross += winLoss;
        totalCommAmount += commAmount;

        const key = `${regionKey}|product${name}`;
        rows.push(
          <Table.Row key={key}>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{winLoss.toFixed(2)}</Table.Cell>
            <Table.Cell>{`${commAmount.toFixed(2)} (${percentage.toFixed(2)}%)`}</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        );
      }

      const key = `${regionKey}|bonus`;
      const bonusAmount = toNumber(get(bonusesRegionsGB, `${regionKey}[0].bonusSum.$numberDecimal`, 0));
      const bonusAdjustmentAmount = toNumber(get(bonusAdjustmentsRegionsGB, `${regionKey}[0].bonusSum.$numberDecimal`, 0));

      const estimatedBonusLoss = (bonusAmount + bonusAdjustmentAmount) * maxPercentage / 100;
      const totalNett = totalGross - totalCommAmount - estimatedBonusLoss;
      rows.push(
        <Table.Row key={key}>
          <Table.Cell textAlign='right'>{t(`message.total`)}</Table.Cell>
          <Table.Cell>{totalGross.toFixed(2)}</Table.Cell>
          <Table.Cell>{totalCommAmount.toFixed(2)}</Table.Cell>
          <Table.Cell>{bonusAmount.toFixed(2)}</Table.Cell>
          <Table.Cell>{bonusAdjustmentAmount.toFixed(2)}</Table.Cell>
          <Table.Cell>{`${estimatedBonusLoss.toFixed(2)} (${maxPercentage.toFixed(2)}%)`}</Table.Cell>
          <Table.Cell>{totalNett.toFixed(2)}</Table.Cell>
        </Table.Row>
      );

    });

    if (!rows.length) return renderEmpty();

    return (
      <Table.Body>
      {rows}
      </Table.Body>
    );
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(DailyWinLossReport);