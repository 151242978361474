import PropTypes from 'prop-types'
import React from 'react';
import {
  Button,
  Card,
  Icon,
  Popup,
  Label
} from 'semantic-ui-react';
import { get } from 'lodash';
import DateTimeRenderer from 'components/Renderer/DateTime';
import { useTranslation } from 'react-i18next';

const PendingCard = (props) => {
  const { t } = useTranslation(['transaction']);
  const { onStatusUpdateCb, transactionData } = props;
  const transactionId = get(transactionData, '_id', '');
  const createdAt = get(transactionData, 'createdAt', null);
  const amount = get(transactionData, 'amount.$numberDecimal', 0)
  const forfeitedAmount = get(transactionData, 'forfeitedAmount.$numberDecimal', 0);
  const toBankName = get(transactionData, 'toAccount.bank.name', '');
  const toBankAccountHolder = get(transactionData, 'toAccount.accountHolder', '');
  const toBankTagColor = get(transactionData, 'toAccount.bank.tagColor', '');
  const finalAmount = amount - forfeitedAmount;
  let extraProps = {};
  if (toBankTagColor) extraProps.color = toBankTagColor;

  return (
    <Card>
      <Label attached='top'><DateTimeRenderer value={createdAt} /></Label>
      <Card.Content>
        <Card.Header>{finalAmount}</Card.Header>
        <Card.Meta>{toBankName}</Card.Meta>
        <Card.Description>
          <strong>{toBankAccountHolder}</strong>
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Button fluid { ...extraProps } onClick={ () => {
          onStatusUpdateCb(transactionId, { status: 'processing' });
        }}>
          {t(`button.process`)}
        </Button>
      </Card.Content>
      <Card.Content>
        <Popup inverted content={t(`transaction:popup.copyId`)} trigger={
          <Button icon labelPosition='right' onClick={ () => { navigator.clipboard.writeText(transactionId) } }>
            <Icon name='copy' />
            {transactionId}
          </Button>
        } />
      </Card.Content>
    </Card>
  )
};

PendingCard.propTypes = {
  onStatusUpdateCb: PropTypes.func.isRequired,
  transactionData: PropTypes.object.isRequired
};

export default PendingCard;