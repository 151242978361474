import * as types from './actionTypes';
import * as loginSelectors from './reducer';
import * as authActions from 'store/auth/actions';
import * as authSelectors from 'store/auth/reducer';

export function updateUsername (newUsername) {
  return({ type: types.USERNAME_CHANGED, username: newUsername });
};

export function updatePassword (newPassword) {
  return({ type: types.PASSWORD_CHANGED, password: newPassword });
};

export function updateCode (newCode) {
  return({ type: types.CODE_CHANGED, code: newCode });
};

export function submit () {
  return (dispatch, getState) => {
    let credential = {};

    const twoFactorEnabled = authSelectors.getTwoFactorEnabled(getState());

    if (twoFactorEnabled) {
      credential.strategy = 'two-factor';
      credential.username = loginSelectors.getUsername(getState());
      credential.password = loginSelectors.getPassword(getState());
      credential.code = loginSelectors.getCode(getState());
    } else {
      credential.strategy = 'local';
      credential.username = loginSelectors.getUsername(getState());
      credential.password = loginSelectors.getPassword(getState());
    };

    // auth with feathers
    dispatch(authActions.authenticate(credential));
  };
};

export function cleanLoginForm () {
  return({ type: types.CLEAN_FORM });
};