import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as loginActions from 'store/login/actions';
import * as loginSelectors from 'store/login/reducer';
import * as authSelectors from 'store/auth/reducer';
import PropTypes from 'prop-types'
import { Button, Dimmer, Form, Grid, Header, Icon, Input, Loader, Message, Segment } from 'semantic-ui-react';

import {
  Redirect
} from 'react-router-dom';

class LoginScreen extends Component {
  componentDidMount () {
  };

  render () {
    if (this.props.isAuthenticated) return <Redirect to='/home' />;

    const errorMessage = this.props.isError ? <Message negative>{this.props.isError.message}</Message> : null;
    const loader = this.props.isLoading ? <Dimmer active><Loader /></Dimmer> : null;
    const formInput = this.props.twoFactorEnabled ? this.renderTwoFactorForm() : this.renderLoginForm();

    return (
      <div className='LoginScreen'>
        {loader}
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 400 }}>
            <Header as='h3' color='grey' textAlign='center'>
              <Icon name='gem' color='orange' />
              Log-in to CCrm
            </Header>
            <Form size='large' onSubmit={this.onFormSubmit.bind(this)}>
              <Segment stacked>
                {formInput}
                <Button type='submit' color='orange' fluid size='large'>
                  Next
                </Button>
              </Segment>
            </Form>
            {errorMessage}
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  onUsernameChange (event) {
    this.props.dispatch(loginActions.updateUsername(event.target.value));
  };

  onPasswordChange (event) {
    this.props.dispatch(loginActions.updatePassword(event.target.value));
  };

  onCodeChange (event) {
    this.props.dispatch(loginActions.updateCode(event.target.value));
  };

  onFormSubmit (event) {
    event.preventDefault();
    this.props.dispatch(loginActions.submit());
  };

  renderLoginForm () {
    return (
      <React.Fragment>
        <Form.Field
          control={Input}
          autoFocus
          fluid
          icon='user'
          iconPosition='left'
          placeholder='Username'
          value={this.props.username}
          onChange={this.onUsernameChange.bind(this)}
        />
        <Form.Field
          control={Input}
          fluid
          icon='lock'
          iconPosition='left'
          placeholder='Password'
          type='password'
          value={this.props.password}
          onChange={this.onPasswordChange.bind(this)}
        />
      </React.Fragment>
    );
  };

  renderTwoFactorForm () {
    return (
      <Form.Field
        control={Input}
        autoFocus
        label={`2-Step Verification`}
        fluid
        icon='key'
        iconPosition='left'
        placeholder='Enter code'
        type='password'
        maxLength='6'
        value={this.props.code}
        onChange={this.onCodeChange.bind(this)}
      />

    );
  };
};

LoginScreen.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps (state) {
  return {
    username: loginSelectors.getUsername(state),
    password: loginSelectors.getPassword(state),
    code: loginSelectors.getCode(state),
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    isError: authSelectors.getIsError(state),
    isLoading: authSelectors.getIsLoading(state),
    role: authSelectors.getUserRole(state),
    twoFactorEnabled: authSelectors.getTwoFactorEnabled(state)
  };
};

export default connect(mapStateToProps)(LoginScreen);
