import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone';
import {
  Dimmer,
  Grid,
  Loader,
  Message,
  Progress,
  Segment
} from 'semantic-ui-react';
import axiosClient from 'services/axios';
import axios from 'axios';
import { each, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import events from 'events';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const Dropzone = (props) => {
  const { t } = useTranslation();
  const {
    additionalUploadData,
    onPasteCb,
    pastedImage,
    service
  } = props;
  const [percentage, setPercentage] = useState(0);
  const [ status, setStatus ] = useState('idle');
  const [uploadError, setUploadError] = useState([]);
  const multiple = props.multiple === undefined ? true : props.multiple;
  const disabled = props.disabled === undefined ? false : props.disabled;
  const [ uploader ] = useState(new events.EventEmitter());

  const onDrop = useCallback(
    acceptedFiles => {
      if (status !== 'idle') return;
      setUploadError([]);
      each(acceptedFiles, async file => {
        const formData = new FormData();

        each(additionalUploadData, (value, key) => {
          formData.append(key, value);
        });

        formData.append('uri', file, file.name);
        try {
          setStatus('uploading');
          await axiosClient.service(service).create(formData);
        } catch (err) {
          console.log(err);
          setStatus('idle');
          const errorMessage = `${file.name}: ${err.message}`;
          setUploadError(uploadError => uploadError.concat(errorMessage));
        };
      });
    },
    [additionalUploadData, service, status]
  );

  useEffect(() => {
    axiosClient.authentication.authenticate().catch(err => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (!pastedImage) return;
    onPasteCb();
    onDrop([pastedImage]);
  }, [onDrop, onPasteCb, pastedImage]);

  useEffect(() => {
    const axiosUploadProgressBridge = (pe) => {
      uploader.emit('progress', pe);
    };

    const updatePercentage = (pe) => {
      if (pe.loaded === pe.total) {
        setStatus('idle');
        setPercentage(100);
      } else {
        setPercentage(pe.loaded / pe.total * 100);
      }
    };

    axios.defaults.onUploadProgress = axiosUploadProgressBridge;
    uploader.on('progress', updatePercentage);
    return () => {
      setStatus('idle');
      setPercentage(100);
      uploader.removeListener('progress', updatePercentage);
    };
  }, [uploader]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, accept: 'image/*', multiple: multiple, disabled: disabled })

  const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragAccept, isDragActive, isDragReject]
  );

  return (
    <div>
      <Segment vertical>
        <Grid container centered>
          <Grid.Row>
            <Grid.Column>
              <Dimmer active={status !== 'idle'}>
                <Loader />
              </Dimmer>
              <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>{t(`dropzone.dragActive`)}</p> :
                    <p>{t(`dropzone.dragInactive`)}</p>
                }
                {
                  uploadError.length ?
                  map(uploadError, e => {
                    return <Message negative compact key={e}>{e}</Message>;
                  }) :
                  null
                }
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={14} tablet={8} computer={8} textAlign='center'>
            {
              status !== 'idle' ?
                <Progress active color='olive' percent={percentage} size='tiny' />  :
                null
            }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};

Dropzone.propTypes = {
  additionalUploadData: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onPasteCb: PropTypes.func,
  pastedImage: PropTypes.any,
  service: PropTypes.string.isRequired
};

export default Dropzone;