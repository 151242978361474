import { useEffect, useState } from 'react';
import feathers from 'services/feathers';

function useImageDownloader (serviceName, fileId) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const nativeService = feathers.service(`/${serviceName}`);

  useEffect (() => {
    async function fetchData () {
      setLoading(true);
      try {
        const getFile = await nativeService.get(fileId);
        if (getFile) setImage(getFile);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      };
    };
    fetchData();
  }, [nativeService, fileId]);

  return { image, loading };
};

export default useImageDownloader;