import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import './index.css';
import Root from './Root';
import reducers from './store/reducers';
import * as authActions from './store/auth/actions';
import 'semantic-ui-less/semantic.less'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'auth.LOGOUT') state = undefined;
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(promise)
  )
);

// JWT local storage login attempt
if (localStorage['feathers-jwt']) {
  store.dispatch(authActions.reAuthenticate())
  .catch(err => {
    console.log('authenticate catch', err); // eslint-disable-line no-console
    return err;
  });
}

ReactDOM.render(
  <Root store={store}/>,
  document.getElementById('root')
);