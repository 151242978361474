import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  List,
  Modal,
} from 'semantic-ui-react';
import { get, map } from 'lodash';
import DataRenderer from 'components/Renderer/Data';
import {  spreadPropsRefData } from 'utils/props';

const ModalObjectView = (props) => {
  const [ open, setOpen ] = useState(false);
  const { field, refs } = props;
  const { t } = useTranslation();
  return (
    <Modal size='tiny' open={open} trigger={<Button size='small' onClick={ () => { setOpen(true); } }>{t(`table:button.view`)}</Button>} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`table:field.${field.name}`)}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <List divided selection>
              {
                map(get(field, 'objectSchema.fields', []), objectField => {
                  let customProps = { ...props };
                  customProps.field = objectField;
                  customProps.value = props.value[objectField.name] || '';
                  return (
                    <List.Item key={`${field.name}${objectField.name}`}>
                      <Label horizontal>{t(`table:field.${objectField.name}`)}</Label>
                      <DataRenderer
                        { ...customProps }
                        { ...spreadPropsRefData(refs, objectField, null) }
                      />
                    </List.Item>
                  )
                })
              }
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={ () => { setOpen(false); } } content={t(`table:button.close`)} labelPosition='right' icon='close' />
        </Modal.Actions>
    </Modal>
  );
};

ModalObjectView.propTypes = {
  field: PropTypes.object.isRequired,
  refs: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired
};

export default ModalObjectView;