import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  List,
  Modal,
} from 'semantic-ui-react';
import { isFunction, map } from 'lodash';

const ModalArrayView = (props) => {
  const [ open, setOpen ] = useState(false);
  const { field, value } = props;
  const { t } = useTranslation();
  return (
    <Modal size='tiny' open={open} trigger={<Button size='small' onClick={ () => { setOpen(true); } }>{`${t(`table:button.view`)} [${value.length}]`}</Button>} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>{t(`table:field.${field.name}`)}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <List as='ol'>
              {
                map(value, (v, index) => {
                  if (!v.product) return null;
                  const text = isFunction(field.text) ? field.text(v) : v;
                  return (
                    <List.Item as='li' value='-' key={`${field.name}${index}`}>
                      {text}
                    </List.Item>
                  );
                })
              }
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={ () => { setOpen(false); } } content={t(`table:button.close`)} labelPosition='right' icon='close' />
        </Modal.Actions>
    </Modal>
  );
};

ModalArrayView.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired
};

export default ModalArrayView;