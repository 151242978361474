import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

class ProductsScreen extends Component {

  render() {
    const schema = {
      modelName: 'products',
      serviceName: 'products',
      fields: [
        {
          name: '_id',
          type: 'String'
        },
        {
          name: 'region',
          type: 'ObjectId',
          ref: 'regions',
          value: '_id',
          text: 'name',
          flag: 'code',
          required: true
        },
        {
          name: 'name',
          type: 'String',
          required: true
        },
        {
          name: 'percentage',
          type: 'Number',
          required: true
        },
        {
          name: 'themeColor',
          type: 'String',
          required: true
        },
        {
          name: 'logos.web.file',
          type: 'ObjectId',
          ref: 'files',
          value: '_id',
          text: 'name'
        },
        {
          name: 'logos.web.desc',
          type: 'String'
        },
        {
          name: 'logos.mobile.file',
          type: 'ObjectId',
          ref: 'files',
          value: '_id',
          text: 'name'
        },
        {
          name: 'logos.mobile.desc',
          type: 'String'
        },
        {
          name: 'links.android',
          type: 'String'
        },
        {
          name: 'links.ios',
          type: 'String'
        },
        {
          name: 'links.web',
          type: 'String'
        },
        {
          name: 'type',
          type: 'String',
          translate: true,
          enum: ['casino', 'sport', 'slot'],
          required: true
        },
        {
          name: 'walletType',
          type: 'String',
          translate: true,
          enum: ['internal', 'external'],
          required: true
        },
        {
          name: 'onApiSettingModel',
          type: 'String',
          translate: true,
          enum: ['apiSettingsKing855', 'apiSettingsKiss918'],
          required: true
        },
        {
          name: 'apiSetting',
          type: 'ObjectId',
          refPath: 'onApiSettingModel',
          value: '_id',
          text: (object) => {
            if (object.apiName === 'king855') return `${object.apiName} (${object.agentName}@${object.currencyName})`;
            if (object.apiName === 'kiss918') return `${object.apiName} (${object.username}@${object.area})`
            return `${object.apiName}`
          },
          required: true
        },
        {
          name: 'androidReady',
          type: 'Boolean',
          default: true,
          required: true
        },
        {
          name: 'iosReady',
          type: 'Boolean',
          default: true,
          required: true
        },
        {
          name: 'status',
          type: 'String',
          translate: true,
          enum: ['enabled', 'disabled'],
          required: true
        }
      ]
    };

    return (
      <div>
        <Segment style={TableSegment} vertical>
          <TableView schema={schema} refFields={extractRefFields(schema)} />
        </Segment>
      </div>
    );
  };
};

ProductsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(ProductsScreen);