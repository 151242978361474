import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react';
import {
  Button,
  Container,
  List
} from 'semantic-ui-react';
import ModalBalanceAdjView from 'components/Modal/ModalBalanceAdjView';
import { get } from 'lodash';

const CompanyBankList = (props) => {
  const { cbaRefData } = props;
  const [ balAdjObject, setBalAdjObject ] = useState(null);

  const onBalanceAdjCallback = useCallback(
    (object) => {
      setBalAdjObject(object);
    },
    []
  );

  const onBalanceAdjModalCloseCallback = useCallback(
    () => {
      setBalAdjObject(null);
    },
    []
  );

  return (
    <Container>

      <List horizontal ordered size='big' style={{ textAlign: 'center', width: '100%' }}>
        {
          cbaRefData.map(bank => {
            const balance = get(bank, 'balance.$numberDecimal', bank.balance);
            return (
              <List.Item key={bank._id}>
                <List.Content>
                  <List.Header>{bank.aliasName}</List.Header>
                  <Button size='mini' circular icon='edit' onClick={ () => {
                    onBalanceAdjCallback(bank);
                  }} />
                  {balance}
                </List.Content>
              </List.Item>
            )
          })
        }
      </List>
      {
        balAdjObject && <ModalBalanceAdjView
          balAdjObject={balAdjObject}
          onClose={onBalanceAdjModalCloseCallback}
          refField='bankAccount'
          service='bank-balance-adj-logs'
          localeKey='bankAccountBalAdj'
        />
      }
    </Container>
  );
};

CompanyBankList.propTypes = {
  cbaRefData: PropTypes.array.isRequired
};

export default CompanyBankList;
