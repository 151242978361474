import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AbilityContext } from 'casl/ability-context';
import { Menu, Popup, Icon } from 'semantic-ui-react'
import { camelCase, get } from 'lodash';
import NavLinkWithBadge from 'components/NavLinkWithBadge';
import { useTranslation } from 'react-i18next';
import { getUserDetails } from 'store/auth/reducer';
import { useSelector } from 'react-redux';
import feathers from 'services/feathers';
import { useHistory } from 'react-router-dom';

function MenuLink (props) {
  const { t, i18n } = useTranslation();
  const ability = useContext(AbilityContext);
  const user = useSelector(getUserDetails);
  const history = useHistory();

  return (
    <div>
      { renderUserbar() }
      { renderHome() }
      {
        renderAccountMenus(
          [
            'deposit-logs',
            'withdrawal-logs'
          ],
          'accountOperations'
        )
      }
      {
        renderSubMenus(
          [
            'deposit-logs',
            'withdrawal-logs',
            'transaction-reports'
          ],
          'transactionLogs'
        )
      }
      {
        renderSubMenus(
          [
            'bank-balance-adj-logs',
            'wallet-balance-adj-logs'
          ],
          'balanceAdjustmentLogs'
        )
      }
      {
        renderSubMenus(
          [
            'users',
            'wallets',
            'customer-bank-accounts'
          ],
          'userOperations'
        )
      }
      {
        renderSubMenus(
          [
            'banks',
            'company-bank-accounts'
          ],
          'bankOperations'
        )
      }
      {
        renderSubMenus(
          [
            'products',
            'bonus-settings',
            'news',
            'supports'
          ],
          'businessOperations'
        )
      }
      {
        renderSubMenus(
          [
            'ext-balance-transfer-logs-kiss-918'
          ],
          'balanceTransferLogs'
        )
      },
      {
        renderSubMenus(
          [
            'ext-bet-logs-king-855',
            'ext-bet-detail-logs-king-855'
          ],
          'seamlessWalletBetLogs'
        )
      }
      {
        renderSubMenus(
          [
            'ext-company-daily-win-loss-logs-king-855',
            'ext-company-daily-win-loss-logs-kiss-918',
            'daily-win-loss-reports',
          ],
          'dailyWinLoss'
        )
      },
      {
        renderSubMenus(
          [
            'api-settings-king-855',
            'api-settings-kiss-918'
          ],
          'productApiSettings'
        )
      },
      {
        renderSubMenus(
          [
            'ext-users-king-855',
            'ext-users-kiss-918'
          ],
          'externalUsers'
        )
      },
      {
        renderSubMenus(
          [
            'currencies',
            'regions',
            'locales',
            'files',
            'app-versions'
          ],
          'applicationSettings'
        )
      }
      {
        renderLocaleChanger()
      }
    </div>
  );

  function onUserSettingsClicked () {
    history.push('/settings');
  };

  function renderUserbar () {
    return (
      <Menu.Item
        name='user'
      >
        <Icon link name='setting' onClick={onUserSettingsClicked} />
        <Popup
          trigger={<div>{user.name}</div>}
          content={user.username}
        />
      </Menu.Item>
    );
  };

  function renderHome () {
    return (
      <Menu.Item>
        <Menu.Menu>
          <NavLink to='/home' className='item'>{t('menu.home')}</NavLink>
        </Menu.Menu>
      </Menu.Item>
    );
  };

  function renderLocaleChanger () {

    const languages = [
      {
        name: 'English',
        code: 'en'
      },
      {
        name: '简体中文',
        code: 'zh-CN'
      },
      {
        name: '繁體中文',
        code: 'zh-TW'
      }
    ];

    return (
      <Menu.Item>
        <Menu.Header>
          {t('menu.locales')}
        </Menu.Header>
        <Menu.Menu>
          {
            languages.map(l => {
              return (<Menu.Item
                key={l.code}
                name={l.name}
                active={i18n.language === l.code}
                onClick={() => {
                  changeLocale(l.code);
                }}
              />)
            })
          }
        </Menu.Menu>
      </Menu.Item>
    );
  };

  async function changeLocale (lang) {
    i18n.changeLanguage(lang);
    const locale = await feathers.service('locales').find({
      query: {
        code: lang,
        $limit: 1
      }
    });
    const localeId = get(locale, 'data[0]');
    if (!localeId) return;
    await feathers.service('users').update(
      user._id,
      {
        locale: localeId
      }
    );
  };


  function renderSubMenus (services, headerName) {
    const menuItems = [];

    services.forEach((service) => {
      const serviceName = camelCase(service);
      const canRead = ability.can('read', serviceName);
      if (canRead) {
        menuItems.push(<NavLink key={service} to={`/${service}`} className='item'>{t(`menu.${serviceName}`)}</NavLink>);
      }
    });

    if (menuItems.length === 0) return null;

    return (
      <Menu.Item>
        <Menu.Header>
          {t(`menu.header.${headerName}`)}
        </Menu.Header>
        <Menu.Menu>
          {menuItems}
        </Menu.Menu>
      </Menu.Item>
    );
  };

  function renderAccountMenus (services, headerName) {
    const menuItems = [];

    services.forEach((service) => {
      const serviceName = camelCase(service);
      const canUpdate = ability.can('update', serviceName);
      if (canUpdate) {
        menuItems.push(<NavLinkWithBadge key={service} service={service} to={`/task-${service}`} title={t(`menu.task.${serviceName}`)} />);
      }
    });

    if (menuItems.length === 0) return null;

    return (
      <Menu.Item>
        <Menu.Header>
          {t(`menu.header.${headerName}`)}
        </Menu.Header>
        <Menu.Menu>
          {menuItems}
        </Menu.Menu>
      </Menu.Item>
    );
  };
}

export default MenuLink;
