import { Ability } from '@casl/ability';
const TYPE_KEY = Symbol.for('type');

function subjectName (subject) {
  if (!subject || typeof subject === 'string') {
    return subject;
  }
  return subject[TYPE_KEY];
}

export default new Ability([], { subjectName });