import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  Divider,
  Form,
  Icon,
  Input,
  Label,
  Popup,
  TextArea
} from 'semantic-ui-react';
import { get, startsWith } from 'lodash';
import DateTimeRenderer from 'components/Renderer/DateTime';
import RefDropdown from 'components/RefDropdown';
import { useTranslation } from 'react-i18next';
import PotWd from 'components/PotWd';
import DataTransfer from 'fbjs/lib/DataTransfer';

const ProcessingCard = (props) => {
  const { t } = useTranslation(['transaction']);
  const [ cba, setCba ] = useState(null);
  const [ bankReference, setBankReference ] = useState('');
  const [ remark, setRemark ] = useState('');
  const [ pastedImage, setPastedImage ] = useState(null);
  const {
    cbaRefData,
    onPotDeleteCb,
    onStatusUpdateCb,
    potServiceStatus,
    setImageModalSourceCb,
    transactionData
  } = props;
  const transactionId = get(transactionData, '_id', '');
  const createdAt = get(transactionData, 'createdAt', null);
  const amount = get(transactionData, 'amount.$numberDecimal', 0)
  const forfeitedAmount = get(transactionData, 'forfeitedAmount.$numberDecimal', 0);
  const toBankName = get(transactionData, 'toAccount.bank.name', '');
  const toBankAccountHolder = get(transactionData, 'toAccount.accountHolder', '');
  const toBankAccountNumber = get(transactionData, 'toAccount.accountNumber', '');
  const toBankTagColor = get(transactionData, 'toAccount.bank.tagColor', '');
  const userContact = get(transactionData, 'user.contact', '');
  const username = get(transactionData, 'user.username', '');
  const proofOfTransfer = get(transactionData, 'proofOfTransfer', null);
  const staticRemark = get(transactionData, 'remark', '');
  const finalAmount = amount - forfeitedAmount;

  let extraProps = {};
  if (toBankTagColor) extraProps.color = toBankTagColor;

  const onPaste = (e) => {
    // proofOfTransfer exists = pot uploaded
    // pastedImage exist = upload in progress
    if (proofOfTransfer || pastedImage) return;

    const data = new DataTransfer(e.clipboardData);
    const files = data.getFiles();
    if (files.length <= 0) return;
    const file = files[0];

    // clear clipboard immediately after data extraction
    //navigator.clipboard.writeText('');

    if (!startsWith(file.type, 'image/')) return;
    setPastedImage(file);
  };

  const onPasteCb = useCallback(
    () => {
      setPastedImage(null);
    },
    []
  );

  const onRemarkChange = (e, { value }) => {
    const extractedRemark = value.slice(staticRemark.length + 1);
    setRemark(extractedRemark);
  };

  return (
    <Card>
      <Label attached='top'><DateTimeRenderer value={createdAt} /></Label>
      <Card.Content>
        <Card.Header>{finalAmount}</Card.Header>
        <Card.Meta>{toBankName}</Card.Meta>
        <Card.Description>
          <strong>{toBankAccountHolder}</strong>
          <br />
          <Label style={{
            margin: 10
          }} color={toBankTagColor} size='massive' horizontal>{toBankAccountNumber}</Label>
          {
            username &&
            <p>
              <Icon name='user' />
              {username}
            </p>
          }
          { userContact ?
            <p><a rel='noopener noreferrer' href={`https://wa.me/${userContact}`} target='_blank'><Icon name='whatsapp' />{userContact}</a></p>
            : null }
          <Divider horizontal>{t(`divider.companyBank`)}</Divider>
          <RefDropdown
            placeholder={t(`transaction:dropdown.companyBankAccount`)}
            data={cbaRefData}
            text={ (d) => { return `${d.aliasName} ${d.balance.$numberDecimal}` } }
            onChange={(data) => { setCba(data); }}
          />
          <br />
          <Input fluid placeholder={t(`transaction:input.bankReference`)} value={bankReference} onChange={ (e, { value }) => {
            setBankReference(value);
          }} />
          <Divider horizontal>{t(`divider.pot`)}</Divider>
          <PotWd
            onPasteCb={onPasteCb}
            onPotDeleteCb={onPotDeleteCb}
            pastedImage={pastedImage}
            potServiceStatus={potServiceStatus}
            proofOfTransfer={proofOfTransfer}
            setImageModalSourceCb={setImageModalSourceCb}
            transactionId={transactionId}
            uploadService='uploads-wd'
          />
          <Divider horizontal>{t(`divider.additional`)}</Divider>
          <Form>
            <TextArea style={{ width: "100%" }} placeholder={t(`transaction:textarea.remark`)} value={`${staticRemark} ${remark}`} onChange={onRemarkChange} onPaste={onPaste} />
          </Form>
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <div className='ui two buttons'>
          <Button disabled={!cba} color='green' onClick={ () => {
            const updateData = {
              status: 'approved'
            };
            if (cba) updateData.fromAccount = cba;
            if (bankReference) updateData.bankReference = bankReference;
            updateData.remark = `${staticRemark} ${remark}`;
            onStatusUpdateCb(transactionId, updateData);
          }}>
            {t(`button.approve`)}
          </Button>
          <Button color='red' onClick={ () => {
            const updateData = {
              status: 'rejected'
            };
            updateData.remark = `${staticRemark} ${remark}`;
            onStatusUpdateCb(transactionId, updateData);
          }}>
            {t(`button.reject`)}
          </Button>
        </div>
      </Card.Content>
      <Card.Content>
        <Popup inverted content={t(`transaction:popup.copyId`)} trigger={
          <Button icon labelPosition='right' onClick={ () => { navigator.clipboard.writeText(transactionId) } }>
            <Icon name='copy' />
            {transactionId}
          </Button>
        } />
      </Card.Content>
    </Card>
  );
};

ProcessingCard.propTypes = {
  cbaRefData: PropTypes.array.isRequired,
  onPotDeleteCb: PropTypes.func.isRequired,
  onStatusUpdateCb: PropTypes.func.isRequired,
  potServiceStatus: PropTypes.object.isRequired,
  setImageModalSourceCb: PropTypes.func.isRequired,
  transactionData: PropTypes.object.isRequired
};

export default ProcessingCard;






