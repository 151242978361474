export const mapServicePathsToNames = {
  'api-settings-king-855': 'apiSettingsKing855',
  'api-settings-kiss-918': 'apiSettingsKiss918',
  'app-versions': 'appVersions',
  'bank-balance-adj-logs': 'bankBalanceAdjLogs',
  'banks': 'banks',
  'bonus-settings': 'bonusSettings',
  'company-bank-accounts': 'companyBankAccounts',
  'currencies': 'currencies',
  'customer-bank-accounts': 'customerBankAccounts',
  'deposit-logs': 'depositLogs',
  'ext-balance-transfer-logs-kiss-918': 'extBalanceTransferLogsKiss918',
  'ext-bet-detail-logs-king-855': 'extBetDetailLogsKing855',
  'ext-bet-logs-king-855': 'extBetLogsKing855',
  'ext-company-daily-win-loss-logs-king-855': 'extCompanyDailyWinLossLogsKing855',
  'ext-company-daily-win-loss-logs-kiss-918': 'extCompanyDailyWinLossLogsKiss918',
  'ext-users-king-855': 'extUsersKing855',
  'ext-users-kiss-918': 'extUsersKiss918',
  'files': 'files',
  'locales': 'locales',
  'news': 'news',
  'products': 'products',
  'proof-of-transfers': 'proofOfTransfers',
  'regions': 'regions',
  'supports': 'supports',
  'users': 'users',
  'wallet-balance-adj-logs': 'walletBalanceAdjLogs',
  'wallets': 'wallets',
  'withdrawal-logs': 'withdrawalLogs',
};

export const prioritizedListServices = [
  'auth',

  'appVersions',
  'bankBalanceAdjLogs',
  'banks',
  'bonusSettings',
  'companyBankAccounts',
  'currencies',
  'customerBankAccounts',
  'depositLogs',
  'files',
  'locales',
  'news',
  'products',
  'proofOfTransfers',
  'regions',
  'supports',
  'users',
  'walletBalanceAdjLogs',
  'wallets',
  'withdrawalLogs',

  'apiSettingsKing855',
  'apiSettingsKiss918',

  'extBalanceTransferLogsKiss918',
  'extBetDetailLogsKing855',
  'extBetLogsKing855',
  'extCompanyDailyWinLossLogsKing855',
  'extCompanyDailyWinLossLogsKiss918',
  'extUsersKing855',
  'extUsersKiss918',
];
