import io from 'socket.io-client'; // eslint-disable-line no-unused-vars
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

import reduxifyServices, { getServicesStatus } from 'feathers-redux';
import config from 'config';
import * as fs from './feathersServices';
import * as frs from './feathersRefServices';
import * as ftts from './feathersTransactionTaskServices';

const socket = io(config.feathers.host);

const app = feathers();
app.configure(socketio(socket));
app.configure(auth({
  storage: window.localStorage
}));

export default app;

const reduxifyOptions = {
  idField: '_id'
}

export const feathersServices = reduxifyServices(app, fs.mapServicePathsToNames, reduxifyOptions);
export const feathersRefServices = reduxifyServices(app, frs.mapServicePathsToNames, reduxifyOptions);
export const feathersTransactionTaskServices = reduxifyServices(app, ftts.mapServicePathsToNames, reduxifyOptions);

export const getFeathersStatus = (servicesRootState, names = fs.prioritizedListServices) => {
  return getServicesStatus(servicesRootState, names);
};

export const getFeathersRefStatus = (servicesRootState, names = frs.prioritizedListServices) => {
  return getServicesStatus(servicesRootState, names);
};

export const getFeathersTransactionTaskStatus = (servicesRootState, names = ftts.prioritizedListServices) => {
  return getServicesStatus(servicesRootState, names);
};

export const socketClient = socket;