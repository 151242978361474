import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const AppVersionsScreen = (props) => {
  const schema = {
    modelName: 'appVersions',
    serviceName: 'app-versions',
    fields: [
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'version',
        type: 'String',
        required: true,
        sortable: true
      },
      {
        name: 'commitId',
        type: 'String',
        required: true
      },
      {
        name: 'apkDownloadUrl',
        type: 'String'
      },
      {
        name: 'ipaDownloadUrl',
        type: 'String',
      },
      {
        name: 'priority',
        type: 'Number',
        required: true,
        sortable: true
      },
      {
        name: 'forceUpdate',
        type: 'Boolean',
        default: true,
        required: true,
        translate: true
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ]
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} />
      </Segment>
    </div>
  );
};

AppVersionsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(AppVersionsScreen);