import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Segment
} from 'semantic-ui-react';
import StatExtWalletBalance from 'components/Stat/StatExtWalletBalance';

class HomeScreen extends Component {

/*  constructor(props) {
    super(props);
  }
*/
  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <Segment style={{ minHeight: '80vh', padding: '8em 0em' }} vertical>
        <Grid container>
          <Grid.Row textAlign='left'>
            <Grid.Column>
              <StatExtWalletBalance />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
};

HomeScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(HomeScreen);