import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimmer,
  Loader
} from 'semantic-ui-react';

const TableLoaderRef = () => {
  const { t } = useTranslation();
  return (
    <Dimmer active page>
      <Loader>{t(`serviceStatus.refLoading`)}</Loader>
    </Dimmer>
  );
};

export default TableLoaderRef;