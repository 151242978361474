import auth from './auth/reducer';
import io from './io/reducer';
import login from './login/reducer';
import table from './table/reducer';
import { feathersServices, feathersRefServices, feathersTransactionTaskServices } from 'services/feathers';

function createFilteredReducer (reducerFunction, reducerPredicate) {
  return (state, action) => {
    const isInitializationCall = (state === undefined);
    const shouldRunWrappedReducer = (reducerPredicate(action) || isInitializationCall);
    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  };
};

function transformRefPayload (reducerFunction, refServiceName) {
  return (state, action) => {
    const FIND_TYPE = `SERVICES_${refServiceName.toUpperCase()}_FIND_FULFILLED`;
    if (action.type === FIND_TYPE) {
      const newPayload = {
        data: action.payload,
        limit: -1,
        skip: 0,
        total: action.payload.length
      };
      action.payload = newPayload;
    }
    return reducerFunction(state, action);
  };
};

const reducers = {
  auth,
  io,
  login,

  // Services
  apiSettingsKing855: feathersServices.apiSettingsKing855.reducer,
  apiSettingsKiss918: feathersServices.apiSettingsKiss918.reducer,
  appVersions: feathersServices.appVersions.reducer,
  bankBalanceAdjLogs: feathersServices.bankBalanceAdjLogs.reducer,
  banks: feathersServices.banks.reducer,
  bonusSettings: feathersServices.bonusSettings.reducer,
  companyBankAccounts: feathersServices.companyBankAccounts.reducer,
  currencies: feathersServices.currencies.reducer,
  customerBankAccounts: feathersServices.customerBankAccounts.reducer,
  depositLogs: feathersServices.depositLogs.reducer,
  extBalanceTransferLogsKiss918: feathersServices.extBalanceTransferLogsKiss918.reducer,
  extBetDetailLogsKing855: feathersServices.extBetDetailLogsKing855.reducer,
  extBetLogsKing855: feathersServices.extBetLogsKing855.reducer,
  extCompanyDailyWinLossLogsKing855: feathersServices.extCompanyDailyWinLossLogsKing855.reducer,
  extCompanyDailyWinLossLogsKiss918: feathersServices.extCompanyDailyWinLossLogsKiss918.reducer,
  extUsersKing855: feathersServices.extUsersKing855.reducer,
  extUsersKiss918: feathersServices.extUsersKiss918.reducer,
  files: feathersServices.files.reducer,
  locales: feathersServices.locales.reducer,
  news: feathersServices.news.reducer,
  products: feathersServices.products.reducer,
  proofOfTransfers: feathersServices.proofOfTransfers.reducer,
  regions: feathersServices.regions.reducer,
  supports: feathersServices.supports.reducer,
  users: feathersServices.users.reducer,
  walletBalanceAdjLogs: feathersServices.walletBalanceAdjLogs.reducer,
  wallets: feathersServices.wallets.reducer,
  withdrawalLogs: feathersServices.withdrawalLogs.reducer,

  // Table
  apiSettingsKing855Table: createFilteredReducer(table, action => action.modelName === 'apiSettingsKing855'),
  apiSettingsKiss918Table: createFilteredReducer(table, action => action.modelName === 'apiSettingsKiss918'),
  appVersionsTable: createFilteredReducer(table, action => action.modelName === 'appVersions'),
  bankBalanceAdjLogsTable: createFilteredReducer(table, action => action.modelName === 'bankBalanceAdjLogs'),
  banksTable: createFilteredReducer(table, action => action.modelName === 'banks'),
  bonusSettingsTable: createFilteredReducer(table, action => action.modelName === 'bonusSettings'),
  companyBankAccountsTable: createFilteredReducer(table, action => action.modelName === 'companyBankAccounts'),
  currenciesTable: createFilteredReducer(table, action => action.modelName === 'currencies'),
  customerBankAccountsTable: createFilteredReducer(table, action => action.modelName === 'customerBankAccounts'),
  depositLogsTable: createFilteredReducer(table, action => action.modelName === 'depositLogs'),
  extBalanceTransferLogsKiss918Table: createFilteredReducer(table, action => action.modelName === 'extBalanceTransferLogsKiss918'),
  extBetDetailLogsKing855Table: createFilteredReducer(table, action => action.modelName === 'extBetDetailLogsKing855'),
  extBetLogsKing855Table: createFilteredReducer(table, action => action.modelName === 'extBetLogsKing855'),
  extCompanyDailyWinLossLogsKing855Table: createFilteredReducer(table, action => action.modelName === 'extCompanyDailyWinLossLogsKing855'),
  extCompanyDailyWinLossLogsKiss918Table: createFilteredReducer(table, action => action.modelName === 'extCompanyDailyWinLossLogsKiss918'),
  extUsersKing855Table: createFilteredReducer(table, action => action.modelName === 'extUsersKing855'),
  extUsersKiss918Table: createFilteredReducer(table, action => action.modelName === 'extUsersKiss918'),
  filesTable: createFilteredReducer(table, action => action.modelName === 'files'),
  localesTable: createFilteredReducer(table, action => action.modelName === 'locales'),
  newsTable: createFilteredReducer(table, action => action.modelName === 'news'),
  productsTable: createFilteredReducer(table, action => action.modelName === 'products'),
  regionsTable: createFilteredReducer(table, action => action.modelName === 'regions'),
  supportsTable: createFilteredReducer(table, action => action.modelName === 'supports'),
  usersTable: createFilteredReducer(table, action => action.modelName === 'users'),
  walletBalanceAdjLogsTable: createFilteredReducer(table, action => action.modelName === 'walletBalanceAdjLogs'),
  walletsTable: createFilteredReducer(table, action => action.modelName === 'wallets'),
  withdrawalLogsTable: createFilteredReducer(table, action => action.modelName === 'withdrawalLogs'),

  // Ref services
  apiSettingsKiss918Ref: transformRefPayload(feathersRefServices.apiSettingsKiss918Ref.reducer, 'apiSettingsKiss918Ref'),
  apiSettingsKing855Ref: transformRefPayload(feathersRefServices.apiSettingsKing855Ref.reducer, 'apiSettingsKing855Ref'),
  banksRef: transformRefPayload(feathersRefServices.banksRef.reducer, 'banksRef'),
  bonusSettingsRef: transformRefPayload(feathersRefServices.bonusSettingsRef.reducer, 'bonusSettingsRef'),
  companyBankAccountsRef: transformRefPayload(feathersRefServices.companyBankAccountsRef.reducer, 'companyBankAccountsRef'),
  currenciesRef: transformRefPayload(feathersRefServices.currenciesRef.reducer, 'currenciesRef'),
  filesRef: transformRefPayload(feathersRefServices.filesRef.reducer, 'filesRef'),
  localesRef: transformRefPayload(feathersRefServices.localesRef.reducer, 'localesRef'),
  productsRef: transformRefPayload(feathersRefServices.productsRef.reducer, 'productsRef'),
  regionsRef: transformRefPayload(feathersRefServices.regionsRef.reducer, 'regionsRef'),
  rejectedReasonsRef: transformRefPayload(feathersRefServices.rejectedReasonsRef.reducer, 'rejectedReasonsRef'),

  // Transaction task services
  depositLogsTask: transformRefPayload(feathersTransactionTaskServices.depositLogsTask.reducer, 'depositLogsTask'),
  withdrawalLogsTask: transformRefPayload(feathersTransactionTaskServices.withdrawalLogsTask.reducer, 'withdrawalLogsTask')
};

export default reducers;
