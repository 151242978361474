import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dimmer,
  Flag,
  Grid,
  Loader,
  Message,
  Segment,
  Table
} from 'semantic-ui-react';
import { TableSegment } from 'styles/common';
import feathers from 'services/feathers';
import { forEach, filter, get, includes, map, merge, toNumber, uniq } from 'lodash';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { useTranslation } from 'react-i18next';

const TransactionReportsScreen = (props) => {
  const { i18n, t } = useTranslation('table');
  const [ dateRange, setDateRange ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ dp, setDp ] = useState(null);
  const [ wd, setWd ] = useState(null);
  const [ report, setReport ] = useState(null);
  const [ status, setStatus ] = useState('idle');
  const locale = i18n.language.toLowerCase();

  useEffect(() => {
    if (status !== 'fetchDp') return;
    async function fetchDp () {
      try {
        const dp = await feathers.service('deposit-logs').find({
          query: {
            _datePath: [
              '$match.transactionTime.$gte',
              '$match.transactionTime.$lt',
            ],
            _aggregate: [
              {
                '$match': {
                  'status': 'approved',
                  'transactionTime': {
                    '$gte': dateRange[0],
                    '$lt': dateRange[1]
                  }
                }
              }, {
                '$group': {
                  '_id': {
                    'date': {
                      '$dateToString': {
                        'format': '%Y-%m-%d',
                        'date': '$transactionTime',
                        'timezone': '+08:00'
                      }
                    },
                    'ba': '$toAccount',
                    'region': '$region'
                  },
                  'dpCount': {
                    '$sum': 1
                  },
                  'dpAmount': {
                    '$sum': '$amount'
                  },
                  'bonusAmount': {
                    '$sum': '$depositBonus.amount'
                  }
                }
              }, {
                '$lookup': {
                  'from': 'companybankaccounts',
                  'localField': '_id.ba',
                  'foreignField': '_id',
                  'as': 'bankAccount'
                }
              }, {
                $lookup: {
                    from: 'regions',
                    localField: '_id.region',
                    foreignField: '_id',
                    as: 'region'
                }
              },
              {
                '$sort': {
                  '_id.region': 1,
                  '_id.date': 1,
                  '_id.ba': 1
                }
              }
            ]
          }
        });
        setDp(dp);
        //console.log(dp);
        setStatus('fetchWd');
      } catch (err) {
        setErrorMessage(err.message);
        setStatus('idle');
      }
    };
    fetchDp();
  }, [dateRange, status]);

  useEffect(() => {
    if (status !== 'fetchWd') return;
    async function fetchWd () {
      try {
        const wd = await feathers.service('withdrawal-logs').find({
          query: {
            _datePath: [
              '$match.updatedAt.$gte',
              '$match.updatedAt.$lt',
            ],
            _aggregate: [
              {
                '$match': {
                  'status': 'approved',
                  'updatedAt': {
                    '$gte': dateRange[0],
                    '$lt': dateRange[1]
                  }
                }
              }, {
                '$group': {
                  '_id': {
                    'date': {
                      '$dateToString': {
                        'format': '%Y-%m-%d',
                        'date': '$updatedAt',
                        'timezone': '+08:00'
                      }
                    },
                    'ba': '$fromAccount',
                    'region': '$region'
                  },
                  'wdCount': {
                    '$sum': 1
                  },
                  'wdAmount': {
                    '$sum': { $subtract: [ "$amount", "$forfeitedAmount" ] }
                  }
                }
              }, {
                '$lookup': {
                  'from': 'companybankaccounts',
                  'localField': '_id.ba',
                  'foreignField': '_id',
                  'as': 'bankAccount'
                }
              }, {
                $lookup: {
                    from: 'regions',
                    localField: '_id.region',
                    foreignField: '_id',
                    as: 'region'
                }
              }, {
                '$sort': {
                  '_id.region': 1,
                  '_id.date': 1,
                  '_id.ba': 1
                }
              }
            ]
          }
        });
        setWd(wd);
        setStatus('idle');
      } catch (err) {
        setErrorMessage(err.message);
        setStatus('idle');
      }
    };
    fetchWd();
  }, [dateRange, status]);

  useEffect(() => {
    if (dp === null || wd === null) return;
    let matchedWdList = [];
    const merged = map(dp, (d) => {
      const dpKey = JSON.stringify(d._id);
      let matchedWd = null;
      forEach(wd, (w) => {
        const wdKey = JSON.stringify(w._id);
        if (dpKey === wdKey) {
          matchedWd = w;
          matchedWdList.push(dpKey);
          return;
        }
      });
      if (matchedWd) return merge({}, d, matchedWd);
      return d;
    });

    forEach(wd, (w) => {
      const wdKey = JSON.stringify(w._id);
      if (!includes(matchedWdList, wdKey)) merged.push(w);
    });
    setReport(merged);
  }, [dp, wd])

  function onSearch () {
    if (dateRange === null) return;
    setStatus('fetchDp');
    setDp(null);
    setWd(null);
    setReport(null);
    setErrorMessage(null);
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <Grid stackable verticalAlign='middle' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Dimmer active={status !== 'idle'} inverted>
                <Loader />
              </Dimmer>
              <Table celled striped attached={errorMessage !== null}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='8'>
                      <DateTimeRangePicker
                        locale={locale}
                        onChange={setDateRange}
                        value={dateRange}
                        maxDate={new Date()}
                      />
                      <Button style={{ margin: 8 }} color='blue' onClick={onSearch}>{t(`button.generate`)}</Button>
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>{t(`field.date`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.bankName`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.dpCount`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.dpAmount`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.bonusAmount`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.wdCount`)}</Table.HeaderCell>
                    <Table.HeaderCell>{t(`field.wdAmount`)}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {
                  report === null ?
                  renderEmpty() :
                  renderReport()
                }
              </Table>
              {
                errorMessage &&
                <Message attached='bottom' negative>
                  <p>{errorMessage}</p>
                </Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );

  function renderEmpty () {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan='6'>
            {t(`message.empty`)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  };

  function renderReport () {
    const regions = uniq(map(report, 'region[0].code'));
    const rows = [];

    forEach(regions, region => {
      const regionRecords = filter(report, r => {
        return r.region[0].code === region;
      });
      if (!regionRecords.length) return;
      rows.push(
        <Table.Row key={`header_${region}`}>
          <Table.Cell colSpan='7'>
            <Flag name={region} />
          </Table.Cell>
        </Table.Row>
      );

      let totalDpCount = 0;
      let totalDpAmount = 0;
      let totalBonusAmount = 0;
      let totalWdCount = 0;
      let totalWdAmount = 0;

      forEach(regionRecords, record => {
        const key = JSON.stringify(record._id);
        const date = get(record, '_id.date');
        const bank = get(record, 'bankAccount[0].aliasName');
        const dpCount = toNumber(get(record, 'dpCount', 0));
        const dpAmount = toNumber(get(record, 'dpAmount.$numberDecimal', 0));
        const bonusAmount = toNumber(get(record, 'bonusAmount.$numberDecimal', 0));
        const wdCount = toNumber(get(record, 'wdCount', 0));
        const wdAmount = toNumber(get(record, 'wdAmount.$numberDecimal', 0));

        totalDpCount += dpCount;
        totalDpAmount += dpAmount;
        totalBonusAmount += bonusAmount;
        totalWdCount += wdCount;
        totalWdAmount += wdAmount;

        rows.push(
          <Table.Row key={key}>
            <Table.Cell collapsing>{date}</Table.Cell>
            <Table.Cell>{bank}</Table.Cell>
            <Table.Cell>{dpCount}</Table.Cell>
            <Table.Cell>{dpAmount.toFixed(2)}</Table.Cell>
            <Table.Cell>{bonusAmount.toFixed(2)}</Table.Cell>
            <Table.Cell>{wdCount}</Table.Cell>
            <Table.Cell>{wdAmount.toFixed(2)}</Table.Cell>
          </Table.Row>
        );
      });

      // Total view
      rows.push(
        <Table.Row key={`footer_${region}`}>
          <Table.Cell colSpan={2} textAlign='right'>{t(`message.total`)}</Table.Cell>
          <Table.Cell>{totalDpCount}</Table.Cell>
          <Table.Cell>{totalDpAmount.toFixed(2)}</Table.Cell>
          <Table.Cell>{totalBonusAmount.toFixed(2)}</Table.Cell>
          <Table.Cell>{totalWdCount}</Table.Cell>
          <Table.Cell>{totalWdAmount.toFixed(2)}</Table.Cell>
        </Table.Row>
      );
    });

    if (!rows.length) return renderEmpty();

    return (
      <Table.Body>
      {rows}
      </Table.Body>
    );
  };
};

TransactionReportsScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(TransactionReportsScreen);