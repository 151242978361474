import {  useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import feathers, { feathersRefServices } from 'services/feathers';
import { kebabCase, merge } from 'lodash';
import { AbilityContext } from 'casl/ability-context';
import { getPersistentRefData, getServiceIsLoading } from 'store/feathers/selectors';

const TYPE_KEY = Symbol.for('type');

// JSON.stringify filterQuery
const useFeathersRefService = (returnPrefix, modelName, filterQuery = '{}') => {
  const dispatch = useDispatch();
  const refModelName = `${modelName}Ref`;
  const serviceName = kebabCase(modelName);
  const service = feathersRefServices[refModelName];
  const nativeService = feathers.service(`/${serviceName}`);
  const ability = useContext(AbilityContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect (() => {
    const onCreated = (data) => {
      data[TYPE_KEY] = modelName;
      const canRead = ability.can('read', data);
      if (canRead) dispatch(service.onCreated(data));
      else dispatch(service.onRemoved(data));
    };
    const onRemoved = (data) => {
      dispatch(service.onRemoved(data));
    };
    const onUpdated = (data) => {
      data[TYPE_KEY] = modelName;
      const canRead = ability.can('read', data);
      if (canRead) dispatch(service.onUpdated(data));
      else dispatch(service.onRemoved(data));
    };

    nativeService.on('created', onCreated);
    nativeService.on('removed', onRemoved);
    nativeService.on('updated', onUpdated);

    return () => {
      nativeService.removeListener('created', onCreated);
      nativeService.removeListener('removed', onRemoved);
      nativeService.removeListener('updated', onUpdated);
    };
  }, [ability, dispatch, modelName, nativeService, service]);

  useEffect (() => {
    let options = {
      query: {
        $limit: -1
      }
    };
    merge(options, { query: JSON.parse(filterQuery) });

    dispatch(service.find(options)).catch(err => console.error(err));
  }, [dispatch, filterQuery, service]);

  const getIsLoading = useSelector(state => {
    return getServiceIsLoading(state, refModelName);
  })

  const getData = useSelector(state => {
    return getPersistentRefData(state, refModelName)
  });

  useEffect (() => {
    setData(getData);
  }, [getData]);

  useEffect (() => {
    setLoading(getIsLoading);
  }, [getIsLoading]);

  return {
    [`${returnPrefix}RefData`]: data,
    [`${returnPrefix}RefIsLoading`]: loading
  };
};

export default useFeathersRefService;
