import PropTypes from 'prop-types'
import React from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

const ExtBetDetailLogsKing855Screen = (props) => {
  const schema = {
    modelName: 'extBetDetailLogsKing855',
    serviceName: 'ext-bet-detail-logs-king-855',
    fields: [
      {
        name: 'region',
        type: 'ObjectId',
        ref: 'regions',
        value: '_id',
        text: 'name',
        flag: 'code',
        required: true
      },
      {
        name: '_id',
        type: 'String'
      },
      {
        name: 'agentName',
        type: 'String'
      },
      {
        name: 'userName',
        localeKey: 'extUsername',
        type: 'String'
      },
      {
        name: 'betTime',
        type: 'Date',
        sortable: true
      },
      {
        name: 'calTime',
        type: 'Date',
        sortable: true
      },
      {
        name: 'id',
        localeKey: 'ticketId',
        type: 'String',
        sortable: true
      },
      {
        name: 'serial',
        type: 'String',
        sortable: true
      },
      {
        name: 'tableId',
        type: 'String'
      },
      {
        name: 'shoeId',
        type: 'String'

      },
      {
        name: 'playId',
        type: 'String'
      },
      {
        name: 'lobbyId',
        type: 'String'
      },
      {
        name: 'gameType',
        type: 'String'
      },
      {
        name: 'gameId',
        type: 'String'
      },
      {
        name: 'memberId',
        type: 'String',
        sortable: true
      },
      {
        name: 'winOrLoss.$numberDecimal',
        localeKey: 'winOrLoss',
        type: 'Number'
      },
      {
        name: 'balanceBefore.$numberDecimal',
        localeKey: 'balanceBefore',
        type: 'Number'
      },
      {
        name: 'betPoints.$numberDecimal',
        localeKey: 'betPoints',
        type: 'Number'
      },
      {
        name: 'betPointsz.$numberDecimal',
        localeKey: 'betPointsz',
        type: 'Number'
      },
      {
        name: 'availableBet.$numberDecimal',
        localeKey: 'availableBet',
        type: 'Number'
      },
      {
        name: 'result',
        type: 'String'
      },
      {
        name: 'betDetail',
        type: 'String'
      },
      {
        name: 'ip',
        type: 'String'
      },
      {
        name: 'ext',
        type: 'String'
      },
      {
        name: 'isRevocation',
        type: 'String'
      },
      {
        name: 'currencyId',
        type: 'String'
      },
      {
        name: 'deviceType',
        type: 'String'
      },
      {
        name: 'pluginid',
        type: 'String'
      },
      {
        name: 'createdAt',
        type: 'Date',
        sortable: true
      },
      {
        name: 'updatedAt',
        type: 'Date',
        sortable: true
      }
    ],
    sorting: {
      'betTime': -1
    }
  };

  return (
    <div>
      <Segment style={TableSegment} vertical>
        <TableView schema={schema} refFields={extractRefFields(schema)} readOnly={true} />
      </Segment>
    </div>
  );
};

ExtBetDetailLogsKing855Screen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(ExtBetDetailLogsKing855Screen);