import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment
} from 'semantic-ui-react';
import TableView from 'components/DataTable/TableView';
import { extractRefFields } from 'utils/mongoose';
import { TableSegment } from 'styles/common';

class UsersScreen extends Component {

  componentDidMount() {

  };

  componentWillUnmount() {

  };

  render() {
    const schema = {
      modelName: 'users',
      serviceName: 'users',
      caslTestData: [ 'role' ],
      fields: [
        {
          name: '_id',
          type: 'String'
        },
        {
          name: 'region',
          type: 'ObjectId',
          ref: 'regions',
          value: '_id',
          text: 'name',
          flag: 'code',
          required: true,
          sortable: true
        },
        {
          name: 'twoFactorEnabled',
          type: 'Boolean',
          translate: true
        },
        {
          name: 'role',
          type: 'String',
          translate: true,
          enum: ['superadmin', 'admin', 'account', 'user'],
          required: true,
          sortable: true
        },
        {
          name: 'status',
          type: 'String',
          translate: true,
          enum: ['pending', 'active', 'blocked'],
          required: true,
          sortable: true

        },
        {
          name: 'username',
          type: 'String',
          required: true,
          sortable: true
        },
        {
          name: 'affiliateTag',
          type: 'String'
        },
        {
          name: 'relationPath',
          type: 'String'
        },
        {
          name: 'remark',
          type: 'String'
        },
        {
          name: 'lastLoginIp',
          type: 'String',
          sortable: true
        },
        {
          name: 'deviceInfo.deviceType',
          type: 'String'
        },
        {
          name: 'deviceInfo.osName',
          type: 'String'
        },
        {
          name: 'deviceInfo.osVersion',
          type: 'String'
        },
        {
          name: 'password',
          type: 'String',
          required: true,
          mask: true
        },
        {
          name: 'name',
          type: 'String',
          required: true
        },
        {
          name: 'contact',
          type: 'String',
          required: true,
          link: {
            href: (v) => {
              return `https://wa.me/${v}`
            },
            icon: 'whatsapp'
          }
        },
        /*{
          name: 'email',
          type: 'String'
        },
        {
          name: 'icNumber',
          type: 'String'
        },
        {
          name: 'dateOfBirth',
          type: 'Date',
          default: '1985/01/01'
        },*/
        {
          name: 'locale',
          type: 'ObjectId',
          ref: 'locales',
          value: '_id',
          text: 'name',
          required: true
        },
        {
          name: 'createdAt',
          type: 'Date',
          sortable: true
        },
        {
          name: 'updatedAt',
          type: 'Date',
          sortable: true
        }
      ]
    };

    return (
      <div>
        <Segment style={TableSegment} vertical>
          <TableView schema={schema} refFields={extractRefFields(schema)} />
        </Segment>
      </div>
    );
  };
};

UsersScreen.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps)(UsersScreen);